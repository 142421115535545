import React, {useContext, useCallback} from 'react'

import styles from './TelephoneInput.module.css';
import MaskElement from 'GlobalComponents/MaskedInput/MaskedInput.js';
import CountryCodeSelect from 'GlobalComponents/SelectionBox/SelectionBoxesInstances/CountryCodeSelect.js';
import AreaCodeSelect from 'GlobalComponents/SelectionBox/SelectionBoxesInstances/AreaCodeSelect.js';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput.js';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';   

export default function TelephoneInput({provider, readOnly, instance}){

    const {setCurrentServiceOrder, currentServiceOrder, providersList, setProvidersList} = useContext(QuoteMasterContext);
    


    const changeProviderNumber = useCallback((event) => {
        let providerIndex;
        if(instance==='providersList' || instance==='chat'){ // this is the list of providers in the service order used in the chat header
            providerIndex = currentServiceOrder.providers.findIndex((prov) => prov.providerName===provider.providerName);
        }else if(instance==='newProvider'){ // this is used in the provider modal for a new provider
            providerIndex = providersList.length-1;
        } else if(instance==='providersPanel'){ // this is used in the providers panel for the list of providers and in the provider modal for editing a provider
            providerIndex = providersList.findIndex((prov) =>prov.affiliateId===provider.affiliateId);
        }
        
        const value = event.target.value;
        const newProviders = (instance==='providersPanel' || instance==='newProvider') ? [...providersList] : [...currentServiceOrder.providers];

        const newProvider = newProviders[providerIndex];
        newProvider.phoneNumber.number = value;

        if(instance==='providersList' || instance==='chat'){
            setCurrentServiceOrder({...currentServiceOrder, providers: newProviders});
        } else if(instance==='providersPanel' || instance==='newProvider'){
            setProvidersList(newProviders);
        }

    }, [currentServiceOrder, providersList]);

    return(
        <div className={styles.detail}>
            <div style={{width:'60px', height: '32px', display: 'flex', boxSizing: 'border-box'}}>{<DetailInput inputElement={<CountryCodeSelect readOnly={readOnly} provider={provider} instance={instance} />} width={'60px'} readOnly={readOnly} backgroundReadOnly={'transparent'} borderBottom={false}/>}</div>
            <div style={{width:'64px', height: '32px', display: 'flex', boxSizing: 'border-box'}}>{<DetailInput inputElement={<AreaCodeSelect readOnly={readOnly} provider={provider} instance={instance}/>} width={'64px'} readOnly={readOnly} backgroundReadOnly={'transparent'} borderBottom={false}/>}</div>
            <div style={{width:'96px', height: '32px', display: 'flex', boxSizing: 'border-box'}}>{<DetailInput inputElement={<MaskElement inputValue={provider.phoneNumber && provider.phoneNumber.number} onChange={changeProviderNumber} maskLayout={['9999-9999','99999-9999']} width={'96px'} readOnly={readOnly} />}width={'96px'} readOnly={readOnly} backgroundReadOnly={'transparent'} borderBottom={false}/>}</div>
        </div>
    )
}