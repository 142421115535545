// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._CMPLBY2xG1wxGXX8SIo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: transparent;
    font-family: var(--quotemaster-font-family);
    padding: 0px 8px;
    font-size: 15px;
    line-height: 15px;
}
`, "",{"version":3,"sources":["webpack://./clientRoot/Static/GlobalComponents/DateInput/DateElement.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,6BAA6B;IAC7B,2CAA2C;IAC3C,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".dateElement{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    height: 100%;\n    width: 100%;\n    box-sizing: border-box;\n    border: none;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    background-color: transparent;\n    font-family: var(--quotemaster-font-family);\n    padding: 0px 8px;\n    font-size: 15px;\n    line-height: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateElement": `_CMPLBY2xG1wxGXX8SIo`
};
export default ___CSS_LOADER_EXPORT___;
