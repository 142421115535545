import React, { useContext, useCallback } from 'react'

import styles from 'GlobalComponents/CommonStyles.module.css';

import LabelElement from 'GlobalComponents/LabelElement/LabelElement.js';
import { QuoteMasterContext } from 'Contexts/QuoteMasterContext.js';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput.js';
import TextInput from 'GlobalComponents/TextInput/TextInput.js';
import AutoCategorySelect from 'GlobalComponents/SelectionBox/SelectionBoxesInstances/AutoCategorySelect.js';

export default function AutoDetails({labelWidth, inputWidth}){
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);
    const changeTheValueOf = useCallback((field) => (event) => {
        const value = event.target.value; // Extract the value from the event object
        setCurrentServiceOrder((prevOrder) => ({
            ...prevOrder,
            autoInformation: {
                ...prevOrder.autoInformation,
                [field]: value
            }
        }));
    }, [setCurrentServiceOrder]);

    return(
        <div className={styles.parametersColumn} > Informações do Veículo
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Categoria do Veículo'}/>
                <DetailInput 
                    width={inputWidth}
                    inputElement={
                        <AutoCategorySelect 
                            inputValue={currentServiceOrder.autoInformation.autoCategory} 
                            onChange={changeTheValueOf('autoCategory')}
                        />
                    }
                />
            </div>
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Placa'}/>
                <DetailInput 
                    width={inputWidth}
                    inputElement={
                        <TextInput 
                            inputValue={currentServiceOrder.autoInformation.autoPlate} 
                            onChange={changeTheValueOf('autoPlate')}
                            readOnly={currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated'}
                        />
                    }
                />
            </div>
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Modelo'}/>
                <DetailInput 
                    width={inputWidth}
                    inputElement={
                        <TextInput 
                            inputValue={currentServiceOrder.autoInformation.autoModel} 
                            onChange={changeTheValueOf('autoModel')}
                            readOnly={currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated'}
                        />
                    }
                />
            </div>
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Ano'}/>
                <DetailInput 
                    width={inputWidth}
                    inputElement={
                        <TextInput 
                            inputValue={currentServiceOrder.autoInformation.autoYear} 
                            onChange={changeTheValueOf('autoYear')}
                            readOnly={currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated'}
                        />
                    }
                />
            </div>
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Chassis'}/>
                <DetailInput 
                    width={inputWidth}
                    inputElement={
                        <TextInput 
                            inputValue={currentServiceOrder.autoInformation.autoChassis} 
                            onChange={changeTheValueOf('autoChassis')}
                            readOnly={currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated'}
                        />
                    }
                />
            </div>
        </div>
    )
}