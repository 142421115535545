import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-modal';

import styles from './QuoteMaster.module.css';
import EntryMessage from './EntryMessagePage/EntryMessage.js';
import {QuoteMasterContext} from './QuoteMasterContext.js';
import WaitingProgressLine from './GlobalComponents/WaitingProgressLine/WaitingProgressLine.js';
import SOHeader from './WorkingArea/ServiceOrderManagement/SOHeader/SOHeader.js';
import ConversationsArea from './WorkingArea/ServiceOrderManagement/ChatWindows/ConversationsArea.js';
import ProviderQuoteDetailsPopup from './WorkingArea/ServiceOrderManagement/ProviderQuoteDetails/ProviderQuoteDetailsPopup.js';
import SODeletionConfirmation from './WorkingArea/ServiceOrdersTab/SODeletionConfirmation.js';
import {getUserServiceOrders} from './ServerCalls/serverCalls.js';
import AdminPanel from './AdminPanel/AdminPanel.js';
import ServiceOrdersTab from './WorkingArea/ServiceOrdersTab/ServiceOrdersTab.js';


export default function QuoteMaster() {
    Modal.setAppElement('#root');
    const {SOToDelete, user, setUser, setLoading, loading, setServiceOrders, providerQuoteDetails} = useContext(QuoteMasterContext);
    const [pageDimensions, setPageDimensions] = useState({width: window.innerWidth, height: window.innerHeight});
    const [userList, setUserList] = useState([]);
    const pageHeaderHeight = 60;
    const [orderManagementDimensions, setOrderManagementDimensions] = useState({
        width: pageDimensions.width,
        height: pageDimensions.height - pageHeaderHeight
    });
    const [isAdminPanelOpen, setIsAdminPanelOpen] = useState(false);
    const [isServiceOrdersTabOpen, setIsServiceOrdersTabOpen] = useState(true);
    const [headerView, setHeaderView] = useState('main');
    const [isSOHeaderOpen, setIsSOHeaderOpen] = useState(true);
    const serviceOrdersTabWidth=248;
    const SOHeaderTabWidth = headerView==='main'? 352 : window.innerWidth - serviceOrdersTabWidth;
    const conversationsWidth = pageDimensions.width - (isSOHeaderOpen ? SOHeaderTabWidth : 0) - (isServiceOrdersTabOpen ? serviceOrdersTabWidth : 0);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function handleResize() {
        setPageDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
        setOrderManagementDimensions({
            width: window.innerWidth,
            height: window.innerHeight - pageHeaderHeight
        });
    }

    useEffect(() => {
        console.log('page is loading for the first time')
        checkUserInStorage();
    }, []);

    async function checkUserInStorage(){
        console.log('check if user in the local storage')
        const storedQuoteMasterUser = localStorage.getItem('quoteMasterUser');
        if (storedQuoteMasterUser) { // if there is a user in the local storage, we use it 
            console.log('setting user from the local storage')
            const parsedstoredQuoteMasterUser= JSON.parse(storedQuoteMasterUser);
            setUser(parsedstoredQuoteMasterUser);
            if(process.env.REACT_APP_NODE_ENV!=='development'){ // just run get setvice orders if not in development
                handleGetServiceOrders();
            }
        } else{
            console.log('no user logged in' )
        }
    }

    const handleGetServiceOrders = async () => {
        setLoading(true);
        try{
            const response = await getUserServiceOrders();
            if(response.serviceOrders.length === 0){
                console.log('No service orders found');
                setLoading(false);
                return;
            }
            setServiceOrders(response.serviceOrders);
            setLoading(false);
        } catch(error){
            setLoading(false);
            console.error('Error getting user service orders', error);
        }
        
    }

    return (
        <div className={styles.mainstructure}>
            {!user.userEmail ?
                 <EntryMessage areaDimensions={orderManagementDimensions} handleGetServiceOrders={handleGetServiceOrders}/>
                : 
                <>
                    {(isAdminPanelOpen && user.userType==='master-admin') ? 
                        <AdminPanel userList={userList} setUserList={setUserList} setIsAdminPanelOpen={setIsAdminPanelOpen}/>
                        : 
                        <>
                            {loading && <WaitingProgressLine  /> }           
                            <div className={styles.workingArea}
                                style={{height: window.innerHeight}}
                            >    
                                {isServiceOrdersTabOpen && <ServiceOrdersTab handleGetServiceOrders={handleGetServiceOrders} setIsAdminPanelOpen={setIsAdminPanelOpen} serviceOrdersTabWidth={serviceOrdersTabWidth}/>}
                                {isSOHeaderOpen && <SOHeader SOHeaderTabWidth={SOHeaderTabWidth} headerView={headerView} setHeaderView={setHeaderView}/>}
                                {headerView==='main' && <ConversationsArea setIsSOHeaderOpen={setIsSOHeaderOpen} isSOHeaderOpen={isSOHeaderOpen} conversationsWidth={conversationsWidth} conversationsHeight={pageDimensions.height}/> }
                            </div> 
                            {providerQuoteDetails && 
                                <ProviderQuoteDetailsPopup providerIndex={providerQuoteDetails.providerIndex} provider={providerQuoteDetails.provider}/>
                            }
                        </>
                    }
                    {SOToDelete && <SODeletionConfirmation />}
                </>
            }
        </div>
    )
}




