import React, { useEffect, useState  } from 'react'
import styles from 'GlobalComponents/CommonStyles.module.css';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput.js';
import TextInput from 'GlobalComponents/TextInput/TextInput.js';


export default function BestQuote({type, quote, providerName}){
    const [days, setDays] = useState(0);
    const [tier, setTier] = useState(0);

    useEffect(() => {
        //check if the quote object is not empty 
        if(quote.shortestAvailability){
            if(type==='bestQuote'){
                setDays(quote.shortestAvailability.relativeDays);
            } else {
                setDays(quote.shortestAvailability.days);
            }
        }
    }, [quote]);

    useEffect(() => {
        if(quote.brand){
            setTier(quote.brand.tier);
        }
    }, [quote]);




    return(
        <div className={styles.parametersColumn} style={{marginBottom: '0px'}}> 

            <div className={styles.parameterRow}>
                <DetailInput
                    width={'200px'}
                    readOnly={true}
                    inputElement={
                        <TextInput
                            inputValue={type==='bestQuote' ? (providerName) : 'Target Atual'}
                            onChange={() => (e) => {}}
                            readOnly={true}
                        />
                    }
                />
                <DetailInput
                    width={'96px'}
                    readOnly={true}
                    inputElement={
                        <TextInput
                        inputValue={quote.price || ''}
                            onChange={() => (e) => {}}
                            readOnly={true}
                        />
                    }
                />
                <DetailInput
                    width={'60px'}
                    readOnly={true}
                    inputElement={
                        <TextInput
                        inputValue={quote.rate || ''}
                            onChange={() => (e) => {}}
                            readOnly={true}
                        />
                    }
                />
                <DetailInput
                    width={'60px'}
                    readOnly={true}
                    inputElement={
                        <TextInput
                        inputValue={days}
                            onChange={() => (e) => {}}
                            readOnly={true}
                        />
                    }
                /> 
                <DetailInput
                    width={'60px'}
                    readOnly={true}
                    inputElement={
                        <TextInput
                        inputValue={tier}
                            onChange={() => (e) => {}}
                            readOnly={true}
                        />
                    }
                />
            </div>
        </div>
    )
}