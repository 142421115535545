// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ouI9ThIKE0eFLktxPDjk {
    min-height: 130px;
    min-width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 16px;
    transform: translate(-50%, -50%);
    width: 256px;
    height: 232px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: var(--border-medium);
    border-width: 2px;
    outline: none; /* Removes the default focus outline */
    background-color: var(--background-light);
}

.ouI9ThIKE0eFLktxPDjk:focus {
    border-style: solid;
    border-color: var(--border-light);
    border-width: 2px;
    outline: none; /* Removes the default focus outline */
}

.uqdngDNUdIL0o2HZownz{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: 700;
    color: var(--letter-dark);
    line-height: 20px;
    margin: 16px 0 16px 0;
}

.kwi2k2MShu7eArEJIgXo{
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    flex-direction: row; 
    margin-top: 16px;
    margin-bottom: 32px;
}`, "",{"version":3,"sources":["webpack://./clientRoot/Static/ProvidersPanel/ProviderModal.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,aAAa;IACb,gCAAgC;IAChC,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,kCAAkC;IAClC,iBAAiB;IACjB,aAAa,EAAE,sCAAsC;IACrD,yCAAyC;AAC7C;;AAEA;IACI,mBAAmB;IACnB,iCAAiC;IACjC,iBAAiB;IACjB,aAAa,EAAE,sCAAsC;AACzD;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".newUserPopup {\n    min-height: 130px;\n    min-width: 500px;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    padding: 16px;\n    transform: translate(-50%, -50%);\n    width: 256px;\n    height: 232px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    border-style: solid;\n    border-color: var(--border-medium);\n    border-width: 2px;\n    outline: none; /* Removes the default focus outline */\n    background-color: var(--background-light);\n}\n\n.newUserPopup:focus {\n    border-style: solid;\n    border-color: var(--border-light);\n    border-width: 2px;\n    outline: none; /* Removes the default focus outline */\n}\n\n.actionModalTitle{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    font-size: 20px;\n    font-weight: 700;\n    color: var(--letter-dark);\n    line-height: 20px;\n    margin: 16px 0 16px 0;\n}\n\n.confirmationButtons{\n    display: flex;\n    justify-content: space-between; \n    align-items: center; \n    flex-direction: row; \n    margin-top: 16px;\n    margin-bottom: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newUserPopup": `ouI9ThIKE0eFLktxPDjk`,
	"actionModalTitle": `uqdngDNUdIL0o2HZownz`,
	"confirmationButtons": `kwi2k2MShu7eArEJIgXo`
};
export default ___CSS_LOADER_EXPORT___;
