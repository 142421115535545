// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XEIyPHTBn9hnGpBp1n3L{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    font-size: 16px;
    line-height: 40px;
    font-weight: 700;
    background-color: var(--background-dark);
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    color: var(--letter-light);
}`, "",{"version":3,"sources":["webpack://./clientRoot/Static/WorkingArea/ServiceOrderManagement/ChatWindows/ServiceOrderStatusDisplay/ServiceOrderStatusDisplay.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,wCAAwC;IACxC,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,0BAA0B;AAC9B","sourcesContent":[".serviceOrderStatusDisplay{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    padding: 10px 16px;\n    font-size: 16px;\n    line-height: 40px;\n    font-weight: 700;\n    background-color: var(--background-dark);\n    width: 100%;\n    height: 60px;\n    box-sizing: border-box;\n    color: var(--letter-light);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceOrderStatusDisplay": `XEIyPHTBn9hnGpBp1n3L`
};
export default ___CSS_LOADER_EXPORT___;
