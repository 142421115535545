import React, {useContext} from 'react';
import { saveServiceOrder } from 'Contexts/ServerCalls/serverCalls.js';
import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js';
import { QuoteMasterContext } from 'Contexts/QuoteMasterContext.js';


export default function SaveButton({}){
    const {currentServiceOrder, setCurrentServiceOrder, setLoading, setServiceOrders, serviceOrders} = useContext(QuoteMasterContext);

    const handleSave = async () => {
        setLoading(true);
        const response = await saveServiceOrder(currentServiceOrder);
        if(response.message==='Service Order saved successfully'){
            if(currentServiceOrder.serviceOrderId===''){
                setCurrentServiceOrder({...currentServiceOrder, serviceOrderId: response.serviceOrderId, timeLastUpdate: response.timeLastUpdate});
            } else {
                setCurrentServiceOrder({...currentServiceOrder, timeLastUpdate: response.timeLastUpdate});
            }
            const shortenedServiceOrder = {
                serviceOrderId:response.serviceOrderId,
                currentStage:currentServiceOrder.currentStage, 
                timeLastUpdate:response.timeLastUpdate,
                user: currentServiceOrder.user,
                providers: currentServiceOrder.providers.map(provider => ({providerName: provider.providerName, affiliateId: provider.affiliateId})),
                runType: currentServiceOrder.runType,
            };

            const newServiceOrders = [...serviceOrders, shortenedServiceOrder];
            setServiceOrders(newServiceOrders);
            console.log('Service order saved successfully');
        } else {
            alert('Erro ao salvar a ordem de serviço');
        }
        setLoading(false);
    }

    return(
        <GlobalButton  onClick={handleSave} label='Salvar' width='80px' type='primary' />
    )
}