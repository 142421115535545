
import React, { useContext, useState, useEffect, useCallback  } from 'react';
import styles from './ChatHeader.module.css';

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import OpenProviderDetailsButton from './OpenProviderDetailsButton.js';
import TextInput from 'GlobalComponents/TextInput/TextInput.js';
import NumberInput from 'GlobalComponents/NumberInput/NumberInput.js';
import TimeToGo from '../TimeToGo/TimeToGo.js';
import Tooltip from '@mui/material/Tooltip';
import TelephoneInput from 'GlobalComponents/TelephoneInput/TelephoneInput.js';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput.js';


export default function ChatHeader({provider, headerWidth, headerHeight, view, instance, setSelectedProvider, selectedProvider, selectedProviderIndex}){
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);
    const [message, setMessage] = useState(null);

    let providerIndex = currentServiceOrder.providers.findIndex((prov) => prov.providerName===provider.providerName);
    if(providerIndex===-1){
        providerIndex = 0;
        provider = currentServiceOrder.providers[0];
        setSelectedProvider(provider);
        if(selectedProviderIndex) {selectedProviderIndex.current = 0;};
    }
    const [isHovered, setIsHovered] = useState(false);
    const backgroundColor = instance==='providersList' ? (selectedProvider.providerName===provider.providerName? 'var(--background-regular)': 'transparent' ): 'var(--background-regular)'; 
    const hoverBackgroundColor =  instance==='providersList' ? (selectedProvider.providerName===provider.providerName? 'var(--background-regular)': 'var(--background-hovered)' ): 'var(--background-regular)';
    const followUpTime = currentServiceOrder.followUpTime*currentServiceOrder.timeMultiplier;

    useEffect(() => {
        if(currentServiceOrder.currentStage==='notInitiated' || currentServiceOrder.currentStage==='fallbackToHuman'){
            setMessage(null);
            return;
        }

        if(currentServiceOrder.currentStage==='closed'){
            if(currentServiceOrder.bestQuoteProvider===provider.affiliateId){
                setMessage('Afiliado Selecionado');    
            } else {
                setMessage('Afiliado Não Selecionado');
            }
            return;
        }

        const completedQuoteAtStage = provider.itemQuotes.find(itemQuote => itemQuote.stage===currentServiceOrder.currentStage && itemQuote.completed);
        if(completedQuoteAtStage){
            setMessage('Cotação Recebida');
            return;
        }


        if(provider.timeOflastMessageSentToProvider==='formulatingAnswer'){
            setMessage('...resposta recebida');
            return;
        }
        if(provider.timeOflastMessageSentToProvider==='formulatingFollowUp'){
            setMessage('...formulando followUp');
            return;
        }

        if(provider.waitingResponse){
            setMessage(<TimeToGo type={'followUp'} length={followUpTime}  startTime={provider.timeOflastMessageSentToProvider}/>)
            return;
        }

        if(provider.conversation.length>0 && !provider.waitingResponse && provider.conversation[provider.conversation.length-1].role==='QuoteMaster'){
            setMessage('Aguardando Resposta');
            return;
        }

        setMessage(null);

    }, [currentServiceOrder, selectedProvider]);

    const changeProvider = useCallback((field, index) => (event) => {
        const value = event.target.value;
        const newProviders = [...currentServiceOrder.providers];
        const newProvider = newProviders[index];
        const keys = field.split('.');
        if(keys.length===1){
            newProvider[field] = value;
        } else if(keys.length===2){ // deals with nested objects up to 2 levels deep
            newProvider[keys[0]][keys[1]] = value;
        }
        setCurrentServiceOrder({...currentServiceOrder, providers: newProviders});
    }, [currentServiceOrder]);

    const handleHeaderClick = () => {   
        if(instance==='providersList' ){
            setSelectedProvider(provider);
            selectedProviderIndex.current = providerIndex;
        }
    }

    const readOnlyDefault = currentServiceOrder.currentStage!=='notInitiated';

    return(
        <div  
            className={styles.chatHeader} 
            onClick={handleHeaderClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)} 
            style={{
                height: headerHeight, 
                minHeight: headerHeight,
                backgroundColor: isHovered ? hoverBackgroundColor: backgroundColor,
                borderRadius: (view==='singleChatWindow')? '0px 0px 0px 0px':'16px 16px 0px 0px',
                borderBottom: instance==='providersList'? '1px solid var(--border-dark)': 'none',
                width: headerWidth ? headerWidth : '100%'

            }}>
            <div className={styles.chatHeaderRow}>
                 <DetailInput 
                    inputElement={<TextInput width={(view==='singleChatWindow' && instance === 'chat') ? '272px': '168px'} inputValue={provider.providerName} onChange={changeProvider('providerName', providerIndex)} color={'var(--letter-dark)'} readOnly={readOnlyDefault}/>}
                    width={(view==='singleChatWindow' && instance === 'chat') ? '272px': '168px'}
                    readOnly={readOnlyDefault}
                    backgroundReadOnly={'transparent'}
                    borderBottom={false}
                />
                <div className={styles.message} style={{width: '178px'}}>{message}</div>
            </div>
            <div className={styles.chatHeaderRow}>

                <div className={styles.detail}>
                <div style={{display: 'flex', boxSizing: 'border-box', width:'40px', height:'32px', flexDirection: 'row', alignItems: 'center', paddingLeft: '0px'}}>Tel:</div>

                    <TelephoneInput provider={provider} readOnly={readOnlyDefault} instance={instance}/>
                </div>
                <div className={styles.detail} style={{width: '120px'}}>
                    <div style={{display: 'flex', boxSizing: 'border-box', width:'32px', height:'32px', flexDirection: 'row', alignItems: 'center', paddingLeft: '8px'}}>ID:</div>
                    <DetailInput inputElement={<TextInput inputValue={provider.affiliateId} onChange={changeProvider('affiliateId', providerIndex)} width={'88px'} readOnly={readOnlyDefault}/>} width={'88px'} readOnly={readOnlyDefault} backgroundReadOnly={'transparent'} borderBottom={false}/>
                </div>
            </div>
            {(view==='multiChatWindow' || (instance==='providersList' && view==='singleChatWindow')) &&
                <div className={styles.chatHeaderRow} >
                    <div className={styles.detail} style={{width: '100px'}}>
                        Preço:
                        <NumberInput width={'60px'} inputValue={provider.providerBestQuote.price} readOnly={true} onChange={() => (e) => {}} />
                    </div>
                    <div className={styles.detail} style={{width: '80px'}}>
                        <Tooltip 
                            title={
                                provider.providerBestQuote.brand.tier===2 || provider.providerBestQuote.shortestAvailability.availability==='needToOrder' ?
                                provider.providerBestQuote.brand.tier===2 && provider.providerBestQuote.shortestAvailability.availability==='needToOrder' ?
                                        "Considera uma penalidade de 7 dias por marcas de segunda linha e 3 dias para encomendadas" :
                                        provider.providerBestQuote.brand.tier===2 ?
                                            "Considera uma penalidade de 7 dias caso haja produtos de segunda linha na cotação" :
                                            "Considera uma penalidade de 3 dias caso haja produtos encomendados na cotação"
                                :''
                            }
                            placement={'bottom'} 
                            >
                                <div>
                                    {provider.providerBestQuote.brand.tier===2 || provider.providerBestQuote.shortestAvailability.availability==='needToOrder' ? 'Dias*': 'Dias'}:
                                </div>
                        </Tooltip>
                        <NumberInput width={'40px'} inputValue={provider.providerBestQuote.shortestAvailability.relativeDays} readOnly={true} onChange={() => (e) => {}}  />
                    </div>   
                    <div className={styles.detail} style={{width: '80px'}}>
                        Linha:
                        <NumberInput width={'30px'} inputValue={provider.providerBestQuote.brand.tier} readOnly={true} onChange={() => (e) => {}}/>
                    </div>
                    <OpenProviderDetailsButton provider={provider}/>
                </div>
            }
        </div>
    )
}

