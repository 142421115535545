

const glassBrands = [
    {assignedBrandName: 'pilkington', tier: 1, brandName: 'Pilkington'}, 
    {assignedBrandName: 'plk', tier: 1, brandName: 'Pilkington'},
    {assignedBrandName: 'skt', tier: 1, brandName: 'Sekurit / Saint Gobain'},
    {assignedBrandName: 'sg', tier: 1, brandName: 'Sekurit / Saint Gobain'},
    {assignedBrandName: 'sain', tier: 1, brandName: 'Sekurit / Saint Gobain'},
    {assignedBrandName: 'sekurit (Saint Gobain)', tier: 1, brandName: 'Sekurit / Saint Gobain'},
    {assignedBrandName: 'sekurit', tier: 1, brandName: 'Sekurit / Saint Gobain'},
    {assignedBrandName: 'saint gobain', tier: 1, brandName: 'Sekurit / Saint Gobain'},
    {assignedBrandName: 'agc', tier: 1, brandName: 'AGC'},
    {assignedBrandName: 'ais', tier: 1, brandName: 'AIS'},
    {assignedBrandName: 'nordGlass', tier: 1, brandName: 'NordGlass'},
    {assignedBrandName: 'fuyao', tier: 1,  brandName: 'Fuyao'},
    {assignedBrandName: 'fy', tier: 1, brandName: 'Fuyao'},
    {assignedBrandName: 'genuino', tier: 1, brandName: 'Genuíno/Concessionária'},
    {assignedBrandName: 'benson', tier: 1, brandName: 'Benson'},
    {assignedBrandName: 'concessionaria', tier: 1, brandName: 'Genuíno/Concessionária'},


    {assignedBrandName: 'fanavid', tier: 2, brandName: 'Fanavid'},
    {assignedBrandName: 'fana', tier: 2, brandName: 'Fanavid'},
    {assignedBrandName: 'vidroforte', tier: 2, brandName: 'Vidroforte'},
    {assignedBrandName: 'xyg', tier: 2, brandName: 'XYG'},
    {assignedBrandName: 'vitro do brasil', tier: 2, brandName: 'Vitro do Brasil'},
    {assignedBrandName: 'vitro', tier: 2, brandName: 'Vitro do Brasil'},
    {assignedBrandName: 'guardian', tier: 2, brandName: 'Guardian'},
    {assignedBrandName: 'thermoglass', tier: 2, brandName: 'Thermoglass'},
    {assignedBrandName: 'thermo', tier: 2, brandName: 'Thermoglass'},
    {assignedBrandName: 'termo', tier: 2, brandName: 'Thermoglass'},

    {assignedBrandName: 'psg', tier: 3, brandName: 'PSG'},
    {assignedBrandName: 'veltrio', tier: 3, brandName: 'Veltrio'},
    {assignedBrandName: 'shatterprufe', tier: 3, brandName: 'Shatterprufe'},
    {assignedBrandName: 'splintex', tier: 3, brandName: 'Splintex'},
    {assignedBrandName: 'glasstech',  tier: 3, brandName: 'Glasstech'}
]

const accessoriesBrands = [
    {assignedBrandName: 'ampas', tier: 1, brandName: 'Ampas'},
    {assignedBrandName: 'marelli', tier: 1, brandName: 'Marelli'},
    {assignedBrandName: 'arteb', tier: 1, brandName: 'Arteb'},
    {assignedBrandName: 'cibie', tier: 1, brandName: 'Cibie'},
    {assignedBrandName: 'valeo', tier: 1, brandName: 'Valeo'},
    {assignedBrandName: 'metagal', tier: 1, brandName: 'Metagal'},
    {assignedBrandName: 'hella', tier: 1, brandName: 'Hella'},
    {assignedBrandName: 'koyto', tier: 1, brandName: 'Koyto'},
    {assignedBrandName: 'ficosa', tier: 1, brandName: 'Ficosa'},
    {assignedBrandName: 'automotive light', tier: 1, brandName: 'Automotive Light'},
    {assignedBrandName: 'ithikoh', tier: 1, brandName: 'Ithikoh'},
    {assignedBrandName: 'bosch', tier: 1, brandName: 'Bosch'},
    {assignedBrandName: 'genuino', tier: 1, brandName: 'Genuino'},

    {assignedBrandName: 'luxcar', tier: 2, brandName: 'Luxcar'},
    {assignedBrandName: 'viewmax', tier: 2, brandName: 'Viewmax'},
    {assignedBrandName: 'tyc', tier: 2, brandName: 'TYC'},
    {assignedBrandName: 'depo', tier: 2, brandName: 'Depo'},
    {assignedBrandName: 'orguns', tier: 2, brandName: 'Orguns'},
    {assignedBrandName: 'fitam', tier: 2, brandName: 'Fitam'},
    {assignedBrandName: 'fly', tier: 2, brandName: 'FLY'},
    {assignedBrandName: 'rufato', tier: 2, brandName: 'Rufato'},
    {assignedBrandName: 'rf', tier: 2, brandName: 'Rufato'},
    {assignedBrandName: 'moracami', tier: 2, brandName: 'Moracami'},
    {assignedBrandName: 'polyway', tier: 2, brandName: 'Polyway'},
    {assignedBrandName: 'cofran', tier: 2, brandName: 'Cofran'},
    {assignedBrandName: 'nino', tier: 2, brandName: 'Nino'}
];



const categories = [
    'glasses', 'accesories', 'tools'
]

export const selectionItems = (itemGroup) => {
    let brands = [];
    if(itemGroup === 'glasses'){
        brands= glassBrands;
    } else{
        brands= accessoriesBrands
    }
    // take out repetitions on brandName
    let uniqueBrandsNames = [];
    brands.forEach(brand => {
        if(!uniqueBrandsNames.includes(brand.brandName)){
            uniqueBrandsNames.push(brand.brandName);
        }
    });
    
    return uniqueBrandsNames.map(uniqueBrand => 
        ({value: uniqueBrand, label: uniqueBrand, tier: brands.find(brand => brand.brandName === uniqueBrand).tier})
    )
}
