
import React, { useEffect, useState, useContext, useCallback } from 'react';

import styles from './ProvidersPanel.module.css';

import {getProvidersReport} from '../ServerCalls/serverCalls.js';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';

import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js'
import WaitingProgressLine from 'GlobalComponents/WaitingProgressLine/WaitingProgressLine.js';

import RestorePageOutlinedIcon from '@mui/icons-material/RestorePageOutlined';
import TelephoneInput from '../GlobalComponents/TelephoneInput/TelephoneInput.js';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

export default function ProvidersReport({setIsProvidersReportOpen }) {

    const {loading, setLoading, setProvidersReport} = useContext(QuoteMasterContext);
    const [updateFilter, setUpdateFilter] = useState(false);
    const [createFilter, setCreateFilter] = useState(false);
    const [deleteFilter, setDeleteFilter] = useState(false);


    useEffect(() => {
        updateProvidersReport();
    }, []);

    async function updateProvidersReport() {
        setLoading(true);
        const response = await getProvidersReport();
        if (response === 'failed') {
            console.log('Failed to get providers report');
            setLoading(false);
            return;
        }
        setProvidersReport(response.report);
        setLoading(false);
    }

    return (
        <div className={styles.providersPanel}>
            <div className={styles.providersPanelHeader}>
                <div className={styles.providersPanelButtons}>
                    <GlobalButton
                        onClick={() => setIsProvidersReportOpen(false)}
                        label={'Fechar Relatório de Edição Afiliados'}
                        width={'184px'}
                        type={'secondary'}
                    />
                    <GlobalButton 
                            onClick={updateProvidersReport} 
                            label={
                                <> 
                                    <RestorePageOutlinedIcon /> 
                                    <span>Atualizar<br/>Relatório</span>
                                </>
                            }
                            width={'184px'}
                            type={'secondary'}
                    />
                </div>
                <div className={styles.providersPanelTitle}>Relatório de Edição de Afiliados</div>
                <div className={styles.providersPanelButtons}>
                    <GlobalButton
                        onClick={() => setUpdateFilter(!updateFilter)}
                        label={'Atualizações'}
                        width={'184px'}
                        type={updateFilter ? 'selected' : 'secondary'}
                    />
                    <GlobalButton
                        onClick={() => setCreateFilter(!createFilter)}
                        label={'Inserções'}	
                        width={'184px'}
                        type={createFilter ? 'selected' : 'secondary'}
                    />
                    <GlobalButton
                        onClick={() => setDeleteFilter(!deleteFilter)}
                        label={'Exclusões'}
                        width={'184px'}
                        type={deleteFilter ? 'selected' : 'secondary'}
                    />

                </div>
            </div>
            {loading ? <WaitingProgressLine /> : null}
            <ProviderEditList deleteFilter={deleteFilter} createFilter={createFilter} updateFilter={updateFilter} />
        </div>

    )
}

function ProviderEditList({deleteFilter, createFilter, updateFilter }) {
    const {providersReport} = useContext(QuoteMasterContext);
    const [providersReportFixedDate, setProvidersReportFixedDate] = useState([]);

    useEffect(() => {
        if(providersReport.length>0){
            let fixedDateReport = []
            for (const edit of providersReport) {
                if(!updateFilter && !createFilter && !deleteFilter){ // if no filter is selected, show all edits
                   // if no filter is selected, show all edits
                } else if(!updateFilter && edit.type==='updateProvider'){ // if the update filter is not selected, do not show updates
                    continue;
                } else if(!createFilter && edit.type==='newProvider'){ // if the create filter is not selected, do not show creates
                    continue;
                } else if(!deleteFilter && edit.type==='deleteProvider'){ // if the delete filter is not selected, do not show deletes
                    continue;
                }
                const newEdit = {...edit};
                const date = edit.created.split('T')[0];
                newEdit.date = date.split('-')[2] + '/' + date.split('-')[1] + '/' + date.split('-')[0];
                const time = edit.created.split('T')[1].split('.')[0];
                // remove the timezone and show only the time
                newEdit.time = time.split(':')[0] + ':' + time.split(':')[1];
                fixedDateReport.push(newEdit);
            }
            setProvidersReportFixedDate(fixedDateReport);
        }
    }, [providersReport, deleteFilter, createFilter, updateFilter]);

    const sortReport = useCallback((field) => {
        const keys = field.split('.');
        let sortedReport = [];
        console.log('sorting report keys', keys, 'keys length', keys.length);
        if(keys.length===1){
            sortedReport = [...providersReportFixedDate].sort((a, b) => {
                if(a[field] < b[field]){
                    return -1;
                }
                if(a[field] > b[field]){
                    return 1;
                }
                return 0;
            });
        } else if (keys.length===2){
            sortedReport = [...providersReportFixedDate].sort((a, b) => {
                if(a[keys[0]][keys[1]] < b[keys[0]][keys[1]]){
                    return -1;
                }
                if(a[keys[0]][keys[1]] > b[keys[0]][keys[1]]){
                    return 1;
                }
                return 0;
            });
        }
        setProvidersReportFixedDate(sortedReport);
    }, [providersReportFixedDate]); 

    return (
        <div className={styles.providersList}>
            <div className={styles.providersReportHeader}>
                <div className={styles.editingType} onClick={()=>sortReport('type')}>Tipo de Edição</div>
                <div className={styles.editingDate} onClick={()=>sortReport('created')}>Data de Edição</div>
                <div className={styles.editingDate}>Hora de Edição</div>
                <div className={styles.providerName} onClick={()=>sortReport('newProvider.providerName')}>Nome</div>
                <div className={styles.affiliateId} onClick={()=>sortReport('newProvider.affiliateId')}>Affiliate Id</div>
                <div className={styles.phoneNumber}>Telefone</div>
                <div className={styles.validWhatsapp} onClick={()=>sortReport('newProvider.validWhatsapp')}>Whatsapp Válido</div>
                <div className={styles.providerStatus} onClick={()=>sortReport('newProvider.status')}>Status</div>
            </div>
            {providersReportFixedDate.map((edit, index) => (
                <EditLine edit={edit} key={index} />
            ))}
        </div>
    )
}

function EditLine({edit}) {

    function editType(type) {
        switch (type) {
            case 'updateProvider':
                return 'Atualização';
            case 'newProvider':
                return 'Inserção';
            case 'deleteProvider':
                return 'Exclusão';
            default:
                return 'Tipo Desconhecido';
        }
    }
    return (
        <div className={styles.edit}>
            <div className={styles.editLine}>
                <div className={styles.editingType}>{editType(edit.type)}</div>
                <div className={styles.editingDate}>{edit.date}</div>
                <div className={styles.editingDate}>{edit.time}</div>
                <div className={styles.providerName}>{edit.newProvider.providerName}</div>
                <div className={styles.affiliateId}>{edit.newProvider.affiliateId}</div>
                <div className={styles.phoneNumber}> {edit.type!=='deleteProvider' && <TelephoneInput provider={edit.newProvider} readOnly={true} instance={'providersReport'}/>} </div>
                <div className={styles.validWhatsapp}>{edit.newProvider.validWhatsapp ? <CheckCircleIcon style={{color: 'green'}}/> : <ErrorIcon style={{color: 'red'}}/>}</div>
                <div className={styles.providerStatus}>{edit.newProvider.status}</div>
            
            </div>
            {edit.type==='updateProvider' &&
                <div className={styles.editLine}>
                    <div className={styles.editingType}>{'Informações Originais'}</div>
                    <div className={styles.editingDate}>{''}</div>
                    <div className={styles.editingDate}>{''}</div>
                    <div className={styles.providerName}>{edit.oldProvider.providerName}</div>
                    <div className={styles.affiliateId}>{edit.oldProvider.affiliateId}</div>
                    <div className={styles.phoneNumber}> {<TelephoneInput provider={edit.oldProvider} readOnly={true} instance={'providersReport'}/>} </div>
                    <div className={styles.validWhatsapp}>{edit.oldProvider.validWhatsapp ? <CheckCircleIcon style={{color: 'green'}}/> : <ErrorIcon style={{color: 'red'}}/>}</div>
                    <div className={styles.providerStatus}>{edit.oldProvider.status}</div>
                </div>
            }
        </div>
    )
}