import React, { useContext} from 'react';
import SwitchOption from 'GlobalComponents/SwitchOption/SwitchOption';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';

export default function MobileServiceSwitch({providerIndex}){
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);

    const changeMobileServiceOption = () => {
        const newServiceOrder = {...currentServiceOrder};
        const newProvider = newServiceOrder.providers[providerIndex];
        newProvider.providerBestQuote.mobileService = !newProvider.providerBestQuote.mobileService;

        setCurrentServiceOrder(newServiceOrder);
    }


    return(
        <SwitchOption 
            checked={currentServiceOrder.providers[providerIndex].providerBestQuote.mobileService}
            onChange={changeMobileServiceOption} 
            name="checked" 
            color="primary"
            disabled={currentServiceOrder.currentStage !== 'notInitiated' || !currentServiceOrder.providers[providerIndex].quoteViaLink}
        />
    )
}