

import React, { useContext, useCallback } from 'react'

import styles from 'GlobalComponents/CommonStyles.module.css';

import LabelElement from 'GlobalComponents/LabelElement/LabelElement.js';
import { QuoteMasterContext } from 'Contexts/QuoteMasterContext.js';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput.js';
import TextInput from 'GlobalComponents/TextInput/TextInput.js';
import MaskElement from 'GlobalComponents/MaskedInput/MaskedInput.js';
import StateSelect from 'GlobalComponents/SelectionBox/SelectionBoxesInstances/StateSelect';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Tooltip from '@mui/material/Tooltip';
import {getAddress} from 'Contexts/ServerCalls/serverCalls.js';


export default function ServiceAddress({labelWidth, inputWidth}){
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);
    const changeTheValueOf = useCallback((field) => (event) => {
        const value = event.target.value; // Extract the value from the event object
        setCurrentServiceOrder((prevOrder) => ({
            ...prevOrder,
            serviceAddress: {
                ...prevOrder.serviceAddress,
                [field]: value
            }
        }));
    }, [currentServiceOrder]);

    const obtainAddress = async () => {
        const address = await getAddress(currentServiceOrder.serviceAddress.latitude, currentServiceOrder.serviceAddress.longitude);
        if(address!='No address found'){
            setCurrentServiceOrder((prevOrder) => ({
                ...prevOrder,
                serviceAddress: {
                    ...prevOrder.serviceAddress,
                    city: address.city,
                    state: address.state,
                    suburb: address.suburb
                }
            }));
        } else{
            alert('Endereço não encontrado');
        }
    }



    const  defaultReadOnly = currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated';
    return(
        <div className={styles.parametersColumn}>
            <div className={styles.parameterRow} style={{color: 'var(--letter-dark)'}}>
                <div >Endereço do Serviço</div>
                <Tooltip title={'Obter Endereço a partir de Latlong'} placement={"bottom"}> 
                    <div>
                        {currentServiceOrder.serviceAddress && currentServiceOrder.serviceAddress.latitude && currentServiceOrder.serviceAddress.longitude && <LocationOnOutlinedIcon onClick={obtainAddress} style={{cursor:'pointer'}}/>}
                    </div>
                </Tooltip>
            </div> 
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Estado'}/>
                <DetailInput 
                    width={inputWidth}
                    readOnly={true}
                    inputElement={
                        <StateSelect 
                            readOnly={true}
                        />
                    }
                />
            </div>	
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Cidade'}/>
                <DetailInput 
                    width={inputWidth}
                    readOnly={true}
                    inputElement={
                        <TextInput 
                            inputValue={currentServiceOrder.serviceAddress ? currentServiceOrder.serviceAddress.city:""} 
                            onChange={changeTheValueOf('city')}
                            readOnly={true}
                        />
                    }
                />
            </div>
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Bairro'}/>
                <DetailInput 
                    width={inputWidth}
                    readOnly={true}
                    inputElement={
                        <TextInput 
                            inputValue={currentServiceOrder.serviceAddress ? currentServiceOrder.serviceAddress.suburb:""} 
                            onChange={changeTheValueOf('suburb')}
                            readOnly={true}
                        />
                    }
                />
            </div>	
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Latitude'}/>
                <DetailInput 
                    width={inputWidth}
                    inputElement={
                        <TextInput 
                            inputValue={currentServiceOrder.serviceAddress ? currentServiceOrder.serviceAddress.latitude:""} 
                            onChange={changeTheValueOf('latitude')}
                            readOnly={defaultReadOnly}
                        />
                    }
                />
            </div>	
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Longitude'}/>
                <DetailInput 
                    width={inputWidth}
                    inputElement={
                        <TextInput 
                            inputValue={currentServiceOrder.serviceAddress ? currentServiceOrder.serviceAddress.longitude:""} 
                            onChange={changeTheValueOf('longitude')}
                            readOnly={defaultReadOnly}
                        />
                    }
                />
            </div>
        </div>
    )
}



