import React, {useContext} from 'react'
import styles from 'GlobalComponents/CommonStyles.module.css';
import BestQuote from './BestQuote.js';;
import LabelElement from 'GlobalComponents/LabelElement/LabelElement.js';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import Tooltip from '@mui/material/Tooltip';

export default function QuotesSummary({}){
    const {currentServiceOrder} = useContext(QuoteMasterContext);
    const bestQuoteProvider = currentServiceOrder.providers.find(provider=>provider.affiliateId===currentServiceOrder.bestQuoteProvider) || currentServiceOrder.providers[0];

    return (
        <div className={styles.mainRow}>
            <div className={styles.parametersColumn} >
                <div className={styles.parameterRow}>
                <LabelElement width={'200px'} label={'Melhor Cotação'} />
                <LabelElement width={'96px'} label={'Preço'} />
                <LabelElement width={'60px'} label={'Nota'}  />
                <Tooltip 
                    title={
                        bestQuoteProvider.providerBestQuote.brand.tier===2 || bestQuoteProvider.providerBestQuote.shortestAvailability.availability==='needToOrder' ?
                        bestQuoteProvider.providerBestQuote.brand.tier===2 && bestQuoteProvider.providerBestQuote.shortestAvailability.availability==='needToOrder' ?
                                "Considera uma penalidade de 7 dias por marcas de segunda linha e 3 dias para encomendadas" :
                                bestQuoteProvider.providerBestQuote.brand.tier===2 ?
                                    "Considera uma penalidade de 7 dias caso haja produtos de segunda linha na cotação" :
                                    "Considera uma penalidade de 3 dias caso haja produtos encomendados na cotação"
                        :''
                    }
                    placement={'bottom'} 
                    >
                    <div>
                        <LabelElement width={'60px'} 
                            label={bestQuoteProvider.providerBestQuote.brand.tier===2 || bestQuoteProvider.providerBestQuote.shortestAvailability.availability==='needToOrder' ? 'Dias*': 'Dias'} 
                        />
                    </div>
                </Tooltip>
                <LabelElement width={'60px'} label={'Linha'} />
            </div>
                <BestQuote type={'bestQuote'} quote={bestQuoteProvider.providerBestQuote} providerName={bestQuoteProvider.providerName}/>
                <BestQuote type={'currentTarget'} quote={currentServiceOrder.currentTarget} />
            </div>
        </div>
    )
}