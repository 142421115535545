import React, {useContext, useEffect, useState } from 'react'
import styles from 'GlobalComponents/CommonStyles.module.css';
import BestQuote from './BestQuote.js';;
import LabelElement from 'GlobalComponents/LabelElement/LabelElement.js';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import Tooltip from '@mui/material/Tooltip';

export default function QuotesSummary({}){
    const {currentServiceOrder} = useContext(QuoteMasterContext);
    const [bestQuoteProvider, setBestQuoteProvider] = useState({});

    useEffect(() => {
        let index = currentServiceOrder.providers.findIndex(provider=>provider.affiliateId===currentServiceOrder.bestQuoteProvider);
        let list;
        if(index===-1){
            list = currentServiceOrder.providersNotConsidered;
            index = list.findIndex(provider=>provider.affiliateId===currentServiceOrder.bestQuoteProvider);
        } else{
            list = currentServiceOrder.providers;
        }

        setBestQuoteProvider(list[index] || currentServiceOrder.providers[0]);
    }, [currentServiceOrder.providers, currentServiceOrder.bestQuoteProvider, currentServiceOrder.providersNotConsidered]);


    function returnTitle(){
        if(!bestQuoteProvider || !bestQuoteProvider.providerBestQuote){
            return ''
        }
        if(bestQuoteProvider.providerBestQuote.brand.tier===2 || bestQuoteProvider.providerBestQuote.shortestAvailability.availability==='needToOrder'){
            if(bestQuoteProvider.providerBestQuote.brand.tier===2 && bestQuoteProvider.providerBestQuote.shortestAvailability.availability==='needToOrder'){
                return "Considera uma penalidade de 7 dias por marcas de segunda linha e 3 dias para encomendadas"
            } else if(bestQuoteProvider.providerBestQuote.brand.tier===2){
                return "Considera uma penalidade de 7 dias caso haja produtos de segunda linha na cotação"
            } else {
                return "Considera uma penalidade de 3 dias caso haja produtos encomendados na cotação"
            }
        } else {
            return ''
        }
    }

    function returnLabel(){
        if(!bestQuoteProvider || !bestQuoteProvider.providerBestQuote){
            return 'Dias'
        }
        if(bestQuoteProvider.providerBestQuote.brand.tier===2 || bestQuoteProvider.providerBestQuote.shortestAvailability.availability==='needToOrder'){
            return 'Dias*'
        } else {
            return 'Dias'
        }
    }

    const [bestQuote, setBestQuote] = useState({});
    useEffect(() => {
        if(bestQuoteProvider && bestQuoteProvider.providerBestQuote){
            setBestQuote(bestQuoteProvider.providerBestQuote)
        }
    }, [bestQuoteProvider]);


    
    return (
        <div className={styles.mainRow}>
            <div className={styles.parametersColumn} >
                <div className={styles.parameterRow}>
                <LabelElement width={'200px'} label={'Melhor Cotação'} />
                <LabelElement width={'96px'} label={'Preço'} />
                <LabelElement width={'60px'} label={'Nota'}  />
                <Tooltip 
                    title={returnTitle()}
                    placement={'bottom'} 
                    >
                    <div>
                        <LabelElement width={'60px'} 
                            label={returnLabel()} 
                        />
                    </div>
                </Tooltip>
                <LabelElement width={'60px'} label={'Linha'} />
            </div>
                <BestQuote type={'bestQuote'} quote={bestQuote} providerName={bestQuoteProvider.providerName || ''}/>
                <BestQuote type={'currentTarget'} quote={currentServiceOrder.currentTarget} />
            </div>
        </div>
    )
}

