// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d1QQR08fg7nTApAucQPh{
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
}


.wyXF3HQa005BPoHlNM_j{
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  align-items: stretch;
}

.YnoVkHsqrYwos2rsayYh{
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.xkJfkglnGzDLkrFSUX95{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  position: relative;
  flex-grow: 1;
  justify-content: start;
}
`, "",{"version":3,"sources":["webpack://./clientRoot/Static/QuoteMaster.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,sBAAsB;AACxB;;;AAGA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,WAAW;EACX,sBAAsB;EACtB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;AACxB","sourcesContent":[".mainstructure{\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n  align-items: center;\n  justify-content: start;\n  box-sizing: border-box;\n}\n\n\n.workingArea{\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  overflow: hidden;\n  width: 100%;\n  box-sizing: border-box;\n  align-items: stretch;\n}\n\n.serviceOrderHeaderTab{\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  box-sizing: border-box;\n  overflow: auto;\n}\n\n.serviceOrderManagement{\n  display: flex;\n  flex-direction: column;\n  box-sizing: border-box;\n  overflow: hidden;\n  align-items: center;\n  position: relative;\n  flex-grow: 1;\n  justify-content: start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainstructure": `d1QQR08fg7nTApAucQPh`,
	"workingArea": `wyXF3HQa005BPoHlNM_j`,
	"serviceOrderHeaderTab": `YnoVkHsqrYwos2rsayYh`,
	"serviceOrderManagement": `xkJfkglnGzDLkrFSUX95`
};
export default ___CSS_LOADER_EXPORT___;
