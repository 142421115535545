
import React, {useContext, useState, useRef, useEffect}  from 'react'

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';   

import SelectionBox from 'GlobalComponents/SelectionBox/SelectionBox.js';

export default function ItemGroupSelect({index}) {
    const {setCurrentServiceOrder, currentServiceOrder} = useContext(QuoteMasterContext);
    const [itemGroup, setItemGroup] = useState(currentServiceOrder.items[index].itemGroup);
    const firstLoadCompleted = useRef(false);

    useEffect(() => {
        if(firstLoadCompleted.current && currentServiceOrder.currentStage === 'notInitiated'){ // if the page is just loading for the first time
            const newItems = [...currentServiceOrder.items];
            const newItem = newItems[index];
            newItem.itemGroup = itemGroup;
            setCurrentServiceOrder({...currentServiceOrder, items: newItems});
        } else {
            firstLoadCompleted.current = true;
        }
    }, [itemGroup]);

    useEffect(() => {
        setItemGroup(currentServiceOrder.items[index].itemGroup);
    }, [currentServiceOrder.items[index].itemGroup, index]);

    const selectionItems = [
        {value: 'glasses', label: 'Vidros'},
        {value: 'accessories', label: 'Acessórios'},
        {value: 'services', label:'Serviços'},
    ]

    return (
        <SelectionBox 
            selectionItems={selectionItems} 
            mainFunction={setItemGroup} 
            mainValue={itemGroup} 
            instanceName={'Grupo'}
            readOnly={currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated'} 
        />
    );
}


