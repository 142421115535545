import React, { useEffect, useState, useRef, useContext } from 'react';

import {convertISODateToReadableString} from 'Contexts/Utils/formatTime.js';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import styles from './ServiceOrderStatusDisplay.module.css';
import TimeToGo from '../TimeToGo/TimeToGo.js';


export default function ServiceOrderStatusDisplay({}){

    const {currentServiceOrder} = useContext(QuoteMasterContext);
    const [quoteStartTime, setQuoteStartTime] = useState(currentServiceOrder.stageStartTime.find(stage=>stage.stage===1).time); 
    const [currentStage, setCurrentStage] = useState(currentServiceOrder.currentStage);
    const [stageStartTime, setStageStartTime] = useState(currentServiceOrder.stageStartTime.find(stage=>stage.stage===currentServiceOrder.currentStage).time);
    const [serviceOrderCloseTime, setServiceOrderCloseTime] = useState(currentServiceOrder.serviceOrderCloseTime);
    const [stageLength, setStageLength] = useState(currentServiceOrder.stageTime*currentServiceOrder.timeMultiplier);
    const [quoteLength, setQuoteLength] = useState(currentServiceOrder.totalTime*currentServiceOrder.timeMultiplier);
    
    useEffect(() => {
        setQuoteStartTime(currentServiceOrder.stageStartTime.find(stage=>stage.stage===1).time); 
        setCurrentStage(currentServiceOrder.currentStage);
        setStageStartTime(currentServiceOrder.stageStartTime.find(stage=>stage.stage===currentServiceOrder.currentStage).time);
        setServiceOrderCloseTime(currentServiceOrder.serviceOrderCloseTime);
        setStageLength(currentServiceOrder.stageTime*currentServiceOrder.timeMultiplier);
        setQuoteLength(currentServiceOrder.totalTime*currentServiceOrder.timeMultiplier);
    }, [currentServiceOrder]);

    return(
        <div className={styles.serviceOrderStatusDisplay}>
            <StageStartTime stageStartTime={stageStartTime} currentStage={currentStage} serviceOrderCloseTime={serviceOrderCloseTime}/>
            {(currentStage===1 || currentStage===2 || currentStage===3) && <TimeToGo type={'stage'} length={stageLength} startTime={stageStartTime}/> }
            {currentStage!=='closed' && currentStage!=='fallbackToHuman' && currentStage!=='notInitiated' &&  <TimeToGo type={'humanFallBack'} length={quoteLength} startTime={quoteStartTime} /> }
            {(currentStage==='closed' || currentStage==='fallbackToHuman') && <CloseReason />} 
        </div>
    )
}

function StageStartTime({stageStartTime, currentStage, serviceOrderCloseTime}){
    const [startTimeTitle, setStartTimeTitle] = useState('');
    const readableTime = useRef('');
    let time = '';
    useEffect(() => {

        switch(currentStage){
            case 'notInitiated':
                setStartTimeTitle('Cotação não Iniciada');
            case 'closed':
                setStartTimeTitle('Cotação Encerrada em ');
                time = serviceOrderCloseTime
                break;
            case 'fallbackToHuman':
                setStartTimeTitle('Cotação Enviada para Atendimento Humano em ');
                time = serviceOrderCloseTime
                break;
            default:
                setStartTimeTitle(`Estágio ${currentStage} iniciado às `);
                time = stageStartTime;
                break;
        }
        if(time===''){
            return;
        }
        readableTime.current = convertISODateToReadableString(time, false);
    }, [stageStartTime, currentStage, serviceOrderCloseTime]);
    
    return(
        <>
            {currentStage !== 'notInitiated' ?
                <div>
                    {startTimeTitle} {readableTime.current}
                </div> : 'Cotação não Iniciada'
            }
        </>
    )
}



function CloseReason(){
    const {currentServiceOrder} = useContext(QuoteMasterContext);
    const [closeReason, setCloseReason] = useState('');
    useEffect(() => {
        switch(currentServiceOrder.closeReason){
            // here are the reasons for human fall back
            case('totalTimeOut'):
                setCloseReason('Tempo Total Esgotado');
                break;
            case('belowRealMinimumRate'):
                setCloseReason('Cotações abaixo da alçada mínima do QuoteMaster');
                break;
                // from here on, the reasons are for successful closes
            case('aboveMaximumRate'):
                setCloseReason('Cotação alcançou nota máxima');
                break;
            case('hitTheRequestedTarget'):
                setCloseReason('Negociação atingiu a meta solicitada');
                break;
            case('aboveMinimumRate'):
                setCloseReason('Fechado no Estágio 2. Nota acima do mínimo');
                break;
            case('aboveRealMinimumRate'):
                setCloseReason('Fechado no Estágio 3. Nota dentro da alçada do QuoteMaster');
                break;
            case('noProviderWithValidWhatsapp'):
                setCloseReason('Nenhum afiliado com whatsapp válido');
                break;
            default:
                setCloseReason('Razão não especificada');
                break;
        }
    }, [currentServiceOrder.closeReason])

    return(
        <div>Razão: {closeReason}</div>
    )
}