
import React, {useContext, useState, useRef,useEffect } from 'react'

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';   
import SelectionBox from 'GlobalComponents/SelectionBox/SelectionBox.js';

const selectionItems = [
    {value: '+55', label: '+55 - Brasil'},
]

export default function CountryCodeSelect({readOnly, providerIndex, view, instance}) {
    const {setCurrentServiceOrder, currentServiceOrder} = useContext(QuoteMasterContext);
    const [countryCode, setCountryCode] = useState(currentServiceOrder.providers[providerIndex].phoneNumber.countryCode);
    const firstLoadCompleted = useRef(false);
    
    useEffect(() => {
        if(firstLoadCompleted.current && currentServiceOrder.currentStage === 'notInitiated'){ // if the page is just loading for the first time
            const newCurrentServiceOrder = {...currentServiceOrder};
            const newProvider = newCurrentServiceOrder.providers[providerIndex];
            newProvider.phoneNumber.countryCode = countryCode;
            setCurrentServiceOrder(newCurrentServiceOrder);
        } else {
            firstLoadCompleted.current = true;
        }
    }, [countryCode]);

    useEffect(() => {
        setCountryCode(currentServiceOrder.providers[providerIndex].phoneNumber.countryCode);
    }, [currentServiceOrder.providers[providerIndex].phoneNumber.countryCode]);



    return (
        <SelectionBox 
            selectionItems={selectionItems} 
            mainFunction={setCountryCode} 
            mainValue={countryCode} 
            instanceName={'País'} 
            readOnly={readOnly}
            noWrap={false}
            width={'100px'}
            height={'18px'}
        />
    );
}


