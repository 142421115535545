// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sDDgVNG0VGUAWjTxD8Lw {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 16px;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: var(--letter-dark);
    border-width: 2px;
    outline: none; /* Removes the default focus outline */
    background-color: var(--background-light);
    width: auto;
}

.betKPWA7pacLjr98rHgY{
    font-size: 16px;
    font-weight: 100;
    color: var(--border-dark);
    line-height: 16px;
    white-space: pre-line; /* This will respect the \\n characters */
}

.dlVRyM4bNWerZAFkUMVy{
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    flex-direction: row; 
    margin-top: 16px;
}`, "",{"version":3,"sources":["webpack://./clientRoot/Static/GlobalComponents/ConfirmationModal/ConfirmationModal.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,aAAa;IACb,gCAAgC;IAChC,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,gCAAgC;IAChC,iBAAiB;IACjB,aAAa,EAAE,sCAAsC;IACrD,yCAAyC;IACzC,WAAW;AACf;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,iBAAiB;IACjB,qBAAqB,EAAE,wCAAwC;AACnE;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".confirmSODeletionPopup {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    padding: 16px;\n    transform: translate(-50%, -50%);\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    border-style: solid;\n    border-color: var(--letter-dark);\n    border-width: 2px;\n    outline: none; /* Removes the default focus outline */\n    background-color: var(--background-light);\n    width: auto;\n}\n\n.confirmationModalTitle{\n    font-size: 16px;\n    font-weight: 100;\n    color: var(--border-dark);\n    line-height: 16px;\n    white-space: pre-line; /* This will respect the \\n characters */\n}\n\n.confirmationButtons{\n    display: flex;\n    justify-content: space-between; \n    align-items: center; \n    flex-direction: row; \n    margin-top: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmSODeletionPopup": `sDDgVNG0VGUAWjTxD8Lw`,
	"confirmationModalTitle": `betKPWA7pacLjr98rHgY`,
	"confirmationButtons": `dlVRyM4bNWerZAFkUMVy`
};
export default ___CSS_LOADER_EXPORT___;
