
import React, { useCallback, useContext } from 'react'

import styles from 'GlobalComponents/CommonStyles.module.css';
import LabelElement from 'GlobalComponents/LabelElement/LabelElement.js';
import { QuoteMasterContext } from 'Contexts/QuoteMasterContext.js';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput.js';
import NumberInput from 'GlobalComponents/NumberInput/NumberInput.js';

export default function CostTargets({}){    
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);
    const priceWidth = '100px';
    const rateWidth = '60px';

    const changeTheRateOf = useCallback((field) => (event) => {
        const value = isNaN(event.target.value) ? 0 : event.target.value;
        setCurrentServiceOrder((prevOrder) => ({
            ...prevOrder,
            [field]: {rate: value, price: prevOrder[field].price},
            currentTarget: field==='minimumDesirableRate' ? {...prevOrder.currentTarget, rate: value} : prevOrder.currentTarget
        }));
    }, [currentServiceOrder]);

    const changeThePriceOf = useCallback((field) => (event) => {
        const value = event.target.value; // Extract the value from the event object
        setCurrentServiceOrder((prevOrder) => ({
            ...prevOrder,
            [field]: {rate: prevOrder[field].rate, price: value},
            currentTarget: field==='minimumDesirableRate' ? {...prevOrder.currentTarget, price: value} : prevOrder.currentTarget
        }));
    }, [currentServiceOrder]);

    const changeMaxDiscountRate = useCallback((event) => {
        const numberValue = parseFloat(event.target.value);
        console.log('changeMaxDiscountRate - numberValue:', numberValue);
        const value = isNaN(numberValue) ? 0 : parseFloat((numberValue/100).toFixed(2));
        setCurrentServiceOrder((prevOrder) => ({
            ...prevOrder,
            maximumRateDiscount: value
        }));
    }, [currentServiceOrder]);

    

    return(
        <div className={styles.parametersColumn} >
            <div className={styles.parameterRow}>
                <LabelElement width={'170px'} label={'Targets de Custo'} />
                <LabelElement width={rateWidth} label={'Nota'} />
                <LabelElement width={priceWidth} label={'Preço'} />
            </div>
            <div className={styles.parameterRow}>
                <LabelElement width={'170px'} label={'Alçada QuoteMaster'} />
                    <DetailInput 
                        width={rateWidth}
                        inputElement={
                            <NumberInput 
                                inputValue={currentServiceOrder.quoteMasterMinimumRate.rate}
                                onChange={changeTheRateOf('quoteMasterMinimumRate')}
                                readOnly={currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated'}
                                spin={true}
                                minValue={1}
                                maxValue={currentServiceOrder.minimumDesirableRate.rate}
                                step={0.1}
                                preventingDefault={true}
                            />
                        }
                    />
                    <DetailInput 
                        width={priceWidth}
                        inputElement={
                            <NumberInput 
                                inputValue={currentServiceOrder.quoteMasterMinimumRate.price}
                                onChange={changeThePriceOf('quoteMasterMinimumRate')}
                                readOnly={currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated'}
                                spin={false}
                                minValue={currentServiceOrder.minimumDesirableRate.price}
                            />
                        }
                    />
            </div>
            <div className={styles.parameterRow}>
                <LabelElement width={'170px'} label={'Mínimo Desejável'} />
                <DetailInput 
                        width={rateWidth}
                        inputElement={
                        <NumberInput 
                            inputValue={currentServiceOrder.minimumDesirableRate.rate}
                            onChange={changeTheRateOf('minimumDesirableRate')}
                            readOnly={currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated'}
                            spin={true}
                            minValue={currentServiceOrder.quoteMasterMinimumRate.rate}
                            maxValue={currentServiceOrder.maximumRate.rate}
                            step={0.1}
                            preventingDefault={true}
                        />
                    }
                />
                <DetailInput 
                        width={priceWidth}
                        inputElement={
                            <NumberInput 
                                inputValue={currentServiceOrder.minimumDesirableRate.price}
                                onChange={changeThePriceOf('minimumDesirableRate')}
                                readOnly={currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated'}
                                spin={false}
                                minValue={currentServiceOrder.maximumRate.price}
                                maxValue={currentServiceOrder.quoteMasterMinimumRate.price}
                            />
                        }
                    />
            </div>
            <div className={styles.parameterRow}>
                <LabelElement width={'170px'} label={'Nota Máxima'} />
                <DetailInput 
                    width={rateWidth}
                    inputElement={
                        <NumberInput 
                            inputValue={currentServiceOrder.maximumRate.rate}
                            onChange={changeTheRateOf('maximumRate')}
                            readOnly={currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated'}
                            spin={true}
                            minValue={currentServiceOrder.minimumDesirableRate.rate}
                            maxValue={10}
                            step={0.1}
                            preventingDefault={true}
                        />
                    }
                />
                <DetailInput 
                    width={priceWidth}
                    inputElement={
                        <NumberInput 
                            inputValue={currentServiceOrder.maximumRate.price}
                            onChange={changeThePriceOf('maximumRate')}
                            readOnly={currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated'}
                            spin={false}
                            maxValue={currentServiceOrder.minimumDesirableRate.price}
                        />
                    }
                />
            </div>
            <div className={styles.parameterRow}>
                <LabelElement width={'230px'} label={'Desconto Nota Máxima (%)'} />
                <DetailInput 
                    width={priceWidth}
                    inputElement={
                        <NumberInput 
                            inputValue={currentServiceOrder.maximumRateDiscount*100}
                            onChange={changeMaxDiscountRate}
                            readOnly={currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated'}
                            spin={true}
                            minValue={1}
                            maxValue={100}
                            step={1}
                            preventingDefault={true}
                        />
                    }
                />
            </div>
        </div>
    )
}