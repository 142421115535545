// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AQSNDYI3hIaDoLmUtola{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.BPVD4FcmSq290nkbEDcm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.U9doGm95sHMWDyr5PtYj{
    font-weight: bold;
    color: var(--letter-dark);
}


.MYafJfs4_KiChy3NLZ0v{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid var(--border-dark);
    width: 300px;
    height: 'auto';
    padding: 16px;
    margin-top: 24px;
}

.Y4VPtkOSU782T56CQ10P{
    color: var(--letter-dark);
    outline: none;
    border: 2px solid var(--border-dark);
    resize: none;
    box-sizing: border-box;
    display: block;
    background-color: var(--background-light);
    width: 232px;
    line-height: 16px;
    margin: 8px 0px;
    font-size: 16px;
    padding: 0px 8px;

}

.PAAu8uSmMoELcJfmzrQy{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 8px 0px;
}
`, "",{"version":3,"sources":["webpack://./clientRoot/Static/EntryMessagePage/EntryMessage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;AAC7B;;;AAGA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,oCAAoC;IACpC,YAAY;IACZ,cAAc;IACd,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,oCAAoC;IACpC,YAAY;IACZ,sBAAsB;IACtB,cAAc;IACd,yCAAyC;IACzC,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,gBAAgB;;AAEpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,eAAe;AACnB","sourcesContent":[".entryPage{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    width: 100vw;\n}\n\n.entryMessage{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.title{\n    font-weight: bold;\n    color: var(--letter-dark);\n}\n\n\n.loginArea{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    border: 2px solid var(--border-dark);\n    width: 300px;\n    height: 'auto';\n    padding: 16px;\n    margin-top: 24px;\n}\n\n.inputArea{\n    color: var(--letter-dark);\n    outline: none;\n    border: 2px solid var(--border-dark);\n    resize: none;\n    box-sizing: border-box;\n    display: block;\n    background-color: var(--background-light);\n    width: 232px;\n    line-height: 16px;\n    margin: 8px 0px;\n    font-size: 16px;\n    padding: 0px 8px;\n\n}\n\n.buttonsLoginSignupArea{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    margin: 8px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"entryPage": `AQSNDYI3hIaDoLmUtola`,
	"entryMessage": `BPVD4FcmSq290nkbEDcm`,
	"title": `U9doGm95sHMWDyr5PtYj`,
	"loginArea": `MYafJfs4_KiChy3NLZ0v`,
	"inputArea": `Y4VPtkOSU782T56CQ10P`,
	"buttonsLoginSignupArea": `PAAu8uSmMoELcJfmzrQy`
};
export default ___CSS_LOADER_EXPORT___;
