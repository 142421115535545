import React, { useContext, useState, useRef, useEffect  } from 'react';

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import styles from './ChatWindows.module.css';
import ChatConversation from './ChatConversation/ChatConversation.js';
import ChatInput from './ChatInput/ChatInput.js';
import ChatHeader from './ChatHeader/ChatHeader.js';

import ProvidersManagement from './ProvidersManagement.js';
import ServiceOrderStatusDisplay from './ServiceOrderStatusDisplay/ServiceOrderStatusDisplay.js';

export default function ConversationsArea({isSOHeaderOpen, setIsSOHeaderOpen, conversationsWidth, conversationsHeight}) {
    const {currentServiceOrder} = useContext(QuoteMasterContext);
    const [view, setView] = useState('singleChatWindow');
    const [ providersList, setProvidersList ] = useState([...currentServiceOrder.providers, ...currentServiceOrder.providersNotConsidered]);
    const [selectedProvider, setSelectedProvider] = useState(providersList[0]);
    const selectedProviderIndex = useRef(0);



    useEffect(() => {
        const newProvidersList = [];
        currentServiceOrder.providers.forEach(provider => {
            newProvidersList.push(provider);
        });
        currentServiceOrder.providersNotConsidered.forEach(provider => {
            newProvidersList.push(provider);
        });
        setProvidersList(newProvidersList);     
    }, [currentServiceOrder.providers, currentServiceOrder.providersNotConsidered]);

    const ServiceOrderStatusDisplayHeight = 60;
    const chatManagementHeight = conversationsHeight - ServiceOrderStatusDisplayHeight;


    useEffect(() => {

        if(providersList.length-1<selectedProviderIndex.current){ // the current service order has less providers than the selected index
            selectedProviderIndex.current = 0;
        }
        setSelectedProvider(providersList[selectedProviderIndex.current]);
    }, [providersList]);


    return(
        <div className={styles.conversations} style={{width:conversationsWidth}} >
            <ServiceOrderStatusDisplay />
            <div 
                className={styles.chatManagement} 
                style={{flexDirection: view==='multiChatWindow'? 'column': 'row', height: chatManagementHeight }}
            >
                <ProvidersManagement view={view} setView={setView} selectedProvider={selectedProvider} setSelectedProvider={setSelectedProvider} setIsSOHeaderOpen={setIsSOHeaderOpen} isSOHeaderOpen={isSOHeaderOpen} selectedProviderIndex={selectedProviderIndex} providersList={providersList}/>
                <ChatWindows view={view} selectedProvider={selectedProvider} selectedProviderIndex={selectedProviderIndex} providersList={providersList}/>
            </div>

        </div>
    )
}

function ChatWindows({view, selectedProvider, selectedProviderIndex, providersList}){

    return(
        <>
            {view==='multiChatWindow' ? 
                <ul className={styles.conversationsContainer} >
                    {providersList.map((provider, index) => (
                        <SingleChatWindow view={view} provider={provider} key={index} selectedProviderIndex={selectedProviderIndex} providersList={providersList}/>
                    ))}
                </ul>
                : <SingleChatWindow view={view} provider={selectedProvider} selectedProviderIndex={selectedProviderIndex} providersList={providersList}/>
            }
        </>
    )   
}

function SingleChatWindow({view, provider, selectedProviderIndex, providersList}){
    const {currentServiceOrder} = useContext(QuoteMasterContext);
    const headerHeight = view==='multiChatWindow'? 128 : 104;
    const inputContainerMinHeight = 54;
    const inputContainerMaxHeight = 192;
    const chatWidth = 400; // this is a default value for the multiChatWindow view // the singleChatWindow view will be automatically adjusted
    const marginRight = view==='multiChatWindow'? '16px': '0px';
    const boxShadow = view==='multiChatWindow'? '0px 0px 8px 2px rgba(0,0,0,0.2)': 'none';
    const borderRadius = view==='multiChatWindow'? '16px': '0px';

    return(
        <li className={styles.chatBox} style={{width: view=== 'multiChatWindow'? `${chatWidth}px`: '100%', marginRight: marginRight, borderRadius:borderRadius, boxShadow:boxShadow}}>
            <ChatHeader provider={provider} headerHeight={headerHeight} view={view} instance={'chat'} setSelectedProvider={()=>{}} selectedProvider={provider} providersList={providersList}/>
            <ChatConversation provider={provider} chatWidth={chatWidth} view={view}/>
            {currentServiceOrder.currentStage!=='notInitiated' && currentServiceOrder.currentStage!=='closed' && currentServiceOrder.currentStage !== 'fallbackToHuman' && 
                <ChatInput 
                    provider={provider}
                    selectedProviderIndex={selectedProviderIndex}
                    inputContainerMinHeight={inputContainerMinHeight}
                    inputContainerMaxHeight={inputContainerMaxHeight}
                />
            }
        </li>
    )
}