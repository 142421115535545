import React, {useRef, useEffect, useState } from 'react';

import styles from './ChatConversation.module.css';

export default function ChatConversation({chatWidth, provider, view}){
    const conversationContainerRef = useRef(null);    
    const userMessageRightMargin =  view==='multiChatWindow'? 24 : 120;
    const initialMessageContentMaxWidth = chatWidth - 2*userMessageRightMargin;
    const [messageContentMaxWidth, setMessageContentMaxWidth] = useState(initialMessageContentMaxWidth);
    const [conversation, setConversation] = useState(provider.conversation);

    useEffect(() => {
        if (conversationContainerRef.current) {
            requestAnimationFrame(() => {
                if(conversationContainerRef.current){
                    conversationContainerRef.current.scrollTop = conversationContainerRef.current.scrollHeight; // Example large scroll
                }
                requestAnimationFrame(() => {
                    if(conversationContainerRef.current){
                        conversationContainerRef.current.scrollTop += 10; // Ensuring this small adjustment occurs after the previous scroll    
                    }
                });
            });
        }


        const updateWidth = () => {
            if(!conversationContainerRef.current){
                return;
            }
            const width = conversationContainerRef.current.offsetWidth; 
            const scrollBarWidth = conversationContainerRef.current.scrollHeight > conversationContainerRef.current.clientHeight ? 6 : 0;
            const newWidth = width - 2 * userMessageRightMargin - scrollBarWidth;
            setMessageContentMaxWidth(newWidth);
        };
        updateWidth();
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                updateWidth();
            }
        });

        if (conversationContainerRef.current) {
            resizeObserver.observe(conversationContainerRef.current);
        }

        return () => {
            if (conversationContainerRef.current) {
                resizeObserver.unobserve(conversationContainerRef.current);
            }
        };
    }, [conversation, conversationContainerRef.current, view ]); 

    useEffect(() => {
        let initialDate = '';
        const newConversation = [];
        for (let i = 0; i < provider.conversation.length; i++) {
            const message = provider.conversation[i];
            if(!message){
                return;
            }
            const messageDate = new Date(message.createdAt).toLocaleDateString([], { year: 'numeric', month: '2-digit', day: '2-digit' });
            if(initialDate!==messageDate){
                initialDate = messageDate;
                const newDate = {role: 'date', content: initialDate, createdAt: ''};
                newConversation.push(newDate);
            }
            newConversation.push(message);
        }
        setConversation(newConversation);
    }, [provider.conversation.length, provider.conversation]);

    

    return(
        <ul className={styles.conversationContainer} ref={conversationContainerRef} >
            {conversation.map((message,index) => (
                <li key={`${message.createdAt}${index}`}  style={{padding:'0px',  margin:'0px', display:'flex', flexDirection:'column', justifyContent:'flex-start'}}>
                    {message.role==='Provider' &&
                        <div className={styles.message} >
                            <div className={styles.messageContentProvider} style={{maxWidth: `${messageContentMaxWidth}px`}}>
                                <div>{message.audio ? <audio src={message.content} controls /> : message.content} </div>
                                <div className={styles.messageTime}>{new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                            </div>
                        </div> 
                    }
                    {message.role==='quoteMaster' && 
                        <div className={styles.messageContentQuoteMaster} style={{maxWidth: `${messageContentMaxWidth}px`}} >
                            <div>{message.content}</div>
                            <div className={styles.messageTime}>{new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                        </div>                            
                    }
                    {message.role==='date' && 
                        <div className={styles.dateLine}  >
                            <div className={styles.date}>{message.content}</div>
                        </div>                            
                    }
                </li>
                
            ))}
        </ul>
    )

}