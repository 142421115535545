
import React, {useContext} from 'react'

import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import {} from 'Contexts/ServerCalls/serverCalls.js';

export default function StopOSButton({}){
    const {currentServiceOrder, setCurrentServiceOrder, setLoading} = useContext(QuoteMasterContext);

    async function stopOS(serviceOrder){
        setLoading(true);
        const response = await stopOSFromFE(serviceOrder.serviceOrderId)
        if(response!=='fail'){
            setCurrentServiceOrder(response);
        } 
        setLoading(false);
    }

    return(
        <GlobalButton 
            onClick={()=>stopOS(currentServiceOrder)} 
            label={'Retornar OS para WA'} 
            type={'primary'} 
            width={'120px'}
        />
    )
}