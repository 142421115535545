import React, { useEffect, useContext,  useRef, useState  } from 'react';
import styles from './ServiceOrdersTab.module.css';
import commonStyles from 'GlobalComponents/CommonStyles.module.css';
import RunTypeSelect  from './RunTypeSelect.js';
import TestScenarios from './TestScenarios.js';
import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js';
import PageHeader from 'Contexts/PageHeader/PageHeader.js';

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js'; 

import NewSOSameParametersButton from './NewSOSameParametersButton.js';
import Icon from 'GlobalComponents/Icon/Icon.js';
import SearchBar from 'GlobalComponents/SearchBar/SearchBar.js';
import LoadServiceOrderButton from './LoadServiceOrderButton.js';

export default function ServiceOrdersTab({setIsAdminPanelOpen, handleGetServiceOrders, serviceOrdersTabWidth }) {
    const {user, serviceOrders, currentServiceOrder, setCurrentServiceOrder, setSOToDelete, settriggerServiceOrderTabCleanup, triggerServiceOrderTabCleanup, freshServiceOrder} = useContext(QuoteMasterContext);

    const [ordersToDisplay, setOrdersToDisplay] = useState(serviceOrders);
    const [view, setView] = useState('all');
    const testScenarioRef = useRef(null);
    const runTypeRef = useRef(null);
    const scrollBarWidth = useRef(0);
    const serviceOrdersContainerRef = useRef(null);
    const [serviceIrdersContainerRightPadding, setServiceIrdersContainerRightPadding] = useState(16);
    const preFilteredSOs = useRef(serviceOrders);
    
    useEffect(() => {
        if(triggerServiceOrderTabCleanup){
            settriggerServiceOrderTabCleanup(false);
            newServiceOrder();
        }
    }, [triggerServiceOrderTabCleanup]);

    const newServiceOrder = () => {
        setCurrentServiceOrder({...freshServiceOrder, user: user});
        if (testScenarioRef.current) {
            testScenarioRef.current.resetScenario();
        }
        if (runTypeRef.current) {
            runTypeRef.current.resetRunType();
        }
    }


    useEffect(() => {
        switch(view){
            case 'all':
                preFilteredSOs.current = serviceOrders;
                break;
            case 'regular':
                preFilteredSOs.current = serviceOrders.filter(order => order.runType === 'regular');
                break;
            case 'test':
                preFilteredSOs.current = serviceOrders.filter(order => order.runType !== 'regular');
                break;
            default:
                preFilteredSOs.current = serviceOrders;
        }
        preFilteredSOs.current = preFilteredSOs.current.slice().sort((a, b) => new Date(b.timeLastUpdate) - new Date(a.timeLastUpdate));
        setOrdersToDisplay(preFilteredSOs.current);
    }, [view, serviceOrders]);
            

    useEffect(() => {
        if(serviceOrdersContainerRef.current.scrollHeight > serviceOrdersContainerRef.current.clientHeight){ // if there is a scrollbar
            scrollBarWidth.current = 4;
        } else {
            scrollBarWidth.current = 0;
        }
        setServiceIrdersContainerRightPadding(16-scrollBarWidth.current);
    }, [serviceOrders, serviceOrdersContainerRef.current]);


    return(
        <div className={styles.serviceOrdersTab} style={{minWidth: `${serviceOrdersTabWidth}px`, maxWidth: `${serviceOrdersTabWidth}px`}}>
            <div style={{padding: '0px 16px'}} >
                <PageHeader setIsAdminPanelOpen={setIsAdminPanelOpen}/>
                <div className={styles.selectors}>
                    <RunTypeSelect FowardingRef={runTypeRef}/>
                </div>
                {currentServiceOrder.runType === 'standardTest' && 
                    <div className={styles.selectors}>
                        <TestScenarios FowardingRef={testScenarioRef} />
                    </div>
                }
                <div className={styles.workspace}>
                    <GlobalButton 
                        onClick={()=>newServiceOrder()}
                        label={<>Nova Ordem de Serviço <br/>em Branco</>}
                        type={'primary'}
                        width={'100%'}
                    />
                </div>
                <div className={styles.workspace}>
                    <NewSOSameParametersButton />
                </div>
                <span className={styles.workspace} style={{borderTop: '1px solid var(--border-dark)', justifyContent: 'space-between'}}>
                    <div className={styles.serviceOrdersLabel}>Ordens de Serviço</div>
                    {Icon({originalIcon:'restorePageOutlined', size:32, onClick:handleGetServiceOrders, tooltipMessage:'Atualizar lista de Ordem de Serviços', tooltipPlacement:"bottom"})}    
                </span>
                <div className={commonStyles.detailsAndCostButtons} >
                    <GlobalButton  onClick={() => setView('all')} label='Todas' width='68px' type={view === 'all' ? 'selected' : 'secondary'} />
                    <GlobalButton  onClick={() => setView('regular')} label='Produção' width='68px' type={view === 'regular' ? 'selected' : 'secondary'} />
                    <GlobalButton  onClick={() => setView('test')} label='Testes' width='68px' type={view === 'test' ? 'selected' : 'secondary'} />
                </div>
                <div className={styles.workspace} >
                    <SearchBar 
                        groupToSearch={preFilteredSOs.current} 
                        setGroup={setOrdersToDisplay} 
                        toolTipTitle={'Ordem de Serviço, Afiliado e Usuário'}
                        keys={['providers.providerName', 'serviceOrderId', 'user.userEmail', 'user.firstName', 'user.lastName']}
                    />
                </div>
            </div>
            <ul className={styles.serviceOrdersArea} ref={serviceOrdersContainerRef} style={{paddingRight:serviceIrdersContainerRightPadding}}>
                {ordersToDisplay.map((serviceOrder, index) =>(
                    <div className={styles.workspace} key={index} >
                        <LoadServiceOrderButton serviceOrder={serviceOrder}/>
                        <Icon originalIcon={'deleteUser'} size={28} onClick={()=>setSOToDelete(serviceOrder.serviceOrderId)} />
                    </div>
                ))}
            </ul>	
    </div>
    )
}
