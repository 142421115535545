// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D6HBYHg6w8s3WPnPwTLX{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 0px 0px;
    margin: 0px;
    height: 32px;
    box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./clientRoot/Static/GlobalComponents/TelephoneInput/TelephoneInput.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,wBAAwB;IACxB,WAAW;IACX,YAAY;IACZ,sBAAsB;AAC1B","sourcesContent":[".detail{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0px 0px 0px 0px;\n    margin: 0px;\n    height: 32px;\n    box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detail": `D6HBYHg6w8s3WPnPwTLX`
};
export default ___CSS_LOADER_EXPORT___;
