import React, {useCallback, useState, useContext, useEffect, useRef} from 'react';
import styles from './ProviderModal.module.css';
import LabelElement from 'GlobalComponents/LabelElement/LabelElement.js';
import commonStyles from 'GlobalComponents/CommonStyles.module.css';
import {createProviderInDB, updateProviderInDB} from 'ServerCalls/serverCalls.js';
import ConfirmationModal from 'GlobalComponents/ConfirmationModal/ConfirmationModal';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput';
import TextInput from 'GlobalComponents/TextInput/TextInput.js';
import TelephoneInput from 'GlobalComponents/TelephoneInput/TelephoneInput.js';

export default function ProviderModal({providerToEdit, setProviderToEdit, instance}){
    const originalProvider = useRef(JSON.parse(JSON.stringify(providerToEdit)));
    const {setLoading, setProvidersList, providersList} = useContext(QuoteMasterContext);
    const [message, setMessage] = useState('');
    const [newProvider, setNewProvider] = useState(providerToEdit);

    const changeTheValueOf = useCallback((field) => (event) => {
        const value = event.target.value; // Extract the value from the event object
        setNewProvider((prev) => ({
            ...prev,
            [field]: value
        }));
    }, [newProvider]);

    const inputWidth = '224px';
    const labelWidth = '160px';

    useEffect(() => {
        setMessage(
            <>
                <div className={styles.actionModalTitle}>
                {instance==='providersPanel' ? 'Editar Afiliado' : 'Adicionar novo Afiliado'}
                </div>
                <div className={commonStyles.parametersColumn}>
                    <div className={commonStyles.parameterRow}>
                        <LabelElement width={labelWidth} label={'AffiliateId'}/>
                        <DetailInput
                            width={inputWidth}
                            readOnly={instance==='providersPanel' ? true : false}
                            inputElement={
                                <TextInput
                                    inputValue={newProvider.affiliateId}
                                    onChange={changeTheValueOf('affiliateId')}
                                    readOnly={instance==='providersPanel' ? true : false}
                                />
                            }
                        />
                    </div>
                    <div className={commonStyles.parameterRow}>
                        <LabelElement width={labelWidth} label={'Nome'}/>
                        <DetailInput
                            width={inputWidth}
                            readOnly={false}
                            inputElement={
                                <TextInput
                                    inputValue={newProvider.providerName}
                                    onChange={changeTheValueOf('providerName')}
                                    readOnly={false}
                                />
                            }
                        />
                    </div>
                    <div className={commonStyles.parameterRow}>
                        <LabelElement width={labelWidth} label={'Telefone'}/>
                        <DetailInput
                            width={inputWidth}
                            readOnly={false}
                            inputElement={
                                <TelephoneInput provider={newProvider}  instance={instance} readOnly={false} />
                            }
                        />
                    </div>

                </div>
            </>
        )
    }, [newProvider]);
    


    const createNewProvider = async () => {
        if (allUserFieldsCorrect()){
            setLoading(true);
            const response = await createProviderInDB(newProvider);
            if (response.message !== 'Provider created'){
                alert(`Failed to create provider: ${response}` );
                setLoading(false);
                return;
            }
            const newProvidersList = [...providersList];
            newProvidersList[newProvidersList.length-1] = response.provider;
            setProvidersList(newProvidersList);
            setProviderToEdit(null);
            setLoading(false);
        }
    }

    const updateProvider = async () => {
        if (allUserFieldsCorrect()){
            setLoading(true);
            const response = await updateProviderInDB(newProvider);
            if (response.message !== 'Provider updated'){
                alert(`Failed to update provider: ${response.message}` );
                setLoading(false);
                return;
            }
            const providerIndex = providersList.findIndex((prov) => prov.affiliateId === newProvider.affiliateId);
            const newProvidersList = [...providersList];
            newProvidersList[providerIndex] = response.provider;
            setProvidersList(newProvidersList);
            setProviderToEdit(null);
            setLoading(false);
        }
    }

    const allUserFieldsCorrect = () => {

        if (newProvider.affiliateId === '' || newProvider.providerName === '' || newProvider.phoneNumber.countryCode === '' || newProvider.phoneNumber.areaCode === '' || newProvider.phoneNumber.number === ''){
            console.log('newProvider', newProvider);
            console.log('newProvider.phoneNumber', newProvider.phoneNumber);
            alert('Please fill all User fields');
            return false;
        }
        return true;
    }

    const cancelUpdate = () => {
        const providerIndex = providersList.findIndex((prov) => prov.affiliateId === providerToEdit.affiliateId);
        console.log('providerIndex', providerIndex);
        console.log('originalProvider', originalProvider);
        console.log('providerToEdit', providerToEdit);
        const newProvidersList = [...providersList];
        newProvidersList[providerIndex] = originalProvider.current;
        setProvidersList(newProvidersList);
        setProviderToEdit(null);
    }

    const cancelCreate = () => {
        setProviderToEdit(null);
        // remove the last provider from the list
        setProvidersList(prev=>prev.slice(0, prev.length-1));
    }

    let providerModalButtons = [
        {text: 'Cancel', onClick: instance==='providersPanel'? cancelUpdate: cancelCreate, width: 100},
        {text: instance==='providersPanel' ? 'Atualizar Afiliado': 'Add Afiliado', onClick: instance==='providersPanel' ? updateProvider : createNewProvider, width: 100},
    ];

    if (instance==='providersPanel'){
        providerModalButtons.push({text: providerToEdit.status === 'active' ? 'Desativar Afiliado' : 'Ativar Afiliado',  onClick: providerToEdit.status === 'active' ? ()=>handleChangeProviderStatus('inactive'): ()=>handleChangeProviderStatus('active') , width: 100});
    }

    return(
        <ConfirmationModal message={message} buttons={providerModalButtons}/>
    )
}