import React, { useContext, useState} from 'react';
import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js';


export default function RemoveProviderButton({providerIndex, setRemoveProviderConfirmationIndex}){

    return(
        <GlobalButton
            onClick={()=>setRemoveProviderConfirmationIndex(providerIndex)}
            label={'Remover Afiliado da Ordem de Serviço'}
            width={'160px'}
            type={'primary'}
            height={'auto'}
        />
    )
}

