// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fhVLA9OZscvOY0732lCd{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px 0px 16px;
    box-sizing: border-box;
    border: none;
    font-size: 16px;
    color: var(--letter-dark);
    flex-grow: 0;
}


.QwyDcRD96duAg80a15Pe,.LtGy4hMRXKFhs6IlB0R9{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 0px;
    box-sizing: border-box;
}

.LtGy4hMRXKFhs6IlB0R9{
    font-size: 14px;
    line-height: 14px;
    padding: 0px 0px 0px 8px;
}

.QwyDcRD96duAg80a15Pe{
    padding: 0px;
}

.kn7FYlt7s6Pd40hEn8_H{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 32px;
    padding: 0px;
    box-sizing: border-box;
    border: none;
}`, "",{"version":3,"sources":["webpack://./clientRoot/Static/WorkingArea/ServiceOrderManagement/ChatWindows/ChatHeader/ChatHeader.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,8BAA8B;IAC9B,0BAA0B;IAC1B,sBAAsB;IACtB,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,YAAY;AAChB;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;;IAEnB,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,YAAY;AAChB","sourcesContent":[".chatHeader{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0px 16px 0px 16px;\n    box-sizing: border-box;\n    border: none;\n    font-size: 16px;\n    color: var(--letter-dark);\n    flex-grow: 0;\n}\n\n\n.detail,.message{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n\n    margin: 0px;\n    box-sizing: border-box;\n}\n\n.message{\n    font-size: 14px;\n    line-height: 14px;\n    padding: 0px 0px 0px 8px;\n}\n\n.detail{\n    padding: 0px;\n}\n\n.chatHeaderRow{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    height: 32px;\n    padding: 0px;\n    box-sizing: border-box;\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatHeader": `fhVLA9OZscvOY0732lCd`,
	"detail": `QwyDcRD96duAg80a15Pe`,
	"message": `LtGy4hMRXKFhs6IlB0R9`,
	"chatHeaderRow": `kn7FYlt7s6Pd40hEn8_H`
};
export default ___CSS_LOADER_EXPORT___;
