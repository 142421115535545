
import React, {useContext, useState, useRef,useEffect } from 'react'

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import SelectionBox from 'GlobalComponents/SelectionBox/SelectionBox.js';

export default function AvailabilitySelect({providerIndex, itemQuote, readOnly, itemQuoteIndex=null}) {
    const {setCurrentServiceOrder, currentServiceOrder} = useContext(QuoteMasterContext);
    const [availability, setAvailability] = useState(itemQuote.shortestAvailability.availability);
    const firstLoadCompleted = useRef(false);

    useEffect(() => {
        if(firstLoadCompleted.current && itemQuoteIndex!==null && !readOnly){ // we don't run the useEffect for the instance of providerBestQuote only for itemQuotes 
            const newServiceOrder = {...currentServiceOrder};
            const newProvider = newServiceOrder.providers[providerIndex];
            console.log(newProvider.itemQuotes);
            console.log(itemQuoteIndex);
            const newItemQuote = newProvider.itemQuotes[itemQuoteIndex];
            newItemQuote.shortestAvailability.availability = availability;
            newProvider.providerBestQuote.shortestAvailability.availability = availability;
            for(const itemQuote of newProvider.itemQuotes){
                if(itemQuote.shortestAvailability.availability === 'unavailable'){
                    newProvider.providerBestQuote.shortestAvailability.availability = 'unavailable';
                    break;
                } else if(itemQuote.shortestAvailability.availability === 'needToOrder'){
                    newProvider.providerBestQuote.shortestAvailability.availability = 'needToOrder';
                    break;
                }
            }
            console.log('newServiceOrder', newServiceOrder);
            setCurrentServiceOrder(newServiceOrder);
        } else {
            firstLoadCompleted.current = true;
        }
    }, [availability]);

    useEffect(() => {
        if(currentServiceOrder.providers[providerIndex]){
            setAvailability(itemQuote.shortestAvailability.availability);
        }
    }, [itemQuote.shortestAvailability.availability]);

    const selection = [
        {value: 'inStock', label: 'Em Estoque'},
        {value: 'needToOrder', label: 'Sob Encomenda'},
        {value: 'unavailable', label: 'Indisponível'},
    ];
    return (
        <SelectionBox 
            selectionItems={selection} 
            mainFunction={setAvailability} 
            mainValue={availability} 
            instanceName={'Disponibilidade'}
            readOnly={readOnly}
        />
    );
}
