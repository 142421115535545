import React, { useContext, useCallback } from 'react'

import styles from 'GlobalComponents/CommonStyles.module.css';

import LabelElement from 'GlobalComponents/LabelElement/LabelElement.js';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput';
import TextInput from 'GlobalComponents/TextInput/TextInput.js';

export default function UserDetails({labelWidth, inputWidth}){
    const {currentServiceOrder, userOptions} = useContext(QuoteMasterContext);

    const userType = () => {
        const option = userOptions.find(option => option.value === currentServiceOrder.user.userType);
        if(option){
            return option.label;
        }
        return '';
    }

    return(
        <div className={styles.parametersColumn} > Usuário
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Nome'} />
                <DetailInput 
                    width={inputWidth}
                    readOnly={true}
                    inputElement={
                        <TextInput 
                            inputValue={currentServiceOrder.user.firstName + ' ' + currentServiceOrder.user.lastName} 
                            onChange={()=>{}} 
                            readOnly={true}
                        />
                    }
                />
            </div>
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'email'} />
                <DetailInput 
                        width={inputWidth}
                        readOnly={true}
                        inputElement={
                            <TextInput 
                                inputValue={currentServiceOrder.user.userEmail} 
                                onChange={()=>{}} 
                                readOnly={true}
                            />
                        }
                    />
            </div>
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Tipo'} />
                <DetailInput 
                    width={inputWidth}
                    readOnly={true}
                    inputElement={
                        <TextInput 
                            inputValue={userType()} 
                            onChange={()=>{}} 
                            readOnly={true}
                        />
                    }
                />
            </div>
        </div>
    )
}

