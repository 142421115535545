import React, { useEffect, useState, useContext} from 'react';

import {formatTime} from 'Contexts/Utils/formatTime.js';
import moment from 'moment-timezone';
import {timeZone} from 'Contexts/static_options/Classes.js'
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';

export default function TimeToGo({type, timeOflastMessageSentToProvider}){

    const {currentServiceOrder} = useContext(QuoteMasterContext);

    const [timeToGoMessage, setTimeToGoMessage] = useState('');
    const [timeToGoFormated, setTimeToGoFormated] = useState(0);

    useEffect(() => {
        if(type==='stage'){
            setTimeToGoMessage('Tempo de Estágio Restante: ');
        } else if(type==='fallBackToHuman'){
            setTimeToGoMessage('Tempo para Envio para Humano: ');
        } else if(type==='followUp'){
            setTimeToGoMessage('Follow up em: ');
        }
    }, [type])  

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = moment().tz(timeZone).format();

            let startTime = '';
            let length = 0;
            if(type==='fallBackToHuman'){
                const startStage = currentServiceOrder.stageStartTime.find(stage=>stage.stage===1).time ? 1 : 2;
                startTime = currentServiceOrder.stageStartTime.find(stage=>stage.stage===startStage).time;
                length = currentServiceOrder.totalTime*currentServiceOrder.timeMultiplier;            
            } else if(type==='stage'){
                startTime = currentServiceOrder.stageStartTime.find(stage=>stage.stage===currentServiceOrder.currentStage).time;
                length = currentServiceOrder.stageTime*currentServiceOrder.timeMultiplier;
            } else if(type==='followUp'){
                startTime = timeOflastMessageSentToProvider;
                length = currentServiceOrder.followUpTime*currentServiceOrder.timeMultiplier;
            }



            // check how much time has passed since the start of the stage
            const timeDifference = new Date(currentTime) - new Date(startTime).getTime();
            const timeDifferenceInSeconds = Math.floor(timeDifference / 1000);

            // check how much time is left
            const timeRemainingInSeconds = length - timeDifferenceInSeconds;

            if(timeRemainingInSeconds <= 0){
                clearInterval(interval);
                setTimeToGoFormated('00:00:00');
                return;
            }
            setTimeToGoFormated(formatTime(timeRemainingInSeconds));
        }, 1000);
        return () => clearInterval(interval);
    }, [currentServiceOrder.currentStage, type]);


    return(
        <div> {timeToGoMessage} {timeToGoFormated}</div>
    )
}