import React, { useContext, useCallback } from 'react'

import styles from 'GlobalComponents/CommonStyles.module.css';

import LabelElement from 'GlobalComponents/LabelElement/LabelElement.js';
import { QuoteMasterContext } from 'Contexts/QuoteMasterContext.js';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput.js';
import TextInput from 'GlobalComponents/TextInput/TextInput.js';
import MaskElement from 'GlobalComponents/MaskedInput/MaskedInput.js';
import LGPDSelect from 'GlobalComponents/SelectionBox/SelectionBoxesInstances/LGPDSelect';

export default function ClientDetails({labelWidth, inputWidth}){
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);
    const changeTheValueOf = useCallback((field) => (event) => {
        const value = event.target.value; // Extract the value from the event object
        setCurrentServiceOrder((prevOrder) => ({
            ...prevOrder,
            clientInformation: {
                ...prevOrder.clientInformation,
                [field]: value
            }
        }));
    }, [currentServiceOrder]);

    const  defaultReadOnly = currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated';
    return(
        <div className={styles.parametersColumn}>Informações do Cliente
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Nome'}/>
                <DetailInput 
                    width={inputWidth}
                    inputElement={
                        <TextInput 
                            inputValue={currentServiceOrder.clientInformation.clientName} 
                            onChange={changeTheValueOf('clientName')}
                            readOnly={defaultReadOnly}
                        />
                    }
                />
            </div>
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Telefone'}/>
                <DetailInput 
                    width={inputWidth}
                    inputElement={
                        <MaskElement 
                            inputValue={currentServiceOrder.clientInformation.clientPhoneNumber} 
                            onChange={changeTheValueOf('clientPhoneNumber')}
                            readOnly={defaultReadOnly}
                            maskLayout={'(99) 99999-9999'}
                        />
                    }
                />
            </div>	
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Aceite LGPD'}/>
                <DetailInput 
                    width={inputWidth}
                    inputElement={
                        <LGPDSelect readOnly={defaultReadOnly}/>
                    }
                />
            </div>				
        </div>
    )
}
