import React  from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import Tooltip from '@mui/material/Tooltip';


import styles from './TabToggleButton.module.css';

export default function TabToggleButton({isTabOpen, setIsTabOpen, direction}){
    return(
        <Tooltip title={isTabOpen ? 'Fechar Detalhes da Ordem de Serviço' : 'Abrir Detalhes da Ordem de Serviço'}>
            <div className={styles.toggleButton} onClick={()=>setIsTabOpen(prev=>!prev)}>
                {isTabOpen ? 
                    direction='horizontal'? <ArrowBackIosNewIcon /> : <KeyboardArrowUpOutlinedIcon />
                    :
                    direction='horizontal'? <ArrowForwardIosIcon /> : <KeyboardArrowDownOutlinedIcon />
                }
            </div>
        </Tooltip>
    )
}