// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SBeTfIr4DmVIBDQPBlIb, .OJvZ1dVfBnEPl0FrSMfg{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 8px; 
    padding-right: 8px;
    font-size: 15px;
    line-height: 15px;
    background: none;
    font-family: var(--quotemaster-font-family);
    justify-content: end;
    text-align: right;
}

.SBeTfIr4DmVIBDQPBlIb{
    user-select: none;
    cursor: default; /* Make cursor default to avoid the I-beam cursor */
}

.OJvZ1dVfBnEPl0FrSMfg::-webkit-outer-spin-button,
.OJvZ1dVfBnEPl0FrSMfg::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./clientRoot/Static/GlobalComponents/NumberInput/NumberInput.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,2CAA2C;IAC3C,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,eAAe,EAAE,mDAAmD;AACxE;;AAEA;;IAEI,wBAAwB;IACxB,SAAS;AACb","sourcesContent":[".numberInput, .numberInputNoSpin{\n    width: 100%;\n    height: 100%;\n    box-sizing: border-box;\n    border: none;\n    padding-top: 0px;\n    padding-bottom: 0px;\n    padding-left: 8px; \n    padding-right: 8px;\n    font-size: 15px;\n    line-height: 15px;\n    background: none;\n    font-family: var(--quotemaster-font-family);\n    justify-content: end;\n    text-align: right;\n}\n\n.numberInput{\n    user-select: none;\n    cursor: default; /* Make cursor default to avoid the I-beam cursor */\n}\n\n.numberInputNoSpin::-webkit-outer-spin-button,\n.numberInputNoSpin::-webkit-inner-spin-button{\n    -webkit-appearance: none;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"numberInput": `SBeTfIr4DmVIBDQPBlIb`,
	"numberInputNoSpin": `OJvZ1dVfBnEPl0FrSMfg`
};
export default ___CSS_LOADER_EXPORT___;
