import React, {useContext, useState, useEffect, useRef  } from 'react'

import styles from 'GlobalComponents/CommonStyles.module.css';

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';   
import LabelElement from 'GlobalComponents/LabelElement/LabelElement';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput';
import NumberInput from 'GlobalComponents/NumberInput/NumberInput';
import TextInput from 'GlobalComponents/TextInput/TextInput';
import {LLMCost} from 'Contexts/static_options/LLMs.js';
import moment from 'moment-timezone';
import { timeZone } from 'Contexts/static_options/static_options';
import { getExchangeRateUSD } from 'Contexts/ServerCalls/serverCalls.js';


export default function LLMCostSummary({}){
    const {currentServiceOrder} = useContext(QuoteMasterContext);
    const [costArray, setCostArray] = useState([]);
    const [exchangeRealUSD, setExchangeRealUSD] = useState([]);
    const gotExchangeRate = useRef(false);

    useEffect(() => {
        const now = moment().tz(timeZone).format('YYYY-MM-DD');
        let newCostArray = [];
        for(const tokenLine of currentServiceOrder.tokens){
            const LLM = LLMCost.find(llm => llm.value === tokenLine.model);
            const costs = LLM.costs;
            
            costs.forEach(cost => {
                if(cost.endDate === 'current'){
                    cost.endDate = now
                }
            });
            console.log('exchangeRealUSD', exchangeRealUSD);

            let exchangeRateUSDReal = exchangeRealUSD.find(exchange => exchange.date === now);
            console.log('exchangeRateUSDReal', exchangeRateUSDReal);
            if(!exchangeRateUSDReal){
                exchangeRateUSDReal = {date: now, value: 5.5};
                getExchangeRateUSDReal(now);
            }

            const tokenLineCostPerMMTokens = costs.find(cost => moment(cost.startDate).isSameOrBefore(now) && moment(cost.endDate).isSameOrAfter(now));
            const outputCost = tokenLine.output===0?0:tokenLineCostPerMMTokens.costPerMMOutput*tokenLine.output/1000000;
            const inputCost = tokenLine.input===0?0:tokenLineCostPerMMTokens.costPerMMInput*tokenLine.input/1000000;
            const newCostLine = {
                agent: tokenLine.agent,
                model: tokenLine.model,
                inputTokens: tokenLine.input,
                costPerMMInput: tokenLineCostPerMMTokens.costPerMMInput,
                inputCost: inputCost,
                outputTokens: tokenLine.output,
                costPerMMOutput: tokenLineCostPerMMTokens.costPerMMOutput,
                outputCost: outputCost,
                totalCostUSD: outputCost + inputCost,
                totalCostReal: (outputCost + inputCost)*exchangeRateUSDReal.value
            }
            newCostArray.push(newCostLine);
        }
        const totalInputCost = newCostArray.reduce((acc, costLine) => acc + costLine.inputCost, 0);
        const totalOutputCost = newCostArray.reduce((acc, costLine) => acc + costLine.outputCost, 0);
        const totalInputTokens = currentServiceOrder.tokens.reduce((acc, tokenLine) => acc + tokenLine.input, 0);
        const totalOutputTokens = currentServiceOrder.tokens.reduce((acc, tokenLine) => acc + tokenLine.output, 0);
        
        const totalCostLine = {
            model: '',
            agent: 'Total',
            inputTokens: totalInputTokens,
            inputCost: totalInputCost,
            costPerMMInput: totalInputTokens===0?0:totalInputCost/totalInputTokens,
            outputTokens: totalOutputTokens,
            outputCost: totalOutputCost,
            costPerMMOutput: totalOutputTokens===0?0:totalOutputCost/totalOutputTokens,
            totalCostUSD: totalInputCost + totalOutputCost,
            totalCostReal: newCostArray.reduce((acc, costLine) => acc + costLine.totalCostReal, 0)
        }
        newCostArray.push(totalCostLine);
        setCostArray(newCostArray);
    }, [currentServiceOrder.tokens, exchangeRealUSD]);

    async function getExchangeRateUSDReal(now){
        if(!gotExchangeRate.current){
            gotExchangeRate.current = true;
            const response = await getExchangeRateUSD('BRL', now )
            if(response){
                console.log()
                const newExchangeRates = [...exchangeRealUSD, {date: now, value: response}];
                setExchangeRealUSD(newExchangeRates);
            }

        }
    }

    return (
        <div className={styles.mainRow}>
            <div className={styles.parametersColumn}>
                <div className={styles.parameterRow}>
                    <LabelElement width={'180px'} label={'Agent'} />
                    <LabelElement width={'140px'} label={'Model'} />
                    <LabelElement width={'100px'} label={'Input Tokens'} />
                    <LabelElement width={'120px'} label={'US$/MM Input Tokens'} />
                    <LabelElement width={'100px'} label={'Input Cost'} />
                    <LabelElement width={'100px'} label={'Output Tokens'} />
                    <LabelElement width={'120px'} label={'US$/MM Output Tokens'} />
                    <LabelElement width={'100px'} label={'Output Cost'} />
                    <LabelElement width={'100px'} label={'Total Cost (US$)'} />
                    <LabelElement width={'100px'} label={'Total Cost (R$)'} />
                </div>
                {costArray.map((costLine, index) => (
                    <div className={styles.parameterRow} key={index}>
                        <DetailInput
                            width={'180px'}
                            readOnly={true}
                            inputElement={
                                <TextInput
                                    inputValue={costLine.agent}
                                    onChange={()=>{}}
                                    readOnly={true}
                                    width={'180px'}
                                />
                            }
                        />
                        <DetailInput
                            width={'140px'}
                            readOnly={true}
                            inputElement={
                                <TextInput
                                    inputValue={costLine.model}
                                    onChange={()=>{}}
                                    readOnly={true}
                                    width={'140px'}
                                />
                            }
                        />
                        <DetailInput
                            width={'100px'}
                            readOnly={true}
                            inputElement={
                                <NumberInput
                                    inputValue={costLine.inputTokens.toFixed(0)}
                                    onChange={()=>{}}
                                    readOnly={true}
                                    width={'100px'}
                                />
                            }
                        />
                        <DetailInput
                            width={'120px'}
                            readOnly={true}
                            inputElement={
                                <NumberInput
                                    inputValue={costLine.costPerMMInput.toFixed(2)}
                                    onChange={()=>{}}
                                    readOnly={true}
                                    width={'120px'}
                                />
                            }
                        />
                        <DetailInput
                            width={'100px'}
                            readOnly={true}
                            inputElement={
                                <NumberInput
                                    inputValue={costLine.inputCost.toFixed(3)}
                                    onChange={()=>{}}
                                    readOnly={true}
                                    width={'100px'}
                                />
                            }
                        />
                        <DetailInput
                            width={'100px'}
                            readOnly={true}
                            inputElement={
                                <NumberInput
                                    inputValue={costLine.outputTokens.toFixed(0)}
                                    onChange={()=>{}}
                                    readOnly={true}
                                    width={'100px'}
                                />
                            }
                        />
                        <DetailInput
                            width={'120px'}
                            readOnly={true}
                            inputElement={
                                <NumberInput
                                    inputValue={costLine.costPerMMOutput.toFixed(2)}
                                    onChange={()=>{}}
                                    readOnly={true}
                                    width={'120px'}
                                />
                            }
                        />
                        <DetailInput
                            width={'100px'}
                            readOnly={true}
                            inputElement={
                                <NumberInput
                                    inputValue={costLine.outputCost.toFixed(3)}
                                    onChange={()=>{}}
                                    readOnly={true}
                                    width={'100px'}
                                />
                            }
                        />
                        <DetailInput
                            width={'100px'}
                            readOnly={true}
                            inputElement={
                                <NumberInput
                                    inputValue={costLine.totalCostUSD.toFixed(3)}
                                    onChange={()=>{}}
                                    readOnly={true}
                                    width={'100px'}
                                />
                            }
                        />
                        <DetailInput
                            width={'100px'}
                            readOnly={true}
                            inputElement={
                                <NumberInput
                                    inputValue={costLine.totalCostReal.toFixed(3)}
                                    onChange={()=>{}}
                                    readOnly={true}
                                    width={'100px'}
                                />
                            }
                        />

                    </div>
                ))}
            </div>
          
        </div>
    )
}