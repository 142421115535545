import React, {useContext} from 'react';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

export default function OpenProviderDetailsButton({provider}){
    const {setProviderQuoteDetails, currentServiceOrder} = useContext(QuoteMasterContext);

    const onClick = () => {
        if(!provider.affiliateId){
            alert('Afiliado não possui ID');
            return;
        }
        const index = currentServiceOrder.providers.findIndex((prov) => prov.affiliateId === provider.affiliateId);
        setProviderQuoteDetails({providerIndex:index});
    }

    return(
        <div onClick={onClick} 
            style={{
                display: 'flex', 
                flexDirection:'column', 
                alignItems:'center', 
                width: '24px',
                color: 'var(--letter-dark)', 
                cursor: 'pointer',
            }}>
            <Tooltip title={'Detalhes '+ provider.providerName} placement="bottom">
                <InfoOutlinedIcon/>
            </Tooltip>
        </div>
    )
}
