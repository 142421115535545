import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-modal';

import styles from './QuoteMaster.module.css';
import EntryMessage from './EntryMessagePage/EntryMessage.js';
import {QuoteMasterContext} from './QuoteMasterContext.js';
import WaitingProgressLine from './GlobalComponents/WaitingProgressLine/WaitingProgressLine.js';
import SOHeader from './WorkingArea/ServiceOrderManagement/SOHeader/SOHeader.js';
import ConversationsArea from './WorkingArea/ServiceOrderManagement/ChatWindows/ConversationsArea.js';
import ProviderQuoteDetailsPopup from './WorkingArea/ServiceOrderManagement/ProviderQuoteDetails/ProviderQuoteDetailsPopup.js';
import SODeletionConfirmation from './WorkingArea/ServiceOrdersTab/SODeletionConfirmation.js';
import {getUserServiceOrders} from './ServerCalls/serverCalls.js';
import UserAdminPanel from './UserAdminPanel/UserAdminPanel.js';
import ServiceOrdersTab from './WorkingArea/ServiceOrdersTab/ServiceOrdersTab.js';
import ProvidersPanel from './ProvidersPanel/ProvidersPanel.js';
import ProvidersReport from './ProvidersPanel/ProvidersReport.js';
import RunningServiceOrdersPanel from './RunningServiceOrdersPanel/RunningServiceOrdersPanel.js';


export default function QuoteMaster() {
    Modal.setAppElement('#root');
    const {SOToDelete, user, setUser, setLoading, loading, setServiceOrders, providerQuoteDetails} = useContext(QuoteMasterContext);
    const [pageDimensions, setPageDimensions] = useState({width: window.innerWidth, height: window.innerHeight});
    const [userList, setUserList] = useState([]);
    
    const pageHeaderHeight = 60;
    const [orderManagementDimensions, setOrderManagementDimensions] = useState({
        width: pageDimensions.width,
        height: pageDimensions.height - pageHeaderHeight
    });
    const [isUserAdminPanelOpen, setIsUserAdminPanelOpen] = useState(false);
    const [isProvidersPanelOpen, setIsProvidersPanelOpen] = useState(false);
    const [isProvidersReportOpen, setIsProvidersReportOpen] = useState(false);
    const [isServiceOrdersTabOpen, setIsServiceOrdersTabOpen] = useState(true);
    const [headerView, setHeaderView] = useState('main');
    const [isSOHeaderOpen, setIsSOHeaderOpen] = useState(true);
    const serviceOrdersTabWidth=248;
    const SOHeaderTabWidth = headerView==='main'? 352 : window.innerWidth - serviceOrdersTabWidth;
    const conversationsWidth = pageDimensions.width - (isSOHeaderOpen ? SOHeaderTabWidth : 0) - (isServiceOrdersTabOpen ? serviceOrdersTabWidth : 0);
    const [isRunningServiceOrdersPanelOpen, setIsRunningServiceOrdersPanelOpen] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function handleResize() {
        setPageDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
        setOrderManagementDimensions({
            width: window.innerWidth,
            height: window.innerHeight - pageHeaderHeight
        });
    }

    useEffect(() => {
        checkUserInStorage();
    }, []);

    async function checkUserInStorage(){
        const storedQuoteMasterUser = localStorage.getItem('quoteMasterUser');
        if (storedQuoteMasterUser) { // if there is a user in the local storage, we use it 
            const parsedstoredQuoteMasterUser= JSON.parse(storedQuoteMasterUser);
            setUser(parsedstoredQuoteMasterUser);
            if(process.env.REACT_APP_NODE_ENV!=='development'){ // just run get setvice orders if not in development
                handleGetServiceOrders();
            }
        } else{
            console.log('no user logged in' )
        }
    }

    const handleGetServiceOrders = async () => {
        setLoading(true);
        try{
            const response = await getUserServiceOrders();
            if(response.serviceOrders.length === 0){
                console.log('No service orders found');
                setLoading(false);
                return;
            }
            setServiceOrders(response.serviceOrders);
            setLoading(false);
        } catch(error){
            setLoading(false);
            console.error('Error getting user service orders', error);
        }
        
    }

    return (
        <div className={styles.mainstructure}>
            {!user.userEmail ?
                 <EntryMessage areaDimensions={orderManagementDimensions} handleGetServiceOrders={handleGetServiceOrders}/>
                : 
                <>
                    {(isUserAdminPanelOpen && user.userType==='master-admin') ? 
                        <UserAdminPanel userList={userList} setUserList={setUserList} setIsUserAdminPanelOpen={setIsUserAdminPanelOpen}/>
                        : 
                        <>
                            {((isProvidersPanelOpen|| isProvidersReportOpen) && (user.userType==='master-admin' || user.userType==='admin')) ?
                                <>
                                    {isProvidersReportOpen && <ProvidersReport setIsProvidersReportOpen={setIsProvidersReportOpen}/>}
                                    {!isProvidersReportOpen && isProvidersPanelOpen && <ProvidersPanel setIsProvidersPanelOpen={setIsProvidersPanelOpen} setIsProvidersReportOpen ={setIsProvidersReportOpen}/>}
                                </>
                                :
                                <>
                                    {loading && <WaitingProgressLine  /> }'
                                    {isRunningServiceOrdersPanelOpen ?
                                        <RunningServiceOrdersPanel setIsRunningServiceOrdersPanelOpen={setIsRunningServiceOrdersPanelOpen}/>
                                        :
                                        <>         
                                            <div className={styles.workingArea}
                                                style={{height: window.innerHeight}}
                                            >    
                                                {isServiceOrdersTabOpen && <ServiceOrdersTab handleGetServiceOrders={handleGetServiceOrders} setIsUserAdminPanelOpen={setIsUserAdminPanelOpen} serviceOrdersTabWidth={serviceOrdersTabWidth} setIsProvidersPanelOpen={setIsProvidersPanelOpen} setIsRunningServiceOrdersPanelOpen={setIsRunningServiceOrdersPanelOpen}/>}
                                                {isSOHeaderOpen && <SOHeader SOHeaderTabWidth={SOHeaderTabWidth} headerView={headerView} setHeaderView={setHeaderView}/>}
                                                {headerView==='main' && <ConversationsArea setIsSOHeaderOpen={setIsSOHeaderOpen} isSOHeaderOpen={isSOHeaderOpen} conversationsWidth={conversationsWidth} conversationsHeight={pageDimensions.height}/> }
                                            </div> 
                                            {providerQuoteDetails && 
                                                <ProviderQuoteDetailsPopup providerIndex={providerQuoteDetails.providerIndex} list={providerQuoteDetails.list}/>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                    {SOToDelete && <SODeletionConfirmation />}
                </>
            }
        </div>
    )
}




