

import React, {useContext} from 'react'
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';  
import {ItemQuote} from 'Contexts/static_options/Classes.js'
import SwitchOption from 'GlobalComponents/SwitchOption/SwitchOption.js';

export default function LinkReponseSwitch({providerIndex, setRemoveLinkResponseConfirmationIndex}){
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);

    const changeLinkResponse = () => {
        if(currentServiceOrder.items.length === 0){
          alert('Adicione itens ao pedido de serviço antes de habilitar a cotação via link');
          return;
        } else{
          const itemsWithoutNameOrGroupInfo = currentServiceOrder.items.filter((item) => item.itemName === '' || item.itemGroup==='');
          if(itemsWithoutNameOrGroupInfo.length>0){
            alert('Adicione nome e grupo em todos os itens antes de habilitar a cotação via link');
            return;
          }
        }

        const newServiceOrder = {...currentServiceOrder};
        const newProvider = newServiceOrder.providers[providerIndex];
        if(!newProvider.quoteViaLink){
          newProvider.quoteViaLink = !newProvider.quoteViaLink;
          newServiceOrder.items.forEach((item, index) => {
            newProvider.itemQuotes[index] = new ItemQuote(index)
            newProvider.providerBestQuote.itemQuotesIndexes.push(index);
          });
        } else{
          setRemoveLinkResponseConfirmationIndex(providerIndex);
        }
        setCurrentServiceOrder(newServiceOrder);
    }



    return(
      <SwitchOption
        checked={currentServiceOrder.providers[providerIndex].quoteViaLink}
        onChange={changeLinkResponse}
        name="checked"
        color="primary"
        disabled={currentServiceOrder.currentStage!=='notInitiated'}
      />
    )
}

