import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from 'GlobalComponents/CommonStyles.module.css';
import modalStyles from './ProviderQuoteDetails.module.css';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import LabelElement from 'GlobalComponents/LabelElement/LabelElement.js';
import NumberInput from 'GlobalComponents/NumberInput/NumberInput';
import TextInput from 'GlobalComponents/TextInput/TextInput';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput';
import BrandSelect from 'GlobalComponents/SelectionBox/SelectionBoxesInstances/BrandSelect/BrandSelect.js';
import AvailabilitySelect from 'GlobalComponents/SelectionBox/SelectionBoxesInstances/AvailabilitySelect.js';
import DateElement from 'GlobalComponents/DateInput/DateElement';
import Tooltip from '@mui/material/Tooltip';
import {convertISODateToReadableString2} from 'Contexts/Utils/formatTime.js';

export default function ItemQuotes({providerIndex}){
    const {currentServiceOrder} = useContext(QuoteMasterContext);

    return(
        <ul className={modalStyles.quotesBox} > 
            <div className={modalStyles.boxTitle}>
                Cotações por Item
            </div>
            <div className={styles.parameterRow}>
                <LabelElement width={'104px'} label={'SKU'} />
                <LabelElement width={'196px'} label={'Descr. Item'} />
                <LabelElement width={'88px'} label={'Preço'} />
                <LabelElement width={'130px'} label={'Marca'} />
                <LabelElement width={'60px'} label={'Linha'} />
                <LabelElement width={'136px'} label={'Disponibilidade'} />
                <LabelElement width={'96px'} label={'Data'} />
                <Tooltip 
                    title={
                        currentServiceOrder.providers[providerIndex].providerBestQuote.brand.tier===2 || currentServiceOrder.providers[providerIndex].providerBestQuote.shortestAvailability.availability==='needToOrder' ?
                            currentServiceOrder.providers[providerIndex].providerBestQuote.brand.tier===2 && currentServiceOrder.providers[providerIndex].providerBestQuote.shortestAvailability.availability==='needToOrder' ?
                                "Considera uma penalidade de 7 dias por marcas de segunda linha e 3 dias para encomendadas" :
                                currentServiceOrder.providers[providerIndex].providerBestQuote.brand.tier===2 ?
                                    "Considera uma penalidade de 7 dias caso haja produtos de segunda linha na cotação" :
                                    "Considera uma penalidade de 3 dias caso haja produtos encomendados na cotação"
                        :''
                    }
                    placement={'bottom'} >
                    <div>
                        <LabelElement width={'48px'} label={currentServiceOrder.providers[providerIndex].providerBestQuote.brand.tier===2 || currentServiceOrder.providers[providerIndex].providerBestQuote.shortestAvailability.availability==='needToOrder' ? 'Dias*': 'Dias'} />
                    </div>
                </Tooltip>
                <LabelElement width={'96px'} label={'Status'} />
                <LabelElement width={'136px'} label={'Cotado em'} />
                <LabelElement width={'70px'} label={'Estágio'} />
                <LabelElement width={'100px'} label={'Atual'} />
            </div>
            {currentServiceOrder.providers[providerIndex].itemQuotes.map((itemQuote, index) => (
                <ItemQuoteLine key={index} itemQuote={itemQuote} providerIndex={providerIndex} itemQuoteIndex={index}/> 
            ))}
        </ul>
    )
}

function ItemQuoteLine({itemQuote, providerIndex, itemQuoteIndex}){
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);

    useEffect(() => {
        if(currentServiceOrder.currentStage==='notInitiated' && currentServiceOrder.providers[providerIndex].quoteViaLink){
            const newServiceOrder = {...currentServiceOrder};
            const newProvider = {...newServiceOrder.providers[providerIndex]};
            const newItemQuote = {...newProvider.itemQuotes[itemQuoteIndex]};
            newProvider.itemQuotes[itemQuoteIndex] = newItemQuote;
            newServiceOrder.providers[providerIndex] = newProvider;
            newItemQuote.shortestAvailability.date = currentServiceOrder.providers[providerIndex].providerBestQuote.shortestAvailability.date;
            newItemQuote.shortestAvailability.days = currentServiceOrder.providers[providerIndex].providerBestQuote.shortestAvailability.days;
            fixItemQuoteRelativeDays(newItemQuote);
            newItemQuote.completed = newItemQuote.brand.tier && newItemQuote.shortestAvailability.availability && newItemQuote.shortestAvailability.date && newItemQuote.price;
            setCurrentServiceOrder(newServiceOrder);
        }
    }, [currentServiceOrder.providers[providerIndex].providerBestQuote.shortestAvailability.date]);

    useEffect(() => {
        if(currentServiceOrder.currentStage==='notInitiated' && currentServiceOrder.providers[providerIndex].quoteViaLink){
            const newServiceOrder = {...currentServiceOrder};
            const newProvider = {...newServiceOrder.providers[providerIndex]};
            const newItemQuote = {...newProvider.itemQuotes[itemQuoteIndex]};
            newProvider.itemQuotes[itemQuoteIndex] = newItemQuote;
            newServiceOrder.providers[providerIndex] = newProvider;
            fixItemQuoteRelativeDays(newItemQuote);
            newItemQuote.completed = newItemQuote.brand.tier && newItemQuote.shortestAvailability.availability && newItemQuote.shortestAvailability.date && newItemQuote.price;              
            setCurrentServiceOrder(newServiceOrder);
        }
    }, [itemQuote.brand.tier, itemQuote.shortestAvailability.availability]);

    function fixItemQuoteRelativeDays(newItemQuote){
        newItemQuote.shortestAvailability.relativeDays = itemQuote.shortestAvailability.days;
        if(newItemQuote.brand.tier === 2){
            newItemQuote.shortestAvailability.relativeDays += currentServiceOrder.tier2Penalty;
        } 
        if(newItemQuote.shortestAvailability.availability === 'needToOrder'){
            newItemQuote.shortestAvailability.relativeDays += currentServiceOrder.orderPenalty;
        } 
    }

    const setItemPrice = useCallback((index) => (event) => { // it's ony possible to change the price manually when we are on the first stage and the quote is via link
        const priceAux = parseFloat(event.target.value);
        let price;
        if(isNaN(priceAux)){
            price=0;
        } else {
            price = priceAux;
        }
        const newServiceOrder = {...currentServiceOrder};
        const newProvider = {...newServiceOrder.providers[providerIndex]};
        const newItemQuote = {...newProvider.itemQuotes[index]};
        newProvider.itemQuotes[itemQuoteIndex] = newItemQuote;
        newServiceOrder.providers[providerIndex] = newProvider;
        newItemQuote.price = price;
        newProvider.providerBestQuote.price = newProvider.itemQuotes.reduce((acc, itemQuote) => acc + parseFloat(itemQuote.price), 0);
        newItemQuote.completed = newItemQuote.brand.tier && newItemQuote.shortestAvailability.availability && newItemQuote.shortestAvailability.date && newItemQuote.price;
        setCurrentServiceOrder(newServiceOrder);
    }, [currentServiceOrder]);

    const defaultReadOnly = currentServiceOrder.currentStage==='notInitiated' && currentServiceOrder.providers[providerIndex].quoteViaLink ? false : true;
    
    return(
        <li className={styles.parameterRow}>
            <DetailInput
                width={'104px'}
                readOnly={true} 
                inputElement={<TextInput inputValue={currentServiceOrder.items[itemQuote.itemIndex].SKU} onChange={()=>{}} readOnly={true}/>}
            />
            <DetailInput
                width={'196px'}
                readOnly={true} 
                inputElement={<TextInput inputValue={currentServiceOrder.items[itemQuote.itemIndex].itemName} onChange={()=>{}} readOnly={true} />}
            />
            <DetailInput
                width={'88px'}
                readOnly={defaultReadOnly} 
                inputElement={<NumberInput inputValue={itemQuote.price} onChange={setItemPrice(itemQuoteIndex)} readOnly={defaultReadOnly} spin={false}/>}
            />
            <DetailInput
                width={'130px'}
                readOnly={defaultReadOnly} 
                inputElement={<BrandSelect itemQuote={itemQuote} readOnly={defaultReadOnly} providerIndex={providerIndex} itemQuoteIndex={itemQuoteIndex}/>}
            />
            <DetailInput
                width={'60px'}
                readOnly={true} 
                inputElement={ <NumberInput inputValue={itemQuote.brand.tier} onChange={()=>{}} readOnly={true}  />}                
            />
            <DetailInput
                width={'136px'}
                readOnly={defaultReadOnly} 
                inputElement={
                    <AvailabilitySelect itemQuote={itemQuote} readOnly={defaultReadOnly}  providerIndex={providerIndex} itemQuoteIndex={itemQuoteIndex} />}
            />
            <DetailInput
                width={'96px'}
                readOnly={true}
                inputElement={ <DateElement inputValue={itemQuote.shortestAvailability.date} onChange={()=>{}}/>}                
            />
            <DetailInput
                width={'48px'}
                readOnly={true} 
                inputElement={<NumberInput inputValue={itemQuote.shortestAvailability.relativeDays} onChange={()=>{}} readOnly={true} /> }
            />
            <div>
                <DetailInput width={'96px'} readOnly={true} 
                    inputElement={<TextInput inputValue={itemQuote.completed?'Completa': 'Incompleta'} onChange={()=>{}} readOnly={true} />}
                />
            </div>
            <DetailInput
                width={'136px'}
                readOnly={true} 
                inputElement={<TextInput inputValue={convertISODateToReadableString2(itemQuote.quoteReceivedAtTime)} onChange={()=>{}} readOnly={true} />}
            />
            <DetailInput
                width={'70px'}
                readOnly={true} 
                inputElement={ <NumberInput inputValue={itemQuote.stage} onChange={()=>{}} readOnly={true} />}
            />
            <DetailInput
                width={'100px'}
                readOnly={true} 
                inputElement={<TextInput inputValue={itemQuote.outdated?'Desatualizada': 'Atualizada'} onChange={()=>{}} readOnly={true} />}
            /> 
        </li>
    )
}