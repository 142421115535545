import React, { useContext, useState} from 'react';
import SwitchOption from 'GlobalComponents/SwitchOption/SwitchOption';
import LabelElement from 'GlobalComponents/LabelElement/LabelElement';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import {updateWhatsappInfo} from 'Contexts/ServerCalls/serverCalls.js';
import styles from 'GlobalComponents/CommonStyles.module.css';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput.js';

export default function ValidWhatsappButton({providerIndex}){
    const {currentServiceOrder, setCurrentServiceOrder, setLoading} = useContext(QuoteMasterContext);

    const changeValidWhatsapp = async () => {

        const newServiceOrder = {...currentServiceOrder};
        const newProvider = newServiceOrder.providers[providerIndex];
        if(!newProvider.validWhatsapp && newServiceOrder.currentStage !== 'notInitiated'){
            alert('Não é possível validar o whatsapp de um afiliado após a cotação ter sido iniciada');
            return;
        }
        newProvider.validWhatsapp = !newProvider.validWhatsapp;
        console.log('newServiceOrder', newServiceOrder)
        const serviceOrderId = newServiceOrder.serviceOrderId;
        console.log('serviceOrderId', serviceOrderId)
        if(currentServiceOrder.currentStage === 'notInitiated'){ // we don't need to update the database if the service order is not initiated yet
            setCurrentServiceOrder(newServiceOrder);
            return;
        }

        // if the service order is already initiated, we need to update the database
        setLoading(true);
            const response = await updateWhatsappInfo(newServiceOrder.serviceOrderId, newProvider.affiliateId, newProvider.validWhatsapp);
            console.log('response', response)
            if(response.message === 'Whatsapp info updated'){
                setCurrentServiceOrder(response.serviceOrder);
            }else{
                alert('Erro ao atualizar o Whatsapp do Afiliado: ' + response.message);
                setCurrentServiceOrder(prev => prev);
            }
        setLoading(false);
    }


    return(
        <div className={styles.parameterRow}>
            <LabelElement width={'248px'} label={'Afiliado possui Whatsapp Válido'} />
            <DetailInput
                width={'60px'}
                readOnly={false}
                inputElement={  
                    <SwitchOption 
                        checked={currentServiceOrder.providers[providerIndex].validWhatsapp}
                        onChange={changeValidWhatsapp} 
                        name="checked" 
                        color="primary"
                        disabled={false}
                    />
                }
            />
        </div>
    )
}

