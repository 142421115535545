// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nlW3UGnvgFmZoBjv_u_H{
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    height: 32px;
    border-bottom: 1px solid var(--border-dark);
}`, "",{"version":3,"sources":["webpack://./clientRoot/Static/GlobalComponents/DetailInput/DetailInput.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;IACZ,2CAA2C;AAC/C","sourcesContent":[".detailInput{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    box-sizing: border-box;\n    height: 32px;\n    border-bottom: 1px solid var(--border-dark);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailInput": `nlW3UGnvgFmZoBjv_u_H`
};
export default ___CSS_LOADER_EXPORT___;
