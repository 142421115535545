
import React, {useContext, useCallback } from 'react'
import styles from 'GlobalComponents/CommonStyles.module.css';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';  
import LabelElement from 'GlobalComponents/LabelElement/LabelElement.js';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput.js';
import NumberInput from 'GlobalComponents/NumberInput/NumberInput.js';
import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js';
import QuotesSummary from './QuotesSummary/QuotesSummary.js';
import TabToggleButton from '../../TabToggleButton/TabToggleButton.js';
import {Provider} from 'Contexts/static_options/Classes.js';
import ProvidersList from './ProvidersList.js';
import StageTimesSummary from './StageTimesSummary.js'; 

export default function ProvidersManagement({view, setView, setIsSOHeaderOpen, isSOHeaderOpen, selectedProvider, setSelectedProvider, selectedProviderIndex, providersList}) { 
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);

    const changeNumberOfProviders = useCallback((event) => {
        const targetValue = event.target.value;
        const providersListLength = providersList.length;
        if(targetValue<1){
            return;
        }

        let newProviders;
        if(targetValue>providersListLength){
            newProviders = [...currentServiceOrder.providers];
            for(let i = providersListLength; i<targetValue; i++){
                const newProvider = new Provider(`Fornecedor ${i+1}`, `${i+1}${i+1}${i+1}${i+1}${i+1}${i+1}${i+1}`);
                newProviders.push(newProvider);
            }
        } else{
            const newProvidersNewLength = targetValue-currentServiceOrder.providersNotConsidered.length;
            newProviders = currentServiceOrder.providers.slice(0, newProvidersNewLength);
        } 
        setCurrentServiceOrder({...currentServiceOrder, providers: newProviders});
    }, [currentServiceOrder, providersList]);


    return (
        <div className={styles.parametersColumn}  style={{marginTop: '16px', width:'400px', paddingBottom: '0px'}}>
            <div className={styles.parametersColumn}  style={{margin: '0px', width:'400px' }}>
                <div style={{ height: '32px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '8px'}} >  
                    <TabToggleButton setIsTabOpen={setIsSOHeaderOpen} isTabOpen={isSOHeaderOpen} direction={'horizontal'}/>
                    <div style={{ height: '32px', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%', marginBottom: '8px'}} >  
                        <GlobalButton  onClick={() => setView('singleChatWindow')} label='Chat Único' width='136px' type={view === 'singleChatWindow' ? 'selected' : 'secondary'} />
                        <GlobalButton  onClick={() => setView('multiChatWindow')} label='Multi Chat' width='136px' type={view === 'multiChatWindow' ? 'selected' : 'secondary'} />
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: view==='singleChatWindow' ? 'column' : 'row', justifyContent: 'space-between', width: '100%', marginBottom: '8px'}} >	
                    <StageTimesSummary />
                    <QuotesSummary />
                </div>
                <div className={styles.parameterRow}>
                    <LabelElement width={'340px'} label={'Afiliados na Cotação'} />
                    <DetailInput
                        width={'60px'}
                        readOnly={currentServiceOrder.currentStage!=='notInitiated'}
                        inputElement={
                            <NumberInput
                                inputValue={currentServiceOrder.providers.length+currentServiceOrder.providersNotConsidered.length}
                                onChange={changeNumberOfProviders}
                                readOnly={currentServiceOrder.currentStage!=='notInitiated'}
                                spin={true}
                            />
                        }
                    />
                </div> 
            </div>
            {view==='singleChatWindow' && <ProvidersList selectedProvider={selectedProvider} setSelectedProvider={setSelectedProvider} selectedProviderIndex={selectedProviderIndex} view={view} providersList={providersList}/>}
        </div> 
    )
}