

const isDesktop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent);
    const hasTouchScreen = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
    const response = !isMobileDevice && !hasTouchScreen;
    return response;
};

const serverAddressMobile = 'https://192.168.208.11:3000';
const serverAddressDesktop = `${process.env.REACT_APP_SERVER_ADDRESS}`
const serverAddressDev = isDesktop() ? serverAddressDesktop : serverAddressMobile;
export const serverAddressPreAddress =  (process.env.REACT_APP_NODE_ENV==='development' || process.env.REACT_APP_NODE_ENV==='githubworkspaces') ? serverAddressDev : 'https://apiquotemaster.carglass.com.br' ;
export const serverAddress = `${serverAddressPreAddress}/api`;
export const errorsToRedirectFullLogin = ['NoUserInSession', 'invalid_grant'];

export const logoff = async () => {
    try {
        const response = await fetch(`${serverAddress}/terminate-session/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        });
        if (!response.ok) { // Handle error
            console.error("Logoff failed");
        }
        console.log("Logoff successful");
    } catch (error) {
        console.error("Network error during terminate session", error);
        return 'failed';
    }
};

export const userLogin = async (email, password) => {
    try {
        const response = await fetch(`${serverAddress}/user-login/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email, password}),
            credentials: 'include' 

        });
        const parsedResponse = await response.json();
        if (!response.ok) { // Handle error
            console.error("Login failed");
            return parsedResponse;
        }
        console.log("Login successful");

        return parsedResponse;
    } catch (error) {
        console.error("Network error during login", error);
        return 'failed';
    }
}

export async function passwordResetRequest(email){
    try {
        const response = await fetch(`${serverAddress}/password-reset-request/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email})
        });
        if (!response.ok) { // Handle error
            console.error("Password reset request failed");
            const message = await response.json();
            return message;
        }
        const message = await response.json();
        return message.message
    } catch (error) {
        console.error("Network error during password reset request", error);
        return error.message;
    }
}

export async function passwordResetConfirmation(userEmail, token){
    try {
        const response = await fetch(`${serverAddress}/password-reset-confirmation/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({userEmail, token})
        });
        if (!response.ok) { // Handle error
            console.error("Password reset confirmation failed");
            const message = await response.json();
            return message;
        }
        const message = await response.json();
        return message.message
    } catch (error) {
        console.error("Network error during password reset confirmation", error);
        return error.message;
    }
}

export async function passwordChange(email, newPassword, currentPassword){
    try {
        const response = await fetch(`${serverAddress}/password-change/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email, newPassword, currentPassword})
        });
        if (!response.ok) { // Handle error
            console.error("Password change failed");
            const message = await response.json();
            console.log('message', message.message);
            return message.message;
        }
        const message = await response.json();  
        return message.message;
    } catch (error) {
        console.error("Network error during password change", error);
        return 'failed';
    }
}

export async function getUserServiceOrders(){
    try {
        const response = await fetch(`${serverAddress}/get-service-orders`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        if (!response.ok) { // Handle error
            console.error("Get User Service Orders failed");
            return {serviceOrders:[]};
        }
        const data = await response.json();

        return data;
    } catch (error) {
        console.error("Network error during get User Service Orders", error);
        return [];
    }
}

export async function deleteServiceOrderInDB(serviceOrderId){
    try{
        const response = await fetch(`${serverAddress}/delete-serviceOrder/${serviceOrderId}`,{
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if(response.ok){
            const data = await response.json();
            return data;
        } else {
            const errorDetails = await response.json();
            console.log('deleteServiceOrderInDB not ok with status', errorDetails)
        }
    } catch (error){
        console.error('Error on deleteServiceOrderInDB', error);
    }
}

export async function loadServiceOrderFromDB(serviceOrderId){
    try{
        const response = await fetch(`${serverAddress}/load-service-order/${serviceOrderId}`,{
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })

        if(response.ok){
            const data = await response.json();
            return {...data};
        } else {
            console.log('loadServiceOrderFromDB not ok with status', response)
            throw new Error('Unable to Load Service Order');
        }
    } catch (error){
        console.error('Error on loadServiceOrderFromDB', error);
        throw new Error('Unable to Load Service Order');
    }
}

export async function runServiceOrder(currentServiceOrder){
    try{
        const response = await fetch(`${serverAddress}/run-service-order`,{
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({currentServiceOrder})
        })
        if(response.ok){
            const data = await response.json();
            return data;
        } else {
            console.log('runScenarioOnServer not ok with status', response)
            const data = await response.json();
            return data;
        }
    } catch (error){
        console.error('Error on runScenarioOnServer', error);
        return 'Unable to Run Scenario';
    }
}

export async function getUsersList(){
    try{
        const response = await fetch(`${serverAddress}/get-users-list`,{
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if(response.ok){
            const data = await response.json();
            return data;
        } else {
            console.log('getUsersList not ok with status', response)
            return 'failed';
        }
    } catch (error){
        console.error('Error on getUsersList', error);
        return 'failed';
    }
}

export async function deleteUserInDB(userEmail){ 
    try{
        const response = await fetch(`${serverAddress}/delete-user/${userEmail}`,{
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if(response.ok){
            
            return 'User deleted successfully';
        } else {
            console.log('deleteUserInDB not ok with status', response)
            return 'failed';
        }
    } catch (error){
        console.error('Error on deleteUserInDB', error);
        return 'failed';
    }
}

export async function changeUserStatus(userEmail, newStatus){
    try{
        const response = await fetch(`${serverAddress}/change-user-status/`,{
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({newStatus, userEmail})
        })
        if(response.ok){
            return 'success';
        } else {
            console.log('changeUserStatus not ok with status', response)
            return 'failed';
        }
    } catch (error){
        console.error('Error on changeUserStatus', error);
        return 'failed';
    }
}

export async function createUserInDB(newUser){
    try{
        const response = await fetch(`${serverAddress}/create-user/`,{
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newUser)
        })

        if(response.ok){
            return 'success';
        } else {
            console.log('createUserInDB not ok with status', response)
            const parsedResponse = await response.json();
            return parsedResponse.message;
        }
    } catch (error){
        console.error('Error on createUserInDB', error);
        return error;
    }
}

export async function saveServiceOrder(serviceOrder){
    try{
        const response = await fetch(`${serverAddress}/save-service-order`,{
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({serviceOrder})
        })
        const data = await response.json();
        if(response.ok){
            return data;
        } else {

            console.log('saveServiceOrder not ok with status', response)
            return data.message;
        }
    } catch (error){
        console.error('Error on saveServiceOrder', error);
        return error.message;
    }
}

export async function getAddress(latitude, longitude){
    try{
        const response = await fetch(`${serverAddress}/get-address/${latitude}/${longitude}`,{
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if(response.ok){
            const data = await response.json();
            return data;
        } else {
            console.log('getAddress not ok with status', response)
            return 'No address found';
        }
    } catch (error){
        console.error('Error on getAddress', error);
        return 'No address found';
    }
}

export async function updateWhatsappInfo(serviceOrderId, affiliateId, validWhatsapp){
    console.log('updateWhatsappInfo', serviceOrderId, affiliateId, validWhatsapp)
    try{
        const response = await fetch(`${serverAddress}/update-whatsapp-info`,{
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({serviceOrderId, affiliateId, validWhatsapp})
        })


        if(response.ok){
            const data = await response.json();
            return data;
        } else {
            const errorMessage = await response.text();
            console.log('updateWhatsappInfo not ok with status', errorMessage)
            return {message: errorMessage};
        }
    } catch (error){
        console.error('Error on updateWhatsappInfo', error);
        return error;
    }
}

export async function runTests(firstScenario, numberOfScenarios){
    try{
        const response = await fetch(`${serverAddress}/run-tests`,{
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({firstScenario, numberOfScenarios})
        })
        const data = await response.json();

        if(response.ok){
            return data.message
        } else {
            console.log('runTests not ok with status', response)
            return data.message;
        }
    } catch (error){
        console.error('Error on runTests', error);
        return 'failed';
    }
}

export async function getExchangeRateUSD(toCurrency, date){
    try{
        const response = await fetch(`${serverAddress}/get-exchange-rate-usd/${toCurrency}/${date}`,{
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if(response.ok){
            const data = await response.json();
            return data;
        } else {
            console.log('getExchangeRate not ok with status', response)
            return '';	
        }
    } catch (error){
        console.error('Error on getExchangeRate', error);
        return '';
    }
}

export async function returnOSToWA(serviceOrderId){
    console.log('returnOSToWA', serviceOrderId)
    try{
        const response = await fetch(`${serverAddress}/return-os-to-wa/${serviceOrderId}`,{
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if(response.ok){
            const data = await response.json();
            return data.serviceOrder;
        } else {
            console.log('returnOSToWA not ok with status', response)
            return 'fail';
        }
    } catch (error){
        console.error('Error on returnOSToWA', error);
        return 'fail';
    }
}

export async function getProvidersList(){
    try{
        const response = await fetch(`${serverAddress}/get-providers-list`,{
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if(response.ok){
            const data = await response.json();
            return data;
        } else {
            console.log('getProvidersList not ok with status', response)
            return 'failed';
        }
    } catch (error){
        console.error('Error on getProvidersList', error);
        return 'failed';
    }
}

export async function deleteProviderInDB(affiliateId, providerName){ 
    try{
        const response = await fetch(`${serverAddress}/delete-provider/${affiliateId}/${providerName}`,{
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if(response.ok){
            
            return 'Provider deleted successfully';
        } else {
            console.log('deleteProviderInDB not ok with status', response)
            return 'failed';
        }
    } catch (error){
        console.error('Error on deleteProviderInDB', error);
        return 'failed';
    }
}

export async function createProviderInDB(newProvider){
    try{
        const response = await fetch(`${serverAddress}/create-provider/`,{
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newProvider)
        })

        const parsedResponse = await response.json();
        console.log('parsedResponse', parsedResponse)
        if(response.ok){
            return parsedResponse;
        } else {
            console.log('createProviderInDB not ok with status', response)
            return parsedResponse.message;
        }
    } catch (error){
        console.error('Error on createProviderInDB', error);
        return error;
    }
}

export async function updateProviderInDB(newProvider){
    try{
        const response = await fetch(`${serverAddress}/update-provider/`,{
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newProvider)
        })

        const parsedResponse = await response.json();
        console.log('parsedResponse', parsedResponse)
        if(response.ok){
            return parsedResponse
        } else {
            console.log('updateProviderInDB not ok with status', response)

            return parsedResponse.message;
        }
    } catch (error){
        console.error('Error on updateProviderInDB', error);
        return error;
    }
}

export async function getProvidersReport(){
    try{
        const response = await fetch(`${serverAddress}/get-providers-report`,{
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if(response.ok){
            const data = await response.json();
            return data;
        } else {
            console.log('getProvidersReport not ok with status', response)
            return 'failed';
        }
    } catch (error){
        console.error('Error on getProvidersReport', error);
        return 'failed';
    }
}