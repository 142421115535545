import React, {useContext} from 'react';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import {SocketContext} from 'Contexts/SocketContext.js';
import {loadServiceOrderFromDB} from 'Contexts/ServerCalls/serverCalls.js';
import moment from 'moment-timezone';
moment.locale('pt');
import {timeZone} from 'Contexts/static_options/Classes.js'
import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js';

export default function LoadServiceOrderButton({serviceOrder}){
    const {currentServiceOrder, setCurrentServiceOrder, user, setLoading} = useContext(QuoteMasterContext);
    

    const serviceOrderLabel = () => {
        let firstLine = serviceOrder.serviceOrderId;
        if(serviceOrder.user){
            firstLine = user.userType === 'agent' ? 
            serviceOrder.serviceOrderId : `${serviceOrder.serviceOrderId} - ${serviceOrder.user.firstName} ${serviceOrder.user.lastName}`;
        }
        const dateLine = (timeLastUpdate) => {
            const today = moment().tz(timeZone).startOf('day');
            const diff = today.diff(moment(timeLastUpdate).tz(timeZone), 'days');
            if (diff === 0) {
                // If date is today, show the time
                return moment(timeLastUpdate).format('HH:mm');
            } else if (diff === 1) {
                // If date is yesterday, show 'Yesterday'
                return 'Ontem';
            } else if (diff > 1 && diff <= 7) {
                // If date is before yesterday and up to 7 days ago, show the day of the week
                return moment(timeLastUpdate).tz(timeZone).format('dddd');
            } else if (diff > 7) {
                // If date is more than 7 days ago, show the date
                return moment(timeLastUpdate).tz(timeZone).format('DD/MM/YYYY');
            }
        }
        const label = user.userType === 'agent' ? 
            <>{firstLine} - {dateLine(serviceOrder.timeLastUpdate)}</> :
            <>{firstLine}<br/>{dateLine(serviceOrder.timeLastUpdate)}</>;
        return (
            <>{label}</>
        )
    }

    
    const loadServiceOrder = async (serviceOrderId) => {
        console.log('loading service order:', serviceOrderId);
        setLoading(true)
        try{
            const newServiceOrder = await loadServiceOrderFromDB(serviceOrderId);
            console.log('newServiceOrder:', newServiceOrder);
            setCurrentServiceOrder(newServiceOrder);
        }catch(error){
            console.error('Error in loading service order from DB:', error);
        }
        setLoading(false);
    }    

    return(
        <GlobalButton 
            onClick={()=>loadServiceOrder(serviceOrder.serviceOrderId)}
            label={serviceOrderLabel()}
            type={currentServiceOrder.serviceOrderId === serviceOrder.serviceOrderId ? 'selected': 'secondary'}
            width={'85%'}
            uppercase={false}
        />
    )
}