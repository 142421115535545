import React, { useEffect, useState, useRef, useContext } from 'react';

import {convertISODateToReadableString} from 'Contexts/Utils/formatTime.js';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import styles from './ServiceOrderStatusDisplay.module.css';
import TimeToGo from '../TimeToGo/TimeToGo.js';
import { closeReasons, fallbackToHumanReasons } from '../../../../static_options/static_options.js';


export default function ServiceOrderStatusDisplay({}){

    const {currentServiceOrder} = useContext(QuoteMasterContext);
    
    return(
        <div className={styles.serviceOrderStatusDisplay}>
            <StageStartTime/>
            {currentServiceOrder.currentStage!=='closed' && currentServiceOrder.currentStage!=='fallbackToHuman' && currentServiceOrder.currentStage!=='notInitiated' && <TimeToGo type={'stage'} /> }
            {currentServiceOrder.currentStage!=='closed' && currentServiceOrder.currentStage!=='fallbackToHuman' && currentServiceOrder.currentStage!=='notInitiated' && <TimeToGo type={'fallBackToHuman'} /> }
            {(currentServiceOrder.currentStage==='closed' || currentServiceOrder.currentStage==='fallbackToHuman') && <CloseReason />} 
        </div>
    )
}

function StageStartTime({ }){
    const {currentServiceOrder} = useContext(QuoteMasterContext);
    const [startTimeTitle, setStartTimeTitle] = useState('');
    const readableTime = useRef('');

    useEffect(() => {
        switch(currentServiceOrder.currentStage){
            case 'notInitiated':
                setStartTimeTitle('Cotação não Iniciada');
                break;
            case 'closed':
                setStartTimeTitle('Encerrada em ');
                break;
            case 'fallbackToHuman':
                setStartTimeTitle('Enviada para Atendimento Humano em ');
                break;
            default:
                setStartTimeTitle(`Estágio ${currentServiceOrder.currentStage} iniciado às `);
                break;
        }

        const time = currentServiceOrder.stageStartTime.find(stage=>stage.stage===currentServiceOrder.currentStage).time;
        if(time){
            readableTime.current = convertISODateToReadableString(time, false);
        }

    }, [currentServiceOrder.currentStage]);
    
    return(
        <>
            {currentServiceOrder.currentStage !== 'notInitiated' ?
                <div>
                    {startTimeTitle} {readableTime.current}
                </div> : 'Cotação não Iniciada'
            }
        </>
    )
}


function CloseReason(){
    const {currentServiceOrder} = useContext(QuoteMasterContext);
    const [closeReason, setCloseReason] = useState('');
    
    useEffect(() => {

        if(currentServiceOrder.currentStage==='closed'){
            setCloseReason(closeReasons.find(reason => reason.reasonCode === currentServiceOrder.closeReason)?.description || 'Sem razão');
        } else if(currentServiceOrder.currentStage==='fallbackToHuman'){

            setCloseReason(fallbackToHumanReasons.find(reason => reason.reasonCode === currentServiceOrder.closeReason)?.description || 'Sem razão');
        }
     
    }, [currentServiceOrder.closeReason])

    return(
        <div>Razão: {closeReason}</div>
    )
}