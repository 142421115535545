// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NizJpFZUrvRx4UuNsjUC{
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 15px;
    padding: 0px 8px;
    margin: 0px;
    height: 32px;
    border-bottom: 1px solid var(--border-dark);
    background-color: var(--background-dark);
}`, "",{"version":3,"sources":["webpack://./clientRoot/Static/GlobalComponents/LabelElement/LabelElement.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,2CAA2C;IAC3C,wCAAwC;AAC5C","sourcesContent":[".labels{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    box-sizing: border-box;\n    font-size: 15px;\n    line-height: 15px;\n    padding: 0px 8px;\n    margin: 0px;\n    height: 32px;\n    border-bottom: 1px solid var(--border-dark);\n    background-color: var(--background-dark);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labels": `NizJpFZUrvRx4UuNsjUC`
};
export default ___CSS_LOADER_EXPORT___;
