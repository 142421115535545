// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KCeSVMRj4KDDARJWOl_D{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
    padding: 0px 8px;
    font-size: 15px;
    line-height: 15px;
    padding: 0px 8px;
    background: none;
    font-family: var(--quotemaster-font-family);
    display: flex;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./clientRoot/Static/GlobalComponents/HyperlinkInput/Hyperlink.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,2CAA2C;IAC3C,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".textInput{\n    width: 100%;\n    height: 100%;\n    box-sizing: border-box;\n    border: none;\n    padding: 0px 8px;\n    font-size: 15px;\n    line-height: 15px;\n    padding: 0px 8px;\n    background: none;\n    font-family: var(--quotemaster-font-family);\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textInput": `KCeSVMRj4KDDARJWOl_D`
};
export default ___CSS_LOADER_EXPORT___;
