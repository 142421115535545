import React from 'react';
import CarGlassLogo from './Carglass-Logo.png';


export default function CarGlassLogoDisplay({dimension = '24px', color = 'white'}) {
    const source = color === 'white' ? CarGlassLogo : CarGlassLogo ;
    return (
        <img src={source} alt="Carglass Icon" style={{widht:dimension, height: dimension}} />
  );    
}

