
import React, {useContext, useState, useRef,useEffect } from 'react'

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import SelectionBox from 'GlobalComponents/SelectionBox/SelectionBox';
import {selectionItems} from './brands.js';

export default function BrandSelect({providerIndex, itemQuote, readOnly, itemQuoteIndex}) {
    const {setCurrentServiceOrder, currentServiceOrder} = useContext(QuoteMasterContext);
    const [brandName, setBrandName] = useState(itemQuote.brand.brandName);
    const selection = selectionItems(currentServiceOrder.items[itemQuote.itemIndex].itemGroup);
    const firstLoadCompleted = useRef(false);

    useEffect(() => {
        if(firstLoadCompleted.current){ // if the page is just loading for the first time
            const selectionBrand = selection.find(brand => brand.value === brandName);
            const brand = {
                brandName: selectionBrand.value,
                tier: selectionBrand.tier
            }
            const newServiceOrder = {...currentServiceOrder};
            const newProvider = newServiceOrder.providers[providerIndex];
            itemQuote.brand = brand;

            const tiers = newProvider.itemQuotes.map(itemQuote => itemQuote.brand.tier);
            // find maximum tier
            const quoteTier = tiers.reduce((max, current) => Math.max(max, current), 0);
            newProvider.providerBestQuote.brand.tier = quoteTier;
            setCurrentServiceOrder(newServiceOrder);
        } else {
            firstLoadCompleted.current = true;
        }
    }, [brandName]);

    useEffect(() => { 
        setBrandName(currentServiceOrder.providers[providerIndex].itemQuotes[itemQuoteIndex].brand.brandName);
    }, [currentServiceOrder.providers[providerIndex].itemQuotes[itemQuoteIndex].brand.brandName]);

    return (
        <SelectionBox 
            selectionItems={selection} 
            mainFunction={setBrandName} 
            mainValue={brandName} 
            instanceName={'Marca'}
            readOnly={readOnly}
        />
    );
}
