import React, {useContext} from 'react';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js';
import {Quote} from 'Contexts/static_options/Classes.js'


export default function NewSOSameParametersButton(){
    const {currentServiceOrder, setCurrentServiceOrder, user, initialTarget} = useContext(QuoteMasterContext);
    const newSOSameParameters = () => {
        currentServiceOrder.providers.forEach(provider => {
            if(provider.quoteViaLink){
                console.log('building quoteViaLink')
                provider.itemQuotes = provider.itemQuotes.filter(itemQuote => itemQuote.stage===1)
                console.log('provider.itemQuotes after first filter:', provider.itemQuotes)
                // remove repeated items
                const itemIndexes = provider.itemQuotes.map(itemQuote => itemQuote.itemIndex)
                console.log('itemIndexes:', itemIndexes)
                const uniqueItemIndexes = [...new Set(itemIndexes)]
                console.log('uniqueItemIndexes:', uniqueItemIndexes)
                // get the first itemQuote for each item
                let itemQuotes = []
                uniqueItemIndexes.forEach(itemIndex => {
                    const firstItemQuote = provider.itemQuotes.find(itemQuote => itemQuote.itemIndex === itemIndex)
                    console.log('firstItemQuote:', firstItemQuote)
                    firstItemQuote.completed = Boolean(firstItemQuote.price && firstItemQuote.shortestAvailability.date && firstItemQuote.shortestAvailability.availability && firstItemQuote.brand.brandName);
                    itemQuotes.push(firstItemQuote)
                })
                provider.itemQuotes = itemQuotes;
                console.log('provider.itemQuotes:', provider.itemQuotes)
                provider.providerBestQuote = {
                    price: provider.itemQuotes.reduce((acc, itemQuote) => itemQuote.price+acc, 0), // this is the sum of the prices of the items
                    rate: '',
                    shortestAvailability: provider.itemQuotes.reduce((acc, itemQuote) => (itemQuote.shortestAvailability.relativeDays > acc.relativeDays) ? itemQuote.shortestAvailability : acc, provider.itemQuotes[0].shortestAvailability),
                    brand: provider.itemQuotes.reduce((acc, itemQuote) => itemQuote.brand.tier > acc.tier ? {tier:itemQuote.brand.tier} : acc, {tier: 0}),
                    itemQuotesIndexes: provider.itemQuotes.map(itemQuote => itemQuote.itemIndex),
                    completed : provider.itemQuotes.reduce((acc, itemQuote) => itemQuote.completed && acc, true),
                    quoteReceivedAtTime : provider.providerBestQuote.quoteReceivedAtTime,
                    mobileService: provider.providerBestQuote.mobileService,
                    bookingTime: provider.providerBestQuote.bookingTime,
                    affiliateId: provider.affiliateId,
                    dateOptions: provider.providerBestQuote.dateOptions
                }
            } else{
                provider.itemQuotes = []
                provider.providerBestQuote = new Quote();
            }
            provider.conversation = [];
            provider.reasignedBrands= []; // this is a list of brands to help in the conversation. The conversation goes in a way, but we need to use specific names for the brands
            provider.timeOflastMessageSentToProvider= null; // this is the time for the next follow up
            provider.counterOfferSent= false;
            provider.waitingResponse= false;
            provider.reasonsForProviderToNotBeConsidered= [];
            provider.whatsappSource = {formattedSource: {areaCode: '', number: '', countryCode: ''}, unformattedSource: ''};
            provider.validWhatsapp= true;
            provider.responsibles = {onCall: '', historic: []};
            provider.fixPhoneNumberAttempts = 0;
        });       
        setCurrentServiceOrder({
            ...currentServiceOrder, 
            currentStage: 'notInitiated', 
            previousStage: '', // this is the last stage of the negotiation
            serviceOrderId: '',
            currentTarget: {...initialTarget, price: currentServiceOrder.currentTarget.price, rate: currentServiceOrder.currentTarget.rate},
            user: user,
            timeLastUpdate: '',
            closeReason: '',
            stageStartTime: [{stage: 'notInitiated', time: ''},{stage: 1, time: ''}, {stage: 2, time: ''}, {stage: 3, time: ''}, {stage: 'confirmToClose', time: ''}, {stage: 'confirmFifoAndTime', time: ''}, {stage: 'confirmDates', time: ''}, {stage: 'fallbackToHuman', time: ''}, {stage: 'closed', time: ''}], // this is the time when each stage started
            tokens: [],
            notes: '',
            providersNotConsidered: [],
        });
    }

    return(
        <GlobalButton 
        onClick={()=>newSOSameParameters()}
        label={'Nova Ordem de Serviço - mesmos parâmetros'}
        type={'primary'}
        width={'100%'}
    />
    )
}