// FoldersToFileContext.js
import React, { createContext, useState, useEffect, useRef, useContext} from 'react';
import io from 'socket.io-client';
import {serverAddressPreAddress} from './ServerCalls/serverCalls.js';
import {QuoteMasterContext} from './QuoteMasterContext.js';

export const SocketContext = createContext();

export const SocketContextProvider = ({children}) => {
    const {currentServiceOrder, setCurrentServiceOrder, serviceOrders, setServiceOrders, user} = useContext(QuoteMasterContext);
    const [socket, setSocket] = useState(null);
    const socketShouldReconnect = useRef(false);
    const socketCallBack = useRef(null);
    const currentServiceOrderRef = useRef(currentServiceOrder);
    const serviceOrdersRef = useRef(serviceOrders);  
   
    function connectSocket(){
        console.log('Connecting to socket on address', serverAddressPreAddress);
        const newSocket = io(serverAddressPreAddress, {
            withCredentials: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 3000,
        });
        setSocket(newSocket);
        socketShouldReconnect.current = true;
        console.log('Connecting to server');
    }

    function socketEmitter(event, data){
        if(socket && socket.connected){
            if(user.userType !== 'agent'){ // join the admin room
                socket.emit('joinRoom', {room: 'admins'});
            }
            socket.emit(event, data);
        } else {
                socketCallBack.current = () => {
                    if(user.userType !== 'agent'){ // join the admin room
                        socket.emit('joinRoom', {room: 'admins'});
                    }
                    socket.emit(event, data);
                }
                socketShouldReconnect.current = true;
                socket.connect();
        }
    }

    useEffect(() => {
        if(user.userEmail){
            connectSocket();
        }
    }, [user]);

    useEffect(() => {
        if(socket){
            socket.connect();
            socket.on('connect_error', (err) => {
                console.error('Connection failed', err);
            });
        
            socket.on('connect', () => {
                console.log('Connected to server');
                if(socketCallBack.current){
                    socketCallBack.current();
                    socketCallBack.current = null;
                }
                if(user.userType !== 'agent'){ // join the admin room
                    socket.emit('joinRoom', {room: 'admins'});
                }
            });

            socket.on('errorResponse', (error) => {
                console.error('Error received from server', error);
                alert('Error received from server: ' + error.message)
            })

            
            socket.on('error', (err) => {
                console.error('Error received', err);
            });
        
            socket.on('disconnect', (reason) => {
                console.log('Disconnected from server', reason);
                if(reason === 'io server disconnect' && socketShouldReconnect.current){
                    console.log('Reconnecting to server');
                    socket.connect();
                };
            });

            socket.on('newServiceOrder', (serviceOrder) => {
                if(serviceOrdersRef.current.find(serviceOrderAux => serviceOrderAux.serviceOrderId === serviceOrder.serviceOrderId)){
                    console.log('Service order already exists', serviceOrder);
                    return;
                }
                console.log('Received new service order', serviceOrder);
                const newServiceOrders = [...serviceOrdersRef.current];
                newServiceOrders.push(serviceOrder);
                setServiceOrders(newServiceOrders);
            });

            socket.on('broadcastConversation', ({ serviceOrderId, conversation, affiliateId, counterOfferSent, timeOflastMessageSentToProvider, serviceOrderTokens, waitingResponse}) => {
                console.log('Received conversation update', serviceOrderId, conversation, affiliateId, counterOfferSent, timeOflastMessageSentToProvider, serviceOrderTokens, waitingResponse);
                if (currentServiceOrderRef.current.serviceOrderId === serviceOrderId) {
                    const newCurrentServiceOrder = {...currentServiceOrderRef.current};
                    const newProvider = {...newCurrentServiceOrder.providers.find(provider => provider.affiliateId === affiliateId)};	
                    newProvider.counterOfferSent = counterOfferSent;
                    newProvider.conversation = conversation;
                    newProvider.timeOflastMessageSentToProvider = timeOflastMessageSentToProvider;
                    newProvider.waitingResponse = waitingResponse;
                    newCurrentServiceOrder.tokens = serviceOrderTokens ? serviceOrderTokens : newCurrentServiceOrder.tokens;
                    const newProviders = newCurrentServiceOrder.providers.map(provider => provider.affiliateId === affiliateId ? newProvider : provider);
                    newCurrentServiceOrder.providers = newProviders;
                    currentServiceOrderRef.current = newCurrentServiceOrder;
                    setCurrentServiceOrder(newCurrentServiceOrder);
                } else {
                    // we need to update the service order in the list
                    const newServiceOrders = [...serviceOrdersRef.current];
                    const currentServiceOrderAux = newServiceOrders.find(serviceOrderAux => serviceOrderAux.serviceOrderId === serviceOrderId);
                    if(!currentServiceOrderAux){
                        return;
                    }
                    updateServiceOrdersList(currentServiceOrderAux)
                }
            });
           

            socket.on('updateQuote', ({ provider, serviceOrder}) => {
                console.log('Received updateQuote', serviceOrder.serviceOrderId, provider);
                if (currentServiceOrderRef.current.serviceOrderId === serviceOrder.serviceOrderId) {
                    const oldCurrentServiceOrder = {...currentServiceOrderRef.current};
                    const oldProviders = oldCurrentServiceOrder.providers;
                    const index = oldProviders.findIndex(providerHere => providerHere.providerName === provider.providerName);
                    if(index !== -1){
                        provider.inputText = oldProviders[index].inputText;
                        oldProviders[index] = provider;
                        serviceOrder.providers = oldProviders;
                        currentServiceOrderRef.current = serviceOrder;
                        setCurrentServiceOrder(serviceOrder);
                        updateServiceOrdersList(serviceOrder)
                    } else {
                        alert('Provider not found - raise a ticket with IT');
                    }
                } else {
                    // we need to update the service order in the list
                    updateServiceOrdersList(serviceOrder)
                }
            });

            // updateServiceOrder is used when a provider is included in the service order by a message of an existing provider
            // ATTENTION: it could also be used to update new stages since changeStage is not being called anywhere in the server
            socket.on('updateServiceOrder', (serviceOrder) => {
                console.log('updateServiceOrder', serviceOrder);
                if(currentServiceOrderRef.current.serviceOrderId !== serviceOrder.serviceOrderId){
                    updateServiceOrdersList(serviceOrder)
                    return;
                }
                const oldProviders = currentServiceOrderRef.current.providers;
                const newProviders = serviceOrder.providers;
                newProviders.forEach(provider => {
                    const index = oldProviders.findIndex(providerHere => providerHere.providerName === provider.providerName);
                    if(index !== -1){
                        provider.inputText = oldProviders[index].inputText;
                    } else {
                        console.error('Provider not found in updateServiceOrder', provider.providerName);
                    }
                });
                currentServiceOrderRef.current = serviceOrder;
                setCurrentServiceOrder(serviceOrder);
                updateServiceOrdersList(serviceOrder)        
            });



            socket.on('deleteServiceOrder', (serviceOrder) => {
                console.log('deleteServiceOrder', serviceOrder);
                const newServiceOrders = serviceOrdersRef.current.filter(serviceOrderAux => serviceOrderAux.serviceOrderId !== serviceOrder.serviceOrderId);
                setServiceOrders(newServiceOrders);
            });
        }
    }, [socket]);



    function updateServiceOrdersList(serviceOrder){
        const newServiceOrders = [...serviceOrdersRef.current];
        const currentServiceOrderAux = newServiceOrders.find(serviceOrderAux => serviceOrder.serviceOrderId === serviceOrderAux.serviceOrderId);
        if(!currentServiceOrderAux){
            return;
        }
        currentServiceOrderAux.currentStage = serviceOrder.currentStage;
        currentServiceOrderAux.timeLastUpdate = serviceOrder.timeLastUpdate;
        if(currentServiceOrderAux.serviceOrderId !== currentServiceOrderRef.current.serviceOrderId){
            currentServiceOrderAux.newInfo = true;
        }
        setServiceOrders(newServiceOrders);
    }

    useEffect(() => {
        currentServiceOrderRef.current = currentServiceOrder;
    }, [currentServiceOrder]);

    useEffect(() => {
        serviceOrdersRef.current = serviceOrders;
    }, [serviceOrders]);


    const contextValue = {
        connectSocket,
        socketShouldReconnect,
        socketEmitter,socketCallBack,
        socket,
    };
        
    return (
        <SocketContext.Provider value={contextValue}>
            {children}
        </SocketContext.Provider>
    );
};

