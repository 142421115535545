// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.X5PxiFpUhqn8WO5Xmjzo, .sbALbgKBlVVk0bHQxq2U, .HXeUuxO54TXho7YrXn53{
    border-radius: 16px;
    font-size: 12px;
    line-height: 12px;
    font-family: var(--quotemaster-font-family);
}

.X5PxiFpUhqn8WO5Xmjzo{
    color: var(--letter-light);
    background-color: var(--background-dark);
}

.X5PxiFpUhqn8WO5Xmjzo:hover{
    color: var(--letter-light);
    background-color: var(--background-dark);
}

.sbALbgKBlVVk0bHQxq2U{
    color: var(--letter-dark);
    background-color: var(--background-regular);
}

.sbALbgKBlVVk0bHQxq2U:hover{
    color: var(--letter-dark);
    background-color: var(--background-hovered-button);

}

.HXeUuxO54TXho7YrXn53{
    color: var(--letter-selected-button);
    background-color: var(--background-selected-button);
    border: none;
}

.HXeUuxO54TXho7YrXn53:hover{
    color: var(--letter-selected-button);
    background-color: var(--background-selected-button);
    border: none;
}`, "",{"version":3,"sources":["webpack://./clientRoot/Static/GlobalComponents/GlobalButton/GlobalButton.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,2CAA2C;AAC/C;;AAEA;IACI,0BAA0B;IAC1B,wCAAwC;AAC5C;;AAEA;IACI,0BAA0B;IAC1B,wCAAwC;AAC5C;;AAEA;IACI,yBAAyB;IACzB,2CAA2C;AAC/C;;AAEA;IACI,yBAAyB;IACzB,kDAAkD;;AAEtD;;AAEA;IACI,oCAAoC;IACpC,mDAAmD;IACnD,YAAY;AAChB;;AAEA;IACI,oCAAoC;IACpC,mDAAmD;IACnD,YAAY;AAChB","sourcesContent":[".primary, .secondary, .selected{\n    border-radius: 16px;\n    font-size: 12px;\n    line-height: 12px;\n    font-family: var(--quotemaster-font-family);\n}\n\n.primary{\n    color: var(--letter-light);\n    background-color: var(--background-dark);\n}\n\n.primary:hover{\n    color: var(--letter-light);\n    background-color: var(--background-dark);\n}\n\n.secondary{\n    color: var(--letter-dark);\n    background-color: var(--background-regular);\n}\n\n.secondary:hover{\n    color: var(--letter-dark);\n    background-color: var(--background-hovered-button);\n\n}\n\n.selected{\n    color: var(--letter-selected-button);\n    background-color: var(--background-selected-button);\n    border: none;\n}\n\n.selected:hover{\n    color: var(--letter-selected-button);\n    background-color: var(--background-selected-button);\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": `X5PxiFpUhqn8WO5Xmjzo`,
	"secondary": `sbALbgKBlVVk0bHQxq2U`,
	"selected": `HXeUuxO54TXho7YrXn53`
};
export default ___CSS_LOADER_EXPORT___;
