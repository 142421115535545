
import React, {useContext, useState, useRef,useEffect } from 'react'

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';   
import SelectionBox from 'GlobalComponents/SelectionBox/SelectionBox.js';

const selectionItems = [
    {value: '+55', label: '+55 - Brasil'},
]

export default function CountryCodeSelect({readOnly, instance, provider, width='100px'}) {
    const {setCurrentServiceOrder, currentServiceOrder, providersList, setProvidersList} = useContext(QuoteMasterContext);
    const [countryCode, setCountryCode] = useState(provider.phoneNumber.countryCode);
    const firstLoadCompleted = useRef(false);
    
    useEffect(() => {
        if(firstLoadCompleted.current && currentServiceOrder.currentStage === 'notInitiated'){ 
            if(instance==='providersList'|| instance==='chat'){
                    // if the page is just loading for the first time
                    const newCurrentServiceOrder = {...currentServiceOrder};
                    let providerIndex = currentServiceOrder.providers.findIndex((prov) => prov.providerName===provider.providerName);
                    const newProvider = newCurrentServiceOrder.providers[providerIndex];
                    newProvider.phoneNumber.countryCode = countryCode;
                    setCurrentServiceOrder(newCurrentServiceOrder);
            } else if(instance==='providersPanel'){
                const newProvidersList = [...providersList];
                let providerIndex = providersList.findIndex((prov) => prov.affiliateId===provider.affiliateId);
                const newProvider = newProvidersList[providerIndex];
                newProvider.phoneNumber.countryCode = countryCode;
                setProvidersList(newProvidersList);
            } else if(instance==='newProvider'){
                const newProvidersList = [...providersList];
                let providerIndex = providersList.length-1;
                const newProvider = newProvidersList[providerIndex];
                newProvider.phoneNumber.countryCode = countryCode;
                setProvidersList(newProvidersList);
            }
        } else {
            firstLoadCompleted.current = true;
        }

    }, [countryCode]);


    useEffect(() => {
        let providerIndex;
        switch(instance){
            case 'providersList':
            case 'chat':
                providerIndex = currentServiceOrder.providers.findIndex((prov) => prov.providerName===provider.providerName);
                setCountryCode(currentServiceOrder.providers[providerIndex].phoneNumber.countryCode);
                break;
            case 'providersPanel':
                providerIndex = providersList.findIndex((prov) => prov.affiliateId===provider.affiliateId);
                setCountryCode(providersList[providerIndex].phoneNumber.countryCode);
                break;
            case 'newProvider':
                providerIndex = providersList.length-1;
                setCountryCode(providersList[providerIndex].phoneNumber.countryCode);
                break;
        }

    }, [currentServiceOrder.providers, providersList]);



    return (
        <SelectionBox 
            selectionItems={selectionItems} 
            mainFunction={setCountryCode} 
            mainValue={countryCode} 
            instanceName={'País'} 
            readOnly={readOnly}
            noWrap={false}
            width={width}
        />
    );
}


