// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.vDsKpbp0OZRVHdtOHm19{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 4px;
    margin-bottom: 4px;
}

.fhR0qZ8d0jIEIN0ApjE1{
    border-radius: 50%;
}

.dBTKaQh4emX7oNQxJDbp{
    font-size: 16px;
    color: var(--letter-dark);
}
`, "",{"version":3,"sources":["webpack://./clientRoot/Static/PageHeader/PageHeader.module.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,sBAAsB;IACtB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":["\n.pageHeader{\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    box-sizing: border-box;\n    margin-top: 4px;\n    margin-bottom: 4px;\n}\n\n.avatar{\n    border-radius: 50%;\n}\n\n.pageHeaderTitle{\n    font-size: 16px;\n    color: var(--letter-dark);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": `vDsKpbp0OZRVHdtOHm19`,
	"avatar": `fhR0qZ8d0jIEIN0ApjE1`,
	"pageHeaderTitle": `dBTKaQh4emX7oNQxJDbp`
};
export default ___CSS_LOADER_EXPORT___;
