// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XF4pzJn69stPONObXgLB{
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px 8px;
    border: none;
    font-size: 14px;
    align-items: center;
} 
`, "",{"version":3,"sources":["webpack://./clientRoot/Static/GlobalComponents/SwitchOption/SwitchOption.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".linkBox{\n    display: flex;\n    flex-direction: row;\n    box-sizing: border-box;\n    justify-content: center;\n    height: 100%;\n    width: 100%;\n    margin: 0px;\n    padding: 0px 8px;\n    border: none;\n    font-size: 14px;\n    align-items: center;\n} \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkBox": `XF4pzJn69stPONObXgLB`
};
export default ___CSS_LOADER_EXPORT___;
