const timeZone = 'America/Sao_Paulo';

const stages = [
    'notInitiated',
    1,
    2,
    3,
    'closed',
    'fallbackToHuman'
];

const selectionItems = [
    {value: 'glasses', label: 'Vidros', WA_name: '01-VIDROS'},
    {value: 'accessories', label: 'Acessórios', WA_name: '04-ACESSÓRIOS'},
    {value: 'services', label:'Serviços', WA_name: 'REPARO RÁPIDO'},
    {value: 'services', label:'Serviços', WA_name: 'REPAROS'},
    {value: 'services', label:'Serviços', WA_name: 'SUPERMARTELINHO'},
    {value: 'services', label:'Serviços', WA_name: 'UNDER CAR'},
    {value: 'services', label:'Serviços', WA_name: 'MOTOR E CAMBIO'},
]

const reasonForProviderNotBeConsidered = [
    'noPhoneNumber',
    'noWhatsAppNumber',
    'invalidPhoneNumber',
    'noProviderName',
    'noAffiliateId'
]

const freshTokensObject = {input: 0, output: 0};
const desiredAvailability = 7; // in days

export { freshTokensObject, stages, selectionItems, desiredAvailability, timeZone, reasonForProviderNotBeConsidered }
