// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guvFBQ2FIo2CGm5utdWM{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid var(--border-dark);
    border-radius: 16px;
    background-color: transparent;
    padding: 0 16px;
    position: relative;
}

.zF3obhRD7RFMiVhjWoN3 {
    position: absolute;
    left: 10px; /* Adjust spacing as needed */
    color: var(--icon-color); /* Define this variable in your CSS or replace it with a color */
}

.Kri6FLxVWGm3Xahg3FIt {
    width: 100%;
    border: none;
    background: none;
    padding-left: 24px; /* Adjust based on icon size */
    height: 100%;
    outline: none;
}
`, "",{"version":3,"sources":["webpack://./clientRoot/Static/GlobalComponents/SearchBar/SearchBar.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,oCAAoC;IACpC,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU,EAAE,6BAA6B;IACzC,wBAAwB,EAAE,gEAAgE;AAC9F;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,kBAAkB,EAAE,8BAA8B;IAClD,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".searchBar{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    box-sizing: border-box;\n    border: 1px solid var(--border-dark);\n    border-radius: 16px;\n    background-color: transparent;\n    padding: 0 16px;\n    position: relative;\n}\n\n.iconStyle {\n    position: absolute;\n    left: 10px; /* Adjust spacing as needed */\n    color: var(--icon-color); /* Define this variable in your CSS or replace it with a color */\n}\n\n.input {\n    width: 100%;\n    border: none;\n    background: none;\n    padding-left: 24px; /* Adjust based on icon size */\n    height: 100%;\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBar": `guvFBQ2FIo2CGm5utdWM`,
	"iconStyle": `zF3obhRD7RFMiVhjWoN3`,
	"input": `Kri6FLxVWGm3Xahg3FIt`
};
export default ___CSS_LOADER_EXPORT___;
