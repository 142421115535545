
export const LLMOptions = [
    {value: 'gpt-4o-2024-05-13', label: 'GPT-4o (Maio/24)' },
    {value: 'gpt-4o-mini-2024-07-18', label: 'GPT-4o Mini'},
    {value: 'gpt-4o-2024-08-06', label: 'GPT-4o (Aug/24)'},
    {value: 'gpt-4-0125-preview', label:'GPT 4.0 Turbo Preview'},
    {value: 'gpt-3.5-turbo-0125', label:'GPT 3.5 Turbo'},
    {value: 'claude-3-opus-20240229', label:'Claude Opus'},
    {value: 'claude-3-5-sonnet-20240620', label:'Claude 3.5 Sonnet'},
    {value: 'llama-3.2-90b-vision-instruct-maas', label:'Llama 3.2 90b'},
    {value: 'llama3-70b-instruct-maas', label:'Llama 3.1 70b'},
    {value: 'llama3-405b-instruct-maas', label:'Llama 3.1 405b'},
];


export const LLMCost = [
    {value: 'gpt-4o-2024-05-13', costs: [{startDate: '2024-10-15', endDate: 'current', costPerMMInput:5, costPerMMOutput:15}]},
    {value: 'gpt-4o-mini-2024-07-18', costs: [{startDate: '2024-10-15', endDate: 'current', costPerMMInput:0.15, costPerMMOutput:0.6}]},
    {value: 'gpt-4o-2024-08-06', costs: [{startDate: '2024-10-15', endDate: 'current', costPerMMInput:2.5, costPerMMOutput:10}]},
    {value: 'gpt-4-0125-preview', costs: [{startDate: '2024-10-15', endDate: 'current', costPerMMInput:10, costPerMMOutput:30}]},
    {value: 'gpt-3.5-turbo-0125', costs: [{startDate: '2024-10-15', endDate: 'current', costPerMMInput:0.5, costPerMMOutput:1.5}]},
    {value: 'claude-3-opus-20240229', costs: [{startDate: '2024-10-15', endDate: 'current', costPerMMInput:15, costPerMMOutput:75}]},
    {value: 'claude-3-5-sonnet-20240620', costs: [{startDate: '2024-10-15', endDate: 'current', costPerMMInput:3, costPerMMOutput:15}]},
    {value: 'llama-3.2-90b-vision-instruct-maas', costs: [{startDate: '2024-11-04', endDate: 'current', costPerMMInput:1 , costPerMMOutput:1}]},
    {value: 'llama3-70b-instruct-maas', costs: [{startDate: '2024-11-04', endDate: 'current', costPerMMInput:1 , costPerMMOutput:1}]},
    {value: 'llama3-405b-instruct-maas', costs: [{startDate: '2024-11-04', endDate: 'current', costPerMMInput:5 , costPerMMOutput:16}]},
];