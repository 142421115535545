import React, {useContext, useCallback } from 'react'

import styles from 'GlobalComponents/CommonStyles.module.css';
import LabelElement from 'GlobalComponents/LabelElement/LabelElement.js';
import { QuoteMasterContext } from 'Contexts/QuoteMasterContext.js';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput.js';
import NumberInput from 'GlobalComponents/NumberInput/NumberInput.js';

export default function TimeParameters({labelWidth, inputWidth}){
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);

    const changeTheTimeOf = useCallback((field) => (event) => {
        const value = isNaN(event.target.value) ? 0 : event.target.value;
        setCurrentServiceOrder((prevOrder) => ({
            ...prevOrder,
            [field]: value
        }));
    }, [currentServiceOrder]);

    return (
        <>
            <div className={styles.parametersColumn} >
                <div className={styles.parameterRow}>
                    <LabelElement width={labelWidth} label={'Tempo'} />
                    <LabelElement width={inputWidth} label={'Horas'} />
                </div>

                <div className={styles.parameterRow}>
                    <LabelElement width={labelWidth} label={'Máximo'} />
                    <DetailInput 
                            width={inputWidth}
                            readOnly={currentServiceOrder.currentStage!=='notInitiated'}
                            inputElement={
                            <NumberInput 
                                inputValue={currentServiceOrder.totalTime}
                                onChange={changeTheTimeOf('totalTime')}
                                readOnly={currentServiceOrder.currentStage!=='notInitiated'}
                                spin={true}
                                minValue={30}
                                maxValue={3600}
                                step={1}
                                preventingDefault={false}
                            />
                        }
                    />
                </div>
                <div className={styles.parameterRow}>
                    <LabelElement width={labelWidth} label={'Follow up'} />
                    <DetailInput 
                            width={inputWidth}
                            readOnly={currentServiceOrder.currentStage!=='notInitiated'}
                            inputElement={
                            <NumberInput 
                                inputValue={currentServiceOrder.followUpTime}
                                onChange={changeTheTimeOf('followUpTime')}
                                readOnly={currentServiceOrder.currentStage!=='notInitiated'}
                                spin={true}
                                minValue={30}
                                maxValue={3600}
                                step={1}
                                preventingDefault={false}
                            />
                        }
                    />
                </div>
                <div className={styles.parameterRow}>
                    <LabelElement width={labelWidth} label={'Estágios'} />
                    <DetailInput 
                            width={inputWidth}
                            readOnly={currentServiceOrder.currentStage!=='notInitiated'}
                            inputElement={
                            <NumberInput 
                                inputValue={currentServiceOrder.stageTime}
                                onChange={changeTheTimeOf('stageTime')}
                                readOnly={currentServiceOrder.currentStage!=='notInitiated'}
                                spin={true}
                                minValue={30}
                                maxValue={3600}
                                step={1}
                                preventingDefault={false}
                            />
                        }
                    />
                </div>
                {currentServiceOrder.runType !== 'regular' &&
                    <div className={styles.parameterRow}>
                        <LabelElement width={labelWidth} label={'Segundos/Hora'} />
                        <DetailInput 
                                width={inputWidth}
                                readOnly={currentServiceOrder.currentStage!=='notInitiated'}
                                inputElement={
                                <NumberInput 
                                    inputValue={currentServiceOrder.timeMultiplier}
                                    onChange={changeTheTimeOf('timeMultiplier')}
                                    readOnly={currentServiceOrder.currentStage!=='notInitiated'}
                                    spin={true}
                                    minValue={30}
                                    maxValue={3600}
                                    step={1}
                                    preventingDefault={false}
                                />
                            }
                        />
                    </div>
                }
            </div>
        </>
    )
}