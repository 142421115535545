
import React, { useEffect, useState, useContext, useCallback } from 'react';

import styles from './RunningServiceOrdersPanel.module.css';

import {getRunningServiceOrders} from '../ServerCalls/serverCalls.js';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';

import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js'
import WaitingProgressLine from 'GlobalComponents/WaitingProgressLine/WaitingProgressLine.js';

import RestorePageOutlinedIcon from '@mui/icons-material/RestorePageOutlined';

export default function RunningServiceOrdersPanel({setIsRunningServiceOrdersPanelOpen }) {

    const {loading, setLoading} = useContext(QuoteMasterContext);
    const [updateFilter, setUpdateFilter] = useState(false);
    const [createFilter, setCreateFilter] = useState(false);
    const [deleteFilter, setDeleteFilter] = useState(false);
    const [runningServiceOrders, setRunningServiceOrders] = useState([]);

    useEffect(() => {
        updateRunningServiceOrders();
    }, []);

    async function updateRunningServiceOrders() {
        setLoading(true);
        const response = await getRunningServiceOrders();
        if (response === 'failed') {
            console.log('Failed to get running service orders');
            setLoading(false);
            return;
        }
        setRunningServiceOrders(response.report);
        setLoading(false);
    }

    return (
        <div className={styles.providersPanel}>
            <div className={styles.providersPanelHeader}>
                <div className={styles.providersPanelButtons}>
                    <GlobalButton
                        onClick={() => setIsRunningServiceOrdersPanelOpen(false)}
                        label={'Fechar Ordens de Serviço Ativas'}
                        width={'184px'}
                        type={'secondary'}
                    />
                    <GlobalButton 
                            onClick={updateRunningServiceOrders} 
                            label={
                                <> 
                                    <RestorePageOutlinedIcon /> 
                                    <span>Atualizar<br/>Ordens de Serviço Ativas</span>
                                </>
                            }
                            width={'184px'}
                            type={'secondary'}
                    />
                </div>
                <div className={styles.providersPanelTitle}>Ordens de Serviço Ativas</div>
            </div>
            {loading ? <WaitingProgressLine /> : null}
            <RunningServiceOrdersList runningServiceOrders={runningServiceOrders}/>
        </div>

    )
}

function RunningServiceOrdersList({runningServiceOrders}) {
    const [sortedRunningServiceOrders, setSortedRunningServiceOrders] = useState([])
    
    const sortServiceOrders = useCallback((field) => {
        const keys = field.split('.');
        let sortedServiceOrdersAux = [];
        console.log('sorting running service orders', keys, 'keys length', keys.length);
        if(keys.length===1){
            sortedServiceOrdersAux = [...runningServiceOrders].sort((a, b) => {
                if(a[field] < b[field]){
                    return -1;
                }
                if(a[field] > b[field]){
                    return 1;
                }
                return 0;
            });
        } else if (keys.length===2){
            sortedServiceOrdersAux = [...runningServiceOrders].sort((a, b) => {
                if(a[keys[0]][keys[1]] < b[keys[0]][keys[1]]){
                    return -1;
                }
                if(a[keys[0]][keys[1]] > b[keys[0]][keys[1]]){
                    return 1;
                }
                return 0;
            });
        }
        setSortedRunningServiceOrders(sortedServiceOrdersAux);
    }, [runningServiceOrders]); 

    return (
        <div className={styles.providersList}>
            <div className={styles.providersReportHeader}>
                <div className={styles.serviceOrderId} onClick={()=>sortServiceOrders('serviceOrderId')}>Ordem de Serviço</div>
                <div className={styles.providerName} onClick={()=>sortServiceOrders('provider.providerName')}>Nome do Afiliados</div>
                <div className={styles.affiliateId} onClick={()=>sortServiceOrders('provider.affiliateId')}>AffiliateId</div>
                <div className={styles.whatsappSource} onClick={()=>sortServiceOrders('provider.whatsappSource')}>Número Whatsapp</div>

            </div>
            {sortedRunningServiceOrders.map((serviceOrder, index) => (
                <RunningServiceOrder serviceOrder={serviceOrder} key={index} />
            ))}
        </div>
    )
}

function RunningServiceOrder({serviceOrder}) {

    return (
        <>
            {serviceOrder.map(provider=> 
                <div className={styles.editLine}>
                    <div className={styles.serviceOrderId}>{serviceOrder.serviceOrderId}</div>
                    <div className={styles.providerName}>{provider.providerName}</div>
                    <div className={styles.serviceOrderId}>{provider.affiliateId}</div>
                    <div className={styles.serviceOrderId}>{provider.whatsappSource}</div>
                </div>
            )}   
        </>
    )
}