import React, {useContext, useRef, useEffect, useState} from 'react';
import SendIcon from '@mui/icons-material/Send';

import styles from './ChatInput.module.css';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';  
import moment from 'moment-timezone';
import {SocketContext} from 'Contexts/SocketContext.js';  
import {timeZone} from 'Contexts/static_options/Classes.js'


export default function ChatInput({inputContainerMinHeight, inputContainerMaxHeight, selectedProviderIndex, provider}){

    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);
    if(selectedProviderIndex.current >= currentServiceOrder.providers.length){
        selectedProviderIndex.current = 0;
    }
    
    if(!provider.inputText){
        provider.inputText = '';
    }
    const {socketEmitter} = useContext(SocketContext);

    const [sendButtonEnabled, setSendButtonEnabled] = useState(false);
    // const [inputText, setInputText] = useState('');
    const [localInput, setLocalInput] = useState(provider.inputText);

    useEffect(() => {
        if(localInput.trim() === ''){
            setSendButtonEnabled(false);
        } else {
            setSendButtonEnabled(true);
        }
    }, [localInput]);

    useEffect(() => {
        setLocalInput(provider.inputText);
    }, [provider]);

    useEffect(() => {
        const handler = setTimeout(() => {
            provider.inputText = localInput;
        }, 25);  // Adjust delay as necessary

        return () => {
            clearTimeout(handler);
        };
    }, [localInput]);
    
    const [isRecordingInputArea, setIsRecordingInputArea] = useState(false)


    const textAreaRef = useRef(null);
    const inputContainerRef = useRef(null);

    const inputIconsAreaWidth = isRecordingInputArea ? 230 : 50;
    const iconSizeNotRecording = 35 
    const inputIconArea = useRef(null);
 


    function sendMessage(){
        const message = {role: 'Provider', content: provider.inputText, createdAt: moment().tz(timeZone).format()}
        const newServiceOrder = {...currentServiceOrder};
        const newProvider = newServiceOrder.providers.find(prov => prov.affiliateId === provider.affiliateId);
        newProvider.conversation.push(message);
        newProvider.waitingResponse = true;
        newProvider.timeOflastMessageSentToProvider = 'formulatingAnswer';
        setCurrentServiceOrder(newServiceOrder);
        const payload = {message: message, serviceOrderId: currentServiceOrder.serviceOrderId, affiliateId: provider.affiliateId}
        socketEmitter('serviceProviderMessage', payload)
    }

    const handleInput = (e) => {
        const textAreaElement = e.target;
        setLocalInput(textAreaElement.value); // Update the state with the new 
        textAreaElement.style.height = 'auto';    
        textAreaElement.style.height = `${textAreaElement.scrollHeight}px`;
    }
    const aroundInputAreaVerticalPadding = 12;
    const aroundInputAreaVerticalStyle = {minHeight: inputContainerMinHeight, maxHeight:inputContainerMaxHeight, padding: `${aroundInputAreaVerticalPadding}px 12px`}
    const inputAreaStyle = {minHeight: `${inputContainerMinHeight - 2*aroundInputAreaVerticalPadding}px`, maxHeight:`${inputContainerMaxHeight - 2*aroundInputAreaVerticalPadding}px`}
    return(
        <div className={styles.inputContainer} ref={inputContainerRef}>
            <div className={styles.aroundInputArea} style={aroundInputAreaVerticalStyle}>
                <textarea
                    ref = {textAreaRef}
                    type="text" 
                    placeholder={"Message"} 
                    className={styles.inputArea} 
                    style={inputAreaStyle}
                    value={localInput}
                    onInput={handleInput}
                    onKeyDown={(e) => {
                        if(e.key === 'Enter' && !e.shiftKey){
                            e.preventDefault();
                            if(sendButtonEnabled){
                                sendMessage();
                                setLocalInput('');
                            }
                        }
                    }}
                />
            </div>

            <div className={styles.inputIcons} style={{width: inputIconsAreaWidth, fontSize: '30px'}} ref={inputIconArea}>
                {sendButtonEnabled &&
                    <SendIcon 
                        className={styles.sendIcon}
                        onClick={()=>{sendMessage(); setLocalInput('')}}
                        style={{width: iconSizeNotRecording, height: iconSizeNotRecording}}
                    />
                }
            </div>

        </div>
    )

}