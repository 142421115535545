
import React, {useContext, useCallback, useEffect}  from 'react'

import styles from 'GlobalComponents/CommonStyles.module.css';

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';   
import LabelElement from 'GlobalComponents/LabelElement/LabelElement.js';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput.js';
import TextInput from 'GlobalComponents/TextInput/TextInput.js';
import NumberInput from 'GlobalComponents/NumberInput/NumberInput.js';
import ItemGroupSelect from 'GlobalComponents/SelectionBox/SelectionBoxesInstances/ItemGroupSelect.js';
import {Item, ItemQuote, timeZone} from 'Contexts/static_options/Classes.js'
import moment from 'moment';

export default function ServiceOrderItems({labelWidth, inputWidth}){
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);

    const changeNumberOfItems = useCallback((event) => {
        const targetValue = event.target.value; // Extract the value from the event object
        let newItems = [...currentServiceOrder.items]
        if(targetValue>currentServiceOrder.items.length){
            newItems.push(new Item());
        } else if(targetValue<currentServiceOrder.items.length){
            newItems = newItems.slice(0, targetValue);
        } 
        setCurrentServiceOrder({...currentServiceOrder, items: newItems});
    }, [currentServiceOrder]);

    useEffect(() => {
        if(currentServiceOrder.currentStage!=='notInitiated'){
            return;
        }
        const newServiceOrder = {...currentServiceOrder};
        const providersWithQuoteViaLink = newServiceOrder.providers.filter(provider => provider.quoteViaLink);
        for(const provider of providersWithQuoteViaLink){
            if(provider.itemQuotes.length<newServiceOrder.items.length){
                const today = moment().tz(timeZone).format('YYYY-MM-DD');         
                for(let i=provider.itemQuotes.length; i<newServiceOrder.items.length; i++){
                    const itemQuote = new ItemQuote(i);
                    itemQuote.shortestAvailability.date = provider.providerBestQuote.shortestAvailability.date;
                    const days = moment(itemQuote.shortestAvailability.date).diff(moment(today), 'days')
                    itemQuote.shortestAvailability.days = days;
                    itemQuote.shortestAvailability.relativeDays = days;
                    itemQuote.stage = 1;
                    provider.itemQuotes.push(itemQuote); 
                } 
                // add the missing itemQuotes indexes to the providerBestQuote
                for(let i=provider.itemQuotes.length; i<newServiceOrder.items.length; i++){
                    provider.providerBestQuote.itemQuotesIndexes.push(i);
                }
            } else if(provider.itemQuotes.length>newServiceOrder.items.length){
                // remove the extra itemQuotes
                provider.itemQuotes = provider.itemQuotes.slice(0, newServiceOrder.items.length);
            } 
        }
    }, [currentServiceOrder.items]);

    const changeItem = useCallback((index, field) => (event) => {
        const newValue = event.target.value; 
        const newServiceOrder = {...currentServiceOrder};
        const newItems = currentServiceOrder.items;
        newItems[index][field] = newValue;
        setCurrentServiceOrder(newServiceOrder);
    }, [currentServiceOrder]);

    const defaultReadOnly = currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated';

    return(
        <div className={styles.parametersColumn} >
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Número de Items'}/>
                <DetailInput 
                    width={inputWidth}
                    inputElement={
                        <NumberInput 
                            inputValue={currentServiceOrder.items.length} 
                            onChange={changeNumberOfItems}
                            readOnly={defaultReadOnly}
                            spin={true}
                        />
                    }
                />
            </div>

            <ul className={styles.parametersColumn}>
                {currentServiceOrder.items.map((item, index) => (
                    <li key={index} className={styles.parametersColumn}>
                        <div className={styles.parameterRow}>
                            <LabelElement width={labelWidth} label={'SKU'} />
                            <DetailInput 
                                width={inputWidth}
                                inputElement={
                                    <TextInput 
                                        inputValue={item.SKU} 
                                        onChange={changeItem(index, 'SKU')}
                                        readOnly={defaultReadOnly}
                                    />
                                }
                            />
                        </div>
                        <div className={styles.parameterRow}>
                            <LabelElement width={labelWidth} label={'Descr. Item'} />
                            <DetailInput 
                                width={inputWidth}
                                inputElement={
                                    <TextInput 
                                        inputValue={item.itemName} 
                                        onChange={changeItem(index, 'itemName')}
                                        readOnly={defaultReadOnly}
                                    />
                                }
                            />
                        </div>
                        <div className={styles.parameterRow}>
                            <LabelElement width={labelWidth} label={'Grupo do Item'} />
                            <DetailInput 
                                width={inputWidth}
                                inputElement={
                                    <ItemGroupSelect 
                                        index={index} 
                                        readOnly={defaultReadOnly}
                                    />
                                }
                            />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}
