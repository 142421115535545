// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.xnfycANxSr3rpQVTjJMs{
    color: var(--icons);
    flex-shrink: 0;
    transform: scaleX(0.75); /* Adjust as necessary */
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./clientRoot/Static/WorkingArea/TabToggleButton/TabToggleButton.module.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;IACnB,cAAc;IACd,uBAAuB,EAAE,wBAAwB;IACjD,eAAe;AACnB","sourcesContent":["\n.toggleButton{\n    color: var(--icons);\n    flex-shrink: 0;\n    transform: scaleX(0.75); /* Adjust as necessary */\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleButton": `xnfycANxSr3rpQVTjJMs`
};
export default ___CSS_LOADER_EXPORT___;
