
import React, {useContext, useCallback, useRef, useState, useEffect } from 'react';
import styles from 'GlobalComponents/CommonStyles.module.css';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';  

import ChatHeader from './ChatHeader/ChatHeader.js';


export default function ProvidersList({selectedProvider, setSelectedProvider, selectedProviderIndex, view}){
    const headerHeight = 96;
    const {currentServiceOrder} = useContext(QuoteMasterContext);
    const providersChatHeaderList = useRef(null);
    const [headerWidth, setHeaderWidth] = useState(400);

    useEffect(() => {
        if (providersChatHeaderList.current) {
            requestAnimationFrame(() => {
                if(providersChatHeaderList.current){
                    providersChatHeaderList.current.scrollTop = providersChatHeaderList.current.scrollHeight; // Example large scroll
                }
                requestAnimationFrame(() => {
                    if(providersChatHeaderList.current){
                        providersChatHeaderList.current.scrollTop += 10; // Ensuring this small adjustment occurs after the previous scroll    
                    }
                });
            });
        }


        const updateWidth = () => {
            if(!providersChatHeaderList.current){
                return;
            }
            const width = providersChatHeaderList.current.offsetWidth; 
            const scrollBarWidth = providersChatHeaderList.current.scrollHeight > providersChatHeaderList.current.clientHeight ? 6 : 0;
            const newWidth = width -scrollBarWidth;
            setHeaderWidth(newWidth);
        };
        updateWidth();
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                updateWidth();
            }
        });

        if (providersChatHeaderList.current) {
            resizeObserver.observe(providersChatHeaderList.current);
        }

        return () => {
            if (providersChatHeaderList.current) {
                resizeObserver.unobserve(providersChatHeaderList.current);
            }
        };
    }, [currentServiceOrder.providers.length, providersChatHeaderList.current, view ]); 

    return(
        <ul className={styles.mainView}  style={{margin: '0px', padding: '0px'}} ref={providersChatHeaderList} >
            {currentServiceOrder.providers.map((provider, index) => (
                <ChatHeader key={index} headerWidth={headerWidth} headerHeight={headerHeight} provider={provider} index={index} view={view} instance={'providersList'} setSelectedProvider={setSelectedProvider} selectedProvider={selectedProvider} selectedProviderIndex={selectedProviderIndex}/>
            ))}
        </ul>
    )
}