import React, { useState, useContext } from 'react';

import styles from './PageHeader.module.css';

import { logoff} from '../ServerCalls/serverCalls.js';
import {SocketContext} from 'Contexts/SocketContext.js';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';

import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'; 
import Typography from '@mui/material/Typography';

export default function PageHeader({setIsAdminPanelOpen, setIsProvidersPanelOpen}){ 
    const {user, setUser, setCurrentServiceOrder, setServiceOrders, freshServiceOrder} = useContext(QuoteMasterContext);
    const {socketShouldReconnect} = useContext(SocketContext);
    const [anchorElUser, setAnchorElUser] = useState(null);
    
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    const regularUserSettings = ['Logout'];
    const masterAdminSettings = ['Logout', 'OpenAdminPanel', 'OpenProvidersPanel'];
    const settings = user.userType === 'master-admin' ? masterAdminSettings : regularUserSettings;
    
    const regularUserSettingsLabels = {
        Logout: 'Logout',
    };
    const masterAdminSettingsLabels = {
        Logout: 'Logout',
        OpenAdminPanel: 'Admin Panel',
        OpenProvidersPanel: 'Painel de Afiliados'
    };
    const settingsLabels = user.userType === 'master-admin' ? masterAdminSettingsLabels : regularUserSettingsLabels;


    const regularUserHandlers = {
        Logout: handleLogout,
    };   

    const masterAdminHandlers = {
        Logout: handleLogout,
        OpenAdminPanel: handleOpenAdminPanel,
        OpenProvidersPanel: handleOpenProvidersPanel
    }; 

    const settingsHandlers = user.userType === 'master-admin' ? masterAdminHandlers : regularUserHandlers;
  
    async function handleLogout(){
        try{
            localStorage.removeItem('quoteMasterUser');
            socketShouldReconnect.current = false;
            await logoff();
            setUser({});
            setCurrentServiceOrder(freshServiceOrder);
            setServiceOrders([]);
            setIsAdminPanelOpen(false);
            setIsProvidersPanelOpen(false);
        } catch(error){
            console.error("Error during logoff", error);
        }
    }

    function handleOpenAdminPanel(){
        setIsAdminPanelOpen(true);
    }

    function handleOpenProvidersPanel(){
        setIsProvidersPanelOpen(true);
    }

    return(
        <div className={styles.pageHeader}>
            <div className={styles.pageHeaderTitle}>{`Olá ${user.firstName}!`}</div>
            <Tooltip title={user.userEmail} placement="bottom">
                <Avatar
                    alt={user.userEmail} 
                    src={user.profilePic} 
                    className={styles.avatar}  
                    style={{width: '40px', height: '40px'}}
                    onClick={handleOpenUserMenu}
                />
            </Tooltip>
            <Menu
                sx={{mt: '35px'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {settings.map((setting) => (
                    <MenuItem key={setting} onClick={() => {handleCloseUserMenu(),  settingsHandlers[setting]()}} sx={{height: 35}} >
                        <Typography textAlign="center" sx={{fontSize: 16}}>{settingsLabels[setting]}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )

}

