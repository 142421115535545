import React, {useContext, useCallback} from 'react'

import styles from 'GlobalComponents/CommonStyles.module.css';

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';   
import DetailInput from 'GlobalComponents/DetailInput/DetailInput';
import TextInput from 'GlobalComponents/TextInput/TextInput';
import NumberInput from 'GlobalComponents/NumberInput/NumberInput.js';
import LabelElement from 'GlobalComponents/LabelElement/LabelElement.js';


export default function Hashtags({inputWidth, labelWidth, totalWidth}){
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);

    const changeHashTag = useCallback((index) => (event) => {
        const newValue = event.target.value; 
        const newServiceOrder = {...currentServiceOrder};
        const newHashTags = currentServiceOrder.hashtags;
        newHashTags[index] = newValue;
        setCurrentServiceOrder(newServiceOrder);
    }, [currentServiceOrder]);

    const changeNumberOfHashtags = useCallback((event) => {
        const targetValue = event.target.value; // Extract the value from the event object
        let newHashtags = [...currentServiceOrder.hashtags]
        if(targetValue>currentServiceOrder.hashtags.length){
            newHashtags.push('');
        } else if(targetValue<currentServiceOrder.hashtags.length){
            newHashtags = newHashtags.slice(0, targetValue);
        } 
        setCurrentServiceOrder({...currentServiceOrder, hashtags: newHashtags});
    }, [currentServiceOrder]);

    const defaultReadOnly = currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated';

    return (
        <div className={styles.parametersColumn} > 
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Hashtags'} />
                <DetailInput 
                    width={inputWidth}
                    inputElement={
                        <NumberInput 
                            inputValue={currentServiceOrder.hashtags.length} 
                            onChange={changeNumberOfHashtags}
                            readOnly={defaultReadOnly}
                            spin={true}
                        />
                    }
                />
            </div>
            {currentServiceOrder.hashtags.map((hashtag, index) => (
                <div className={styles.parameterRow} key={index}>
                    <DetailInput 
                        width={totalWidth}
                        readOnly={defaultReadOnly}
                        inputElement={
                            <TextInput 
                                inputValue={hashtag} 
                                onChange={changeHashTag(index)}  
                                readOnly={defaultReadOnly}
                            />
                        }
                    />
                </div>
            ))}
        </div>
    )
}