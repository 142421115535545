


import React, {useContext, useEffect, useState} from 'react'
import styles from 'GlobalComponents/CommonStyles.module.css';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';  
import LabelElement from 'GlobalComponents/LabelElement/LabelElement.js';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput.js';
import TextInput from 'GlobalComponents/TextInput/TextInput.js';
import moment from 'moment-timezone';
import {timeZone} from 'Contexts/static_options/Classes.js';
import {convertISODateToReadableString, formatTime} from 'Contexts/Utils/formatTime.js';

export default function StageTimesSummary({}){
    const {currentServiceOrder} = useContext(QuoteMasterContext);

    const stageName = (stage) => {
        switch(stage){
            case 1:
            case 2:
            case 3:
                return `Estágio ${stage}`;
            case 'confirmToClose':
                return 'Confirmar Cotação';
            case 'confirmFifoAndTime':
                return 'Confirmar Horário de Atendimento';
            case 'confirmDates':
                return 'Confirmar Datas';
            case 'closed':
                return 'Cotação Fechada';
            case 'fallbackToHuman':
                return 'Atendimento Humano';
        }
    }
                
    return(
        <div className={styles.parameterColumn} style={{marginBottom:'8px'}}>
            <div className={styles.parameterRow}>
                <LabelElement width={'136px'} label={'Estágios'} />
                <LabelElement width={'144px'} label={'Início'} />
                <LabelElement width={'120px'} label={'Duração'} />
            </div>
            {currentServiceOrder.stageStartTime.filter(stage=>stage.stage !== 'notInitiated' && stage.time!=='' ).map((stage, index) => (
                <div className={styles.parameterRow} key={index}>
                    <LabelElement width={'136px'} label={stageName(stage.stage)} />
                    <DetailInput                        
                        width={'144px'}
                        readOnly={true}
                        inputElement={
                            <TextInput
                                inputValue={convertISODateToReadableString(stage.time)}
                                readOnly={true}
                            />
                        }
                    />
                    <DetailInput                        
                        width={'120px'}
                        readOnly={true}
                        inputElement={
                            <StageDuration stage={stage} />
                        }
                    />
                </div>
            ))}
        </div>
    )
}

function StageDuration({stage}){
    const {currentServiceOrder} = useContext(QuoteMasterContext);
    const [duration, setDuration] = useState('');

  
    useEffect(() => {
        if(stage.stage === 'closed' || stage.stage === 'fallbackToHuman'){
            setDuration('');
            return;
        }

        // calculate the duration of the stage based on the time it started and the time the next item in the that has a start time started 
        const stageIndex = currentServiceOrder.stageStartTime.findIndex(stageTime => stageTime.stage === stage.stage);
        if(currentServiceOrder.stageStartTime[stageIndex].time === ''){
            setDuration('');
            return;
        }   

        const nextStageIndex = currentServiceOrder.stageStartTime.findIndex((stageTime, index) => index > stageIndex && stageTime.time !== '');

        if(nextStageIndex !== -1){ // if a next stage with a start time was found the stage is over
            const nextStageTime = moment(currentServiceOrder.stageStartTime[nextStageIndex].time);
            const thisStageTime = moment(currentServiceOrder.stageStartTime[stageIndex].time);
            const timeDifference = nextStageTime.diff(moment(thisStageTime), 'seconds');
            const newDuration = formatTime(timeDifference);
            setDuration(newDuration);
            return;
        }

        const interval = setInterval(() => {
            const currentTime = moment().tz(timeZone)
            const timeDifference = currentTime.diff(moment(currentServiceOrder.stageStartTime[stageIndex].time), 'seconds');
            const newDuration = formatTime(timeDifference);
            setDuration(newDuration);
            const nextStageIndex = currentServiceOrder.stageStartTime.findIndex((stageTime, index) => index > stageIndex && stageTime.time !== '');
            if(nextStageIndex !== -1){ // if a next stage with a start time was found the stage is over
                clearInterval(interval);
                return;
            }
        }, 1000);
        
        return () => clearInterval(interval);
    }, [stage, currentServiceOrder.stageStartTime]);

    return(
        <TextInput
            inputValue={duration}
            readOnly={true}
        />
    )
}