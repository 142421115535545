import React, {useContext, useEffect, useRef, useState, useImperativeHandle  } from 'react';

import { QuoteMasterContext } from 'Contexts/QuoteMasterContext.js';

import SelectionBox from 'GlobalComponents/SelectionBox/SelectionBox.js';
import { Client, Automobile } from 'Contexts/static_options/Classes.js'

export default function RunTypeSelect({FowardingRef}) {
    const {setCurrentServiceOrder, currentServiceOrder} = useContext(QuoteMasterContext);
    const [runType, setRunType] = useState(currentServiceOrder.runType);

    const firstLoadCompleted = useRef(false);
    useEffect(() => {
        if(firstLoadCompleted.current){ // if the page is just loading for the first time
            setCurrentServiceOrder({
                ...currentServiceOrder, 
                runType: runType,
                clientInformation: runType !== 'regular' ? new Client().fillUpClient(clientStandard) : new Client(),
                autoInformation: runType !== 'regular' ? new Automobile().fillUpAuto(autoStandard) : new Automobile(),
                timeMultiplier: runType === 'regular' ? 3600 : currentServiceOrder.timeMultiplier,
                serviceAddress:{...serviceAddressStandard}
            });
        } else {
            firstLoadCompleted.current = true;
        }
    }, [runType]);

    const clientStandard = {
        clientName: 'Hetnon Freitas', 
        clientPhoneNumber: '21988741036', 
        LGPDaccepted: true, // true or false if the client agreed have their data disclosed to the providers,
    };

    const serviceAddressStandard = {
        city: '',
        state: '',
        suburb: '',
        latitude: '-23.00929641342811',
        longitude: '-43.33500107290827',
    };


    
    const autoStandard = {
        autoCategory: 'Auto', // this is cargo or other regular
        autoModel: 'Palio', 
        autoYear: '2020', 
        autoChassis: 'OIJF:ALSJKN12',
        autoPlate: 'JLH-1234',
    }; 

    useImperativeHandle(FowardingRef, () => ({
        resetRunType: () => {
            setRunType('regular'); // Clear or reset the scenario
        }
    }));

    let selectionItems = [
        {value: 'regular', label: 'Operação Regular'},
        {value: 'openTest', label: 'Teste'},
    ]

    if(process.env.REACT_APP_NODE_ENV==='development'){ // just run get setvice orders if not in development
        selectionItems = [...selectionItems, {value: 'standardTest', label: 'Teste Padrão'}]
    }

    return (
        <SelectionBox 
            selectionItems={selectionItems} 
            mainFunction={setRunType} 
            mainValue={runType} 
            instanceName={'Scenario Type'} 
            readOnly={currentServiceOrder.currentStage!=='notInitiated'}  />
    );
}

