import React, { useEffect, useState} from 'react';

import {formatTime} from 'Contexts/Utils/formatTime.js';
import moment from 'moment-timezone';
import {timeZone} from 'Contexts/static_options/Classes.js'

export default function TimeToGo({startTime, length, type}){

    const [timeToGoMessage, setTimeToGoMessage] = useState('');
    const [timeToGoFormated, setTimeToGoFormated] = useState(0);

    useEffect(() => {
        if(type==='stage'){
            setTimeToGoMessage('Tempo de Estágio Restante: ');
        } else if(type==='humanFallBack'){
            setTimeToGoMessage('Tempo para Envio para Humano: ');
        } else if(type==='followUp'){
            setTimeToGoMessage('Follow up em: ');
        }
    }, [type])  

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = moment().tz(timeZone).format();
            const timeDifference = new Date(currentTime) - new Date(startTime).getTime();
            const timeDifferenceInSeconds = Math.floor(timeDifference / 1000);
            const timeRemainingInSeconds = length - timeDifferenceInSeconds;
            if(timeRemainingInSeconds <= 0){
                clearInterval(interval);
                setTimeToGoFormated('00:00:00');
                return;
            }
            setTimeToGoFormated(formatTime(timeRemainingInSeconds));
        }, 1000);
        return () => clearInterval(interval);
    }, [length, startTime]);


    return(
        <div> {timeToGoMessage} {timeToGoFormated}</div>
    )
}