import React, { useContext } from 'react'

import styles from 'GlobalComponents/CommonStyles.module.css';
import ServiceOrderMainRow from './ServiceOrderMainRow/ServiceOrderMainRow.js';
import { QuoteMasterContext } from 'Contexts/QuoteMasterContext.js';
import RunServiceOrderButton from './RunServiceOrderButton.js';
import RunTestsButton from './RunTestsButton.js';
import NotesButton from './NotesButton.js';
import ClientDetails from './ClientDetails.js';
import ServiceAddress from './ServiceAddress.js';
import AutoDetails from './AutoDetails.js';
import VirtualAgentDetails from './VirtualAgentDetails.js';
import UserDetails from './UserDetails.js';
import ServiceOrderItems from './ServiceOrderItems.js';
import CostTargets from './CostTargets.js';
import TimeParameters from './TimeParameters.js';
import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js';
import SaveButton from './SaveButton.js';
import StopOSButton from './StopOSButton.js';
import LLMCostSummary from './LLMCostSummary.js';
import NotesView from './NotesView.js';
import Hashtags from './Hashtags.js';


export default function SOHeader({SOHeaderTabWidth, headerView, setHeaderView}) {   
    const {loading, currentServiceOrder, user} = useContext(QuoteMasterContext); 
    
    return (
        <div className={styles.SOHeader} style={{minWidth: SOHeaderTabWidth, maxWidth:SOHeaderTabWidth }}>
            <div className={styles.headerButtons} >  
                {!loading && currentServiceOrder.currentStage==='notInitiated' && <RunServiceOrderButton />}
                {!loading && user.userType === 'master-admin' && currentServiceOrder.currentStage==='notInitiated' && <RunTestsButton />}
                {(currentServiceOrder.currentStage==='notInitiated' && headerView !== 'cost') ?
                    <SaveButton />:
                    <div className={styles.detailsAndCostButtons} >
                        <GlobalButton  onClick={() => setHeaderView('main')} label='Detalhes' width='80px' type={headerView === 'main' ? 'selected' : 'secondary'} />
                        {!loading && (currentServiceOrder.currentStage!=='closed' && currentServiceOrder.currentStage!=='fallbackToHuman') && <StopOSButton />}
                        {!loading && (currentServiceOrder.currentStage==='closed' || currentServiceOrder.currentStage==='fallbackToHuman') && <NotesButton setHeaderView={setHeaderView} headerView={headerView}/>}
                        {user.userType === 'master-admin' &&  <GlobalButton  onClick={() => setHeaderView('cost')} label='Custos' width='80px' type={headerView === 'cost' ? 'selected' : 'secondary'} />}
                    </div>
                }
            </div>
            <br></br>
            {headerView === 'main' && <MainView />}
            {headerView === 'cost' && user.userType === 'master-admin' && <LLMCostSummary />}
            {headerView === 'notes' && <NotesView />}
        </div>
    )
}

function MainView({}) {
    const labelWidth = '160px';
    const inputWidth = '170px';
    const totalWidth = '330px';
    const {currentServiceOrder} = useContext(QuoteMasterContext);
    return (
        <div className={styles.mainView} >
            <ServiceOrderMainRow labelWidth={labelWidth} inputWidth={inputWidth}/>
            <UserDetails labelWidth={labelWidth} inputWidth={inputWidth}/>
            <VirtualAgentDetails labelWidth={labelWidth} inputWidth={inputWidth}/>
            <ClientDetails labelWidth={labelWidth} inputWidth={inputWidth}/> 
            <ServiceAddress labelWidth={labelWidth} inputWidth={inputWidth}/>     
            <AutoDetails labelWidth={labelWidth} inputWidth={inputWidth}/>
            {currentServiceOrder.hashtags && <Hashtags labelWidth={labelWidth} inputWidth={inputWidth} totalWidth={totalWidth}/>}
            <ServiceOrderItems labelWidth={labelWidth} inputWidth={inputWidth} />
            <CostTargets    />
            <TimeParameters labelWidth={labelWidth} inputWidth={inputWidth}/>
        </div>
    )
}



