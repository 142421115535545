import React, { useContext, useState, useEffect, useRef   } from 'react';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';   
import SelectionBox from 'GlobalComponents/SelectionBox/SelectionBox';

export default function TimeDropdown({providerIndex, readOnly, dateIndex} ){
    
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);
    let timeToTrack = dateIndex === 'main' ? // main is for the main time (providerBestQuote.bookingTime.time)
        currentServiceOrder.providers[providerIndex].providerBestQuote.bookingTime.time : 
        currentServiceOrder.providers[providerIndex].providerBestQuote.dateOptions[dateIndex].time
    // round to the nearest 30 minutes
    timeToTrack = timeToTrack.replace(/:([0-9]{2})$/, (match, p1) => {
        if(p1 < 30){
            return ':00';
        } else {
            return ':30';
        }
    });
    const [selectedTime, setSelectedTime] = useState(timeToTrack)

    const timeOptions = generateTimeSlots();
    const firstLoadCompleted = useRef(false);
    const timeToTrackRef = useRef(timeToTrack);

    useEffect(() => {

        if(firstLoadCompleted.current && !readOnly ){ // if the page is just loading for the first time
            const newServiceOrder = {...currentServiceOrder};
            const newProvider = newServiceOrder.providers[providerIndex];
            if(dateIndex === 0 || dateIndex === 1){ // these are the date options
                newProvider.providerBestQuote.dateOptions[dateIndex].time = selectedTime;
            } else if(dateIndex === 'main'){ // this is the main date
                newProvider.providerBestQuote.bookingTime.time = selectedTime;
            }
            setCurrentServiceOrder(newServiceOrder);
        } else {
            firstLoadCompleted.current = true;
        }
    }, [selectedTime]);

    useEffect(() => {
        if(timeToTrack !== timeToTrackRef.current){
            setSelectedTime(timeToTrack);
            timeToTrackRef.current = timeToTrack;
        }
    }, [timeToTrack]);   
    
    return (
        <SelectionBox
            selectionItems={timeOptions} 
            mainFunction={setSelectedTime} 
            mainValue={selectedTime} 
            instanceName={'Hora'}
            readOnly={readOnly}
        />
    );
}


function generateTimeSlots(interval = 30, startHour = 8, endHour = 19) {
    const times = [];
    for (let hour = startHour; hour <= endHour; hour++) {
        for (let minute = 0; minute < 60; minute += interval) {
            const timeString = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
            times.push({value: timeString, label: timeString});
        }
    }
    return times;
}