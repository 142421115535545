import React, {useContext, useCallback} from 'react'

import styles from 'GlobalComponents/CommonStyles.module.css';

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';   
import LabelElement from 'GlobalComponents/LabelElement/LabelElement';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput';
import {convertISODateToReadableString} from 'Contexts/Utils/formatTime.js';
import TextInput from 'GlobalComponents/TextInput/TextInput';

export default function ServiceOrderMainRow({labelWidth, inputWidth}){
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);

    const changeTheValueOf = useCallback((field) => (event) => {
        const value = event.target.value; // Extract the value from the event object
        setCurrentServiceOrder((prevOrder) => ({
            ...prevOrder,
            [field]: value
        }));
    }, [currentServiceOrder]);
    
    return (
        <div className={styles.parametersColumn} > 
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Ordem de Serviço'} />
                <DetailInput 
                    width={inputWidth}
                    readOnly={currentServiceOrder.runType!=='regular' || currentServiceOrder.currentStage !=='notInitiated'}
                    inputElement={
                        <TextInput 
                            inputValue={currentServiceOrder.serviceOrderId} 
                            onChange={changeTheValueOf('serviceOrderId')}  
                            readOnly={currentServiceOrder.runType!=='regular' || currentServiceOrder.currentStage !=='notInitiated'}
                        />
                    }
                />
            </div>
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Data de Criação'} />
                <DetailInput 
                    width={inputWidth}
                    readOnly={true}
                    inputElement={
                        <TextInput 
                            inputValue={currentServiceOrder.stageStartTime.find(stage=>stage.stage===1).time ? convertISODateToReadableString(currentServiceOrder.stageStartTime.find(stage=>stage.stage===1).time):''}
                            onChange={()=>{}} 
                            readOnly={true}
                        />
                    }
                />
            </div>
        </div>

    )
}