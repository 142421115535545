import React, {useContext, useCallback, useState, useEffect  } from 'react'

import styles from './TelephoneInput.module.css';
import MaskElement from 'GlobalComponents/MaskedInput/MaskedInput.js';
import CountryCodeSelect from 'GlobalComponents/SelectionBox/SelectionBoxesInstances/CountryCodeSelect.js';
import AreaCodeSelect from 'GlobalComponents/SelectionBox/SelectionBoxesInstances/AreaCodeSelect.js';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput.js';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';   

export default function TelephoneInput({provider, readOnly, instance, type}){

    return(
        <div className={styles.detail}>
            <div style={{width:'60px', height: '32px', display: 'flex', boxSizing: 'border-box'}}>
                {<DetailInput 
                    inputElement={
                        <CountryCodeSelect 
                            readOnly={readOnly} 
                            provider={provider} 
                            instance={instance} 
                            type={type} 
                        />
                    } 
                    width={'60px'} 
                    readOnly={readOnly} 
                    backgroundReadOnly={'transparent'} 
                    borderBottom={false}
                />}
            </div>
            
            <div style={{width:'52px', height: '32px', display: 'flex', boxSizing: 'border-box'}}>
                {<DetailInput 
                    inputElement={
                        <AreaCodeSelect 
                            readOnly={readOnly} 
                            provider={provider} instance={instance} type={type}
                        />
                    }                     
                    width={'52px'} 
                    readOnly={readOnly} 
                    backgroundReadOnly={'transparent'} 
                    borderBottom={false}
                />}
            </div>
            
            <div style={{width:'96px', height: '32px', display: 'flex', boxSizing: 'border-box'}}>
                {<DetailInput 
                    inputElement={
                        <NumberElement 
                            provider={provider} 
                            instance={instance} 
                            type={type} 
                            readOnly={readOnly} 
                        />
                    }
                    width={'96px'} 
                    readOnly={readOnly} 
                    backgroundReadOnly={'transparent'} 
                    borderBottom={false}
                />}
            </div>
        </div>
    )
}

function NumberElement({provider, instance, type, readOnly}){
    const [numberValue, setNumberValue] = useState('');
    const {setCurrentServiceOrder, currentServiceOrder, providersList, setProvidersList} = useContext(QuoteMasterContext);

    // set the number value based on the provider's phone number or whatsapp source
    useEffect(() => {
        if(type==='phoneNumber' && provider.phoneNumber){
            setNumberValue(provider.phoneNumber.number);
        } else if(type==='whatsappSource' && provider.whatsappSource.formattedSource!=='notAvailable'){
            setNumberValue(provider.whatsappSource.formattedSource.number);
        }
    }, [provider.phoneNumber.number, provider.whatsappSource.formattedSource.number]);


    // change the provider's number based on the instance
    const changeProviderNumber = useCallback((event) => {

        if(type!=='phoneNumber'){
            return;
        }

        const value = event.target.value;
        setNumberValue(value);

        let providerIndex;
        let newProviders;
        if(instance==='providersList' || instance==='chat'){ // this is the list of providers in the service order used in the chat header
            newProviders = [...currentServiceOrder.providers];
            providerIndex = currentServiceOrder.providers.findIndex((prov) => prov.providerName===provider.providerName);
        }else if(instance==='newProvider'){ 
            // this is used in the provider modal for a new provider
            newProviders = [...providersList];
            providerIndex = providersList.length-1;
        } else if(instance==='providersPanel'){ 
            // this is used in the providers panel for the list of providers and in the provider modal for editing a provider
            newProviders = [...providersList];
            providerIndex = providersList.findIndex((prov) =>prov.affiliateId===provider.affiliateId);
        }


        const newProvider = newProviders[providerIndex];
        newProvider.phoneNumber.number = value.replace('-', '');

        if(instance==='providersList' || instance==='chat'){
            setCurrentServiceOrder({...currentServiceOrder, providers: newProviders});
        } else if(instance==='providersPanel' || instance==='newProvider'){
            setProvidersList(newProviders);
        }
        


    }, [currentServiceOrder, providersList]);

    return(
        <MaskElement 
            inputValue={numberValue}
            onChange={changeProviderNumber} 
            maskLayout={["9999-9999", "99999-9999"]} 
            width={'96px'} 
            readOnly={readOnly} 
        />
    )
}