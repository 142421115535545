import React from 'react'
import styles from 'GlobalComponents/CommonStyles.module.css';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput.js';
import TextInput from 'GlobalComponents/TextInput/TextInput.js';

export default function BestQuote({type, quote, providerName}){

    return(
        <div className={styles.parametersColumn} style={{marginBottom: '0px'}}> 

            <div className={styles.parameterRow}>
                <DetailInput
                    width={'200px'}
                    readOnly={true}
                    inputElement={
                        <TextInput
                            inputValue={type==='bestQuote' ? (providerName) : 'Target Atual'}
                            onChange={() => (e) => {}}
                            readOnly={true}
                        />
                    }
                />
                <DetailInput
                    width={'96px'}
                    readOnly={true}
                    inputElement={
                        <TextInput
                        inputValue={quote ? quote.price : ''}
                            onChange={() => (e) => {}}
                            readOnly={true}
                        />
                    }
                />
                <DetailInput
                    width={'60px'}
                    readOnly={true}
                    inputElement={
                        <TextInput
                        inputValue={quote ? quote.rate: ''}
                            onChange={() => (e) => {}}
                            readOnly={true}
                        />
                    }
                />
                <DetailInput
                    width={'60px'}
                    readOnly={true}
                    inputElement={
                        <TextInput
                        inputValue={(type==='bestQuote' ? quote.shortestAvailability.relativeDays : quote.shortestAvailability.days)  || ''}
                            onChange={() => (e) => {}}
                            readOnly={true}
                        />
                    }
                /> 
                <DetailInput
                    width={'60px'}
                    readOnly={true}
                    inputElement={
                        <TextInput
                        inputValue={quote ? quote.brand.tier : ''}
                            onChange={() => (e) => {}}
                            readOnly={true}
                        />
                    }
                />
            </div>
        </div>
    )
}