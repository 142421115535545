
import React, {useContext, useState, useRef,useEffect } from 'react'

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';   
import SelectionBox from 'GlobalComponents/SelectionBox/SelectionBox.js';


export default function AutoCategorySelect({}) {
    const {setCurrentServiceOrder, currentServiceOrder} = useContext(QuoteMasterContext);
    const [autoCategory, setAutoCategory] = useState(currentServiceOrder.autoInformation.autoCategory);
    const firstLoadCompleted = useRef(false);

    
    useEffect(() => {
        if(firstLoadCompleted.current && currentServiceOrder.currentStage === 'notInitiated'){ // if the page is just loading for the first time
            const newCurrentServiceOrder = {...currentServiceOrder};
            newCurrentServiceOrder.autoInformation.autoCategory = autoCategory;
            setCurrentServiceOrder(newCurrentServiceOrder);
        } else {
            firstLoadCompleted.current = true;
        }
    }, [autoCategory]);

    useEffect(() => {
        setAutoCategory(currentServiceOrder.autoInformation.autoCategory);
    }, [currentServiceOrder.autoInformation.autoCategory]);

    const selectionItems = [
        {value: 'Auto', label: 'Auto'},
        {value: 'Cargo', label: 'Carga'},
    ]

    return (
        <SelectionBox 
            selectionItems={selectionItems} 
            mainFunction={setAutoCategory} 
            mainValue={autoCategory} 
            instanceName={'Categoria Veículo'} 
            readOnly={currentServiceOrder.runType === 'standardTest' || currentServiceOrder.currentStage!=='notInitiated'}
        />
    );
}


