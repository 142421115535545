
import React, {useContext, useState, useRef,useEffect } from 'react'

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import SelectionBox from 'GlobalComponents/SelectionBox/SelectionBox';

export default function FIFOSelect({providerIndex, readOnly}) {
    const {setCurrentServiceOrder, currentServiceOrder} = useContext(QuoteMasterContext);
    const [FIFO, setFIFO] = useState(currentServiceOrder.providers[providerIndex].providerBestQuote.bookingTime.FIFO);
    const firstLoadCompleted = useRef(false);

    useEffect(() => {
        if(firstLoadCompleted.current && currentServiceOrder.currentStage === 'notInitiated' && currentServiceOrder.providers[providerIndex].quoteViaLink ){ // if the page is just loading for the first time
            const newServiceOrder = {...currentServiceOrder};
            const newProvider = {...newServiceOrder.providers[providerIndex]};
            newProvider.providerBestQuote.bookingTime.FIFO = FIFO;
            let newProviders = [...newServiceOrder.providers];
            newProviders[providerIndex] = newProvider;
            newServiceOrder.providers = newProviders;
            setCurrentServiceOrder(newServiceOrder);
        } else {
            firstLoadCompleted.current = true;
        }
    }, [FIFO]);

    useEffect(() => {
        if(currentServiceOrder.providers[providerIndex] && currentServiceOrder.providers[providerIndex]){
            setFIFO(currentServiceOrder.providers[providerIndex].providerBestQuote.bookingTime.FIFO);
        }
    }, [currentServiceOrder.providers[providerIndex].providerBestQuote.bookingTime.FIFO]);

    const selectionItems = [
        {value: true, label: 'Ordem de Chegada'},
        {value: false, label: 'Hora Marcada'},
    ]
    return (
        <SelectionBox 
            selectionItems={selectionItems} 
            mainFunction={setFIFO} 
            mainValue={FIFO} 
            instanceName={'Agendamento Por'}
            readOnly={readOnly}
        />
    );
}


