// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.fYNh3sLr7KT7vJIIvbvA{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



`, "",{"version":3,"sources":["webpack://./clientRoot/Static/GlobalComponents/SelectionBox/SelectionBox.module.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":["\n\n.selectionBox{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    height: 100%;\n    width: 100%;\n    box-sizing: border-box;\n    border: none;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectionBox": `fYNh3sLr7KT7vJIIvbvA`
};
export default ___CSS_LOADER_EXPORT___;
