import React, {useContext, useEffect, useRef, useState, useImperativeHandle   } from 'react';

import { QuoteMasterContext } from '../../QuoteMasterContext';

import SelectionBox from 'GlobalComponents/SelectionBox/SelectionBox.js';
import {Provider} from 'Contexts/static_options/Classes.js'

export default function TestScenarios({FowardingRef}) {
    const {currentServiceOrder, setCurrentServiceOrder, initialTarget} = useContext(QuoteMasterContext);
    
    const fornecedor1 = {providerName: 'Fornecedor 1', affiliateId: '1111111', phoneNumber: {countryCode: '+55', areaCode:'19', number:'999741036'}, link:'https://www.wa.com/fornecedor1'}; //quotes: [535, 530, 530, 'date:2024-07-15']
    const fornecedor2 = {providerName: 'Fornecedor 2',  affiliateId: '2222222', phoneNumber: {countryCode: '+55', areaCode:'11', number:'989450987'}, link:'https://www.wa.com/fornecedor2'}; // quotes: [540, 535, 535, 'date:2024-07-16']

    const parabrisa = {
        scenarioName: 'Parabrisa',
        items: [{SKU:'3349AGNBL', itemName: 'PALIO/WEEK/SIENA 96/ON / STRADA 96/20 - PARABRISA VERDE C/DEG AZUL', itemGroup: 'glasses'}],
        totalContacts: 2,
        quoteMasterMinimumRate: {rate: 5.0, price: 700},
        minimumDesirableRate: {rate: 7.0, price: 600},
        maximumRate: {rate: 10.0, price: 500},
        providers: [fornecedor1, fornecedor2],
        autoCategory: 'Auto'
    }


    const fornecedor3 = {providerName: 'Fornecedor 3',  affiliateId: '0003', prices: [980, 965, '', '2024-07-14'],phoneNumber: {countryCode: '+55', areaCode:'19', number:'999741036'}};
    const fornecedor4 = {providerName: 'Fornecedor 4', affiliateId: '0004', prices: [970, 963, '', '2024-07-17'],  phoneNumber: {countryCode: '+55', areaCode:'11', number:'989450987'}};
    const retrovisor = {
        scenarioName: 'Retrovisor',
        items: [{itemName: 'UH04QRCUEP CITY 15/21 - RETROVISOR ELETR DIR', itemGroup: 'accessories'}],
        totalContacts: 3,
        quoteMasterMinimumRate: {rate: 5.0, price: 1200},
        minimumDesirableRate: {rate: 7.0, price: 958},
        optimalRate: {rate: 9.1, price: 865},
        maximumRate: {rate: 10.0, price: 772},
        providers: [fornecedor3, fornecedor4],
        autoCategory: 'Auto'
    }
    
    const fornecedor5 = { providerName: 'Fornecedor 5', affiliateId: '0005', prices: [260, 260, '', '2024-07-14' ]};
    const fornecedor6 = { providerName: 'Fornecedor 6',  affiliateId: '0006', prices: [260, 255, '', '2024-07-15']};
    const fornecedor7 = { providerName: 'Fornecedor 7',  affiliateId: '0007', prices: [270, 260, '', '2024-07-16']};
    const fornecedor8 = { providerName: 'Fornecedor 8',  affiliateId: '0008', prices: [265, 265, '','2024-07-17']};
    const vidroTraseiro = {
        scenarioName: 'Vidro Traseiro',
        items: [{SKU:'UROORGNH5RD', itemName: 'KWID 18/ON - VID PT TRAS DR VERDE', itemGroup: 'glasses'}],
        totalContacts: 4,
        quoteMasterMinimumRate: {rate: 5.0, price: 310},
        minimumDesirableRate: {rate: 7.0, price: 252},
        optimalRate: {rate: 9.1, price: 230},
        maximumRate: {rate: 10.0, price: 200},
        providers: [fornecedor5, fornecedor6, fornecedor7, fornecedor8],
    }
    
    const fornecedor9 = { providerName: 'Fornecedor 9', affiliateId: '0009', prices: [760, 720, '', '2024-07-18']};
    const fornecedor10 = { providerName: 'Fornecedor 10', affiliateId: '0010', prices: [775, 730, '', '2024-07-19']};
    const fornecedor11 = { providerName: 'Fornecedor 11', affiliateId: '0011', prices: [780, 760, '', '2024-07-20']};
    const fornecedor12 = { providerName: 'Fornecedor 12', affiliateId: '0012', prices: [790, 750, '', '2024-07-21' ]};
    const fornecedor13 = { providerName: 'Fornecedor 13', affiliateId: '0013', prices: [800, 780, '', '2024-07-22' ]};
    const farolEsquerdo = {
        scenarioName: 'Farol Esquerdo',
        items: [{SKU: '33MB16FRMLE', itemName: 'MOBI 17/ON - FAROL PRINC ESQ C/MASC NEGRA', itemGroup: 'accessories'}],
        totalContacts: 5,
        quoteMasterMinimumRate: {rate: 5.0, price: 900},
        minimumDesirableRate: {rate: 7.0, price: 772},
        optimalRate: {rate: 9.1, price: 700},
        maximumRate: {rate: 10.0, price: 608},
        providers: [fornecedor9, fornecedor10, fornecedor11, fornecedor12, fornecedor13],
    }


    const fornecedor14 = { providerName: 'Fornecedor 14', affiliateId: '0014', prices: [1600, 1500, '', '2024-07-23' ]};
    const fornecedor15 = { providerName: 'Fornecedor 15', affiliateId: '0015', prices: [1560, 1500, '', '2024-07-15']};
    const fornecedor16 = { providerName: 'Fornecedor 16', affiliateId: '0016', prices: [1720, 1550, '', '2024-07-19']};
    const fornecedor17 = { providerName: 'Fornecedor 17', affiliateId: '0017', prices: [1750, 1600, '', '2024-07-25' ]};
    const fornecedor18 = { providerName: 'Fornecedor 18', affiliateId: '0018', prices: [1780, 1650, '', '2024-07-21' ]};
    
    const lanternaEsquerda = {
        scenarioName: 'Lanterna Esquerda',
        items: [{SKU: '62022OLTHL1E', itemName: 'ONIX HATCH 20/ON - LANTERNA TRAS ESQ', itemGroup: 'accessories'}],
        totalContacts: 6,
        quoteMasterMinimumRate: {rate: 5.0, price: 1800},
        minimumDesirableRate: {rate: 7.0, price: 1671},
        optimalRate: {rate: 9.1, price: 1500},
        maximumRate: {rate: 10.0, price: 1230},
        providers: [fornecedor14, fornecedor15, fornecedor16, fornecedor17, fornecedor18],
    }
    
    const testScenarios = [parabrisa, retrovisor, vidroTraseiro, farolEsquerdo, lanternaEsquerda]
    
    const [testScenario, setTestScenario] = useState(null);
    const firstLoadCompleted = useRef(false);

    useImperativeHandle(FowardingRef, () => ({
        resetScenario: () => {
            setTestScenario(''); // Clear or reset the scenario
        }
    }));

    useEffect(() => {
        if(firstLoadCompleted.current){ // if the page is just loading for the first time
            if(!testScenario) return;
            const providers = testScenario.providers.map(provider => {
                const newProvider = new Provider(provider.providerName, provider.affiliateId); 
                newProvider.phoneNumber = provider.phoneNumber;
                newProvider.link = provider.link;
                return newProvider;
            });
            setCurrentServiceOrder({...currentServiceOrder, 
                scenarioName: testScenario.scenarioName, 
                items: testScenario.items, 
                totalContacts: testScenario.totalContacts, 
                quoteMasterMinimumRate: testScenario.quoteMasterMinimumRate,
                minimumDesirableRate: testScenario.minimumDesirableRate, 
                maximumRate: testScenario.maximumRate, 
                currentTarget:{ 
                    ...initialTarget,
                    price: testScenario.minimumDesirableRate.price, 
                    rate:testScenario.minimumDesirableRate.rate , 
                },
                autoCategory: testScenario.autoCategory, 
                providers: providers,
                bestQuoteProvider: providers[0].affiliateId,
            });
        } else {
            firstLoadCompleted.current = true;
        }
    }, [testScenario]);


    
    const selectionItems = [
        {value: parabrisa, label: parabrisa.scenarioName},
        {value: retrovisor, label:  retrovisor.scenarioName},
        {value: vidroTraseiro, label: vidroTraseiro.scenarioName},
        {value: farolEsquerdo, label: farolEsquerdo.scenarioName},
        {value: lanternaEsquerda, label: lanternaEsquerda.scenarioName},
    ]

    return (
        <SelectionBox 
            selectionItems={selectionItems} 
            mainFunction={setTestScenario} 
            mainValue={testScenario} 
            instanceName={'Cenários'} 
            readOnly={currentServiceOrder.currentStage!=='notInitiated'}
            />
            
    );
}
