
import React, {useContext, useState, useRef,useEffect } from 'react'

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import SelectionBox from 'GlobalComponents/SelectionBox/SelectionBox';

export default function LGPDSelect({readOnly}) {
    const {setCurrentServiceOrder, currentServiceOrder} = useContext(QuoteMasterContext);
    const [LGPDaccepted, setLGPDaccepted] = useState(currentServiceOrder.clientInformation.LGPDaccepted);
    const firstLoadCompleted = useRef(false);

    useEffect(() => {
        if(firstLoadCompleted.current && currentServiceOrder.currentStage === 'notInitiated' ){ // if the page is just loading for the first time
            const newServiceOrder = {...currentServiceOrder};
            const newClientInformation = {...newServiceOrder.clientInformation};
            newClientInformation.LGPDaccepted = LGPDaccepted;
            newServiceOrder.clientInformation = newClientInformation;
            setCurrentServiceOrder(newServiceOrder);
        } else {
            firstLoadCompleted.current = true;
        }
    }, [LGPDaccepted]);

    useEffect(() => {
        setLGPDaccepted(currentServiceOrder.clientInformation.LGPDaccepted);
    }, [currentServiceOrder.clientInformation.LGPDaccepted]);

    const selectionItems = [
        {value: true, label: 'Sim'},
        {value: false, label: 'Não'},
    ]
    return (
        <SelectionBox 
            selectionItems={selectionItems} 
            mainFunction={setLGPDaccepted} 
            mainValue={LGPDaccepted} 
            instanceName={'Aceite LGPD'}
            readOnly={readOnly}
        />
    );
}


