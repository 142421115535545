
import React, { useEffect, useState, useContext, useCallback } from 'react';

import styles from './ProvidersPanel.module.css';

import {getProvidersList, deleteProviderInDB} from '../ServerCalls/serverCalls.js';


import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js'
import ConfirmationModal from 'GlobalComponents/ConfirmationModal/ConfirmationModal.js';
import ProviderModal from './ProviderModal.js';
import WaitingProgressLine from 'GlobalComponents/WaitingProgressLine/WaitingProgressLine.js';

import RestorePageOutlinedIcon from '@mui/icons-material/RestorePageOutlined';
import Icon from 'GlobalComponents/Icon/Icon.js';
import TelephoneInput from '../GlobalComponents/TelephoneInput/TelephoneInput.js';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

export default function ProvidersPanel({setIsProvidersPanelOpen, setIsProvidersReportOpen}) {
    const {setLoading, providersList, setProvidersList,} = useContext(QuoteMasterContext);
    const [providerToDelete, setProviderToDelete] = useState('');
    const [providerToEdit, setProviderToEdit] = useState('');
    const [providerModalInstance, setProviderModalInstance] = useState('');
   
    useEffect(() => {
        if(providersList.length === 0){
            updateProvidersList();
        }
    }, []);

    async function updateProvidersList() {
        setLoading(true);
        const response = await getProvidersList();
        if (response === 'failed') {
            console.log('Failed to get providers list');
            setLoading(false);
            return;
        }
        setProvidersList(response.providers);
        setLoading(false);
    }

    const deleteProvider = async () => {
        try{
            setLoading(true);
            const response = await deleteProviderInDB(providerToDelete.affiliateId, providerToDelete.providerName);
            if (response !== 'Provider deleted successfully'){
                alert('Error in deleting provider:', response);
                setLoading(false);
                return;
            }
            setProvidersList(prev=>prev.filter(provider=>provider.affiliateId!==providerToDelete.affiliateId));
            setProviderToDelete('');
            setLoading(false);
        } catch(error){
            console.error('Error in deleting provider:', error);
        }
    }

    const openNewProviderModalForNewAffiliate = () => {
        const newProvider = {
            providerName: '',
            affiliateId: '',
            phoneNumber: {
                countryCode: '', 
                areaCode: '',
                number: '',
            },
            validWhatsapp: false,
            serviceOrders: [],
            status: 'active',
            responsibles: [],
        }
        const newProvidersList = [...providersList];
        newProvidersList.push(newProvider);
        setProvidersList(newProvidersList);
        setProviderModalInstance('newProvider');
        setProviderToEdit(newProvider);
    }

    const providerDeleteConfirmationMessage =
            `Está certo que quer deletar o Afiliado ${providerToDelete.providerName}? 
            \n Esta ação não pode ser desfeita.`;

    const providerDeleteButtons = [
        {text: 'Cancelar', onClick: ()=>setProviderToDelete(''), width: 100},
        {text: 'Deletar', onClick: deleteProvider, width: 100}
    ];

    return (
        <div className={styles.providersPanel}>
            <div className={styles.providersPanelHeader}>
                <div className={styles.providersPanelButtons}>
                    <GlobalButton
                        onClick={() => setIsProvidersPanelOpen(false)}
                        label={'Fechar Painel de Afiliados'}
                        width={'184px'}
                        type={'secondary'}
                    />           
                    <GlobalButton 
                        onClick={openNewProviderModalForNewAffiliate} 
                        label={'Criar novo Afiliado'} 
                        width={'184px'} 
                        type={'primary'}    
                    />                
                </div>
                <div className={styles.providersPanelTitle}>Painel de Afiliados</div>
                <div className={styles.providersPanelButtons}>
                    <GlobalButton 
                        onClick={updateProvidersList} 
                        label={
                            <> 
                                <RestorePageOutlinedIcon /> 
                                <span>Atualizar Lista <br/>de Afiliados</span>
                            </>
                        }
                        width={'184px'}
                        type={'secondary'}
                    />
                    <GlobalButton 
                        onClick={() => setIsProvidersReportOpen(true)} 
                        label={
                            <span>Relatório<br/>de Afiliados</span>
                        }
                        width={'184px'}
                        type={'secondary'}
                    />
                </div>
            </div>

            <ProvidersList providersList={providersList} setProvidersList={setProvidersList} setProviderToDelete={setProviderToDelete} setProviderToEdit={setProviderToEdit} setProviderModalInstance={setProviderModalInstance}/>
            {providerToDelete ? <ConfirmationModal message={providerDeleteConfirmationMessage} buttons={providerDeleteButtons}/> : null}
            {providerToEdit && <ProviderModal setProvidersList={setProvidersList} providerToEdit={providerToEdit} setProviderToEdit={setProviderToEdit} instance={providerModalInstance}/>}
        </div>

    )
}

function ProvidersList({providersList, setProvidersList, setProviderToDelete, setProviderToEdit, setProviderModalInstance }) {
    const {loading} = useContext(QuoteMasterContext);

    const orderProvidersList = useCallback((field) => {
        const sortedProvidersList = [...providersList];
        switch(field){
            case 'phoneNumber':
                sortedProvidersList.sort((a,b) => {
                    if (a.phoneNumber.countryCode > b.phoneNumber.countryCode) return 1;
                    if (a.phoneNumber.countryCode < b.phoneNumber.countryCode) return -1;
                    if (a.phoneNumber.areaCode > b.phoneNumber.areaCode) return 1;
                    if (a.phoneNumber.areaCode < b.phoneNumber.areaCode) return -1;
                    if (a.phoneNumber.number > b.phoneNumber.number) return 1;
                    if (a.phoneNumber.number < b.phoneNumber.number) return -1;
                    return 0;
                });	
                break;
            case 'numberOfServiceOrders':
                sortedProvidersList.sort((a,b) => a.serviceOrders.length - b.serviceOrders.length);
                break;
            default:
                sortedProvidersList.sort((a,b) => {
                    if (a[field] > b[field]) return 1;
                    if (a[field] < b[field]) return -1;
                    return 0;
                });
        }
        setProvidersList(sortedProvidersList);
    }, [providersList]);

    return (
        <div className={styles.providersList}>
            <div className={styles.providersListHeader}>
                <div className={styles.providerName} style={{ cursor: 'pointer' }} onClick={()=>orderProvidersList('providerName')}>Nome</div>
                <div className={styles.affiliateId} style={{ cursor: 'pointer' }} onClick={()=>orderProvidersList('affiliateId')}>Affiliate Id</div>
                <div className={styles.phoneNumber} style={{ cursor: 'pointer' }} onClick={()=>orderProvidersList('phoneNumber')}>Telefone</div>
                <div className={styles.validWhatsapp} style={{ cursor: 'pointer' }} onClick={()=>orderProvidersList('validWhatsapp')}>Whatsapp Válido</div>
                <div className={styles.numberOfServiceOrders} style={{ cursor: 'pointer' }} onClick={()=>orderProvidersList('numberOfServiceOrders')}>Ordens de Serviço</div>
                <div className={styles.providerStatus} style={{ cursor: 'pointer' }} onClick={()=>orderProvidersList('providerStatus')}>Status</div>
                <div className={styles.editProvider} >Editar</div>
                <div className={styles.editProvider}>Deletar</div>
            </div>
            {loading && <WaitingProgressLine />}
            {providersList.map((provider, index) => (
                <ProviderLine provider={provider} key={index} setProvidersList={setProvidersList} setProviderToDelete={setProviderToDelete} setProviderToEdit={setProviderToEdit} setProviderModalInstance={setProviderModalInstance}/>
            ))}

        </div>
    )
}

function ProviderLine({provider, setProvidersList, setProviderToDelete, setProviderToEdit, setProviderModalInstance}) {

    function deleteProvider() {
        setProviderToDelete(provider);
    }

    async function handleChangeProviderStatus(newStatus) {
        const response = await changeUserStatus(provider.affiliateId, newStatus);
        if (response === 'failed') {
            console.log(`Failed to change provider status to ${newStatus}`);
            return;
        }
        setProvidersList(prevUserList => prevUserList.map(u => u.affiliateId === provider.affiliateId ? {...u, status: newStatus} : u));
    }

    return (
        <div className={styles.provider}>
            <div className={styles.providerName}>{provider.providerName}</div>
            <div className={styles.affiliateId}>{provider.affiliateId}</div>
            <div className={styles.phoneNumber}> {<TelephoneInput provider={provider} readOnly={true} instance={'providersPanel'} type={'phoneNumber'}/>} </div>
            <div className={styles.validWhatsapp}>{provider.validWhatsapp ? <CheckCircleIcon style={{color: 'green'}}/> : <ErrorIcon style={{color: 'red'}}/>}</div>
            <div className={styles.numberOfServiceOrders}>{provider.serviceOrders?provider.serviceOrders.length : 0}</div>
            <div className={styles.providerStatus}>{provider.status}</div>
            <div className={styles.editProvider}><Icon originalIcon= {'editProvider'} size={24} onClick={()=>{setProviderToEdit(provider); setProviderModalInstance('providersPanel'); }}/></div>
            <div className={styles.editProvider}><Icon originalIcon= {'deleteProvider'} size={24} onClick= {deleteProvider}/></div>
        </div>
    )
}