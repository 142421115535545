
import React, {useContext} from 'react'
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';   
import {SocketContext} from 'Contexts/SocketContext.js';


import {runServiceOrder} from 'ServerCalls/serverCalls.js';
import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js';
import moment from 'moment-timezone';

import {timeZone} from 'Contexts/static_options/Classes.js'

export default function RunServiceOrderButton({}){
    const { currentServiceOrder, setCurrentServiceOrder, serviceOrders, setServiceOrders, setLoading} = useContext(QuoteMasterContext);
    const {socket, socketCallBack} = useContext(SocketContext); 
    const runTest = async () => {
        function scenarioIsValid(){
            if(currentServiceOrder.items.some(item => !item.itemName) ){
                alert('All items must have a name');
                return false;
            }
            if(currentServiceOrder.minimumDesirableRate.price === 0){
                alert('O preço na nota desejável deve ser maior que 0');
                return false;
            }
            if(currentServiceOrder.quoteMasterMinimumRate.price === 0){ 
                alert('A alçada do QuoteMaster deve ser maior que 0');
                return false;
            }
            if(currentServiceOrder.maximumRate.price === 0){
                alert('O preço na nota máxima deve ser maior que 0');
                return false;
            }
            if(currentServiceOrder.minimumDesirableRate.rate === 0){
                alert('A nota desejável deve ser maior que 0');
                return false;
            }
            if(currentServiceOrder.quoteMasterMinimumRate.rate === 0){
                alert('A alçada do QuoteMaster deve ser maior que 0');
                return false;
            }
            if(currentServiceOrder.maximumRate.rate === 0){
                alert('A nota máxima deve ser maior que 0');
                return false;
            }
            if (parseFloat(currentServiceOrder.minimumDesirableRate.rate) <= parseFloat(currentServiceOrder.quoteMasterMinimumRate.rate)) {
                alert('A nota alçada do QuoteMaster deve ser menor que a nota desejável');
                return false;
            }

            if (parseFloat(currentServiceOrder.maximumRate.rate) <= parseFloat(currentServiceOrder.minimumDesirableRate.rate)){
                alert('A nota máxima deve ser maior que a nota desejável'); 
                return false;
            }

            if (parseFloat(currentServiceOrder.minimumDesirableRate.price) >= parseFloat(currentServiceOrder.quoteMasterMinimumRate.price)) {
                alert('O preço na nota desejável deve ser menor que o preço na nota mínima do QuoteMaster');
                return false;
            }                       
            if (parseFloat(currentServiceOrder.maximumRate.price) >= parseFloat(currentServiceOrder.minimumDesirableRate.price)){
                alert('O preço na nota máxima deve ser menor que o preço na nota desejável'); 
                return false;
            }
            if(!currentServiceOrder.maximumRateDiscount || isNaN(currentServiceOrder.maximumRateDiscount)){
                alert('A taxa de desconto máxima deve ser um número maior que 0');
                return false;
            }
            if (currentServiceOrder.items.some(item => !item.itemGroup)){
                alert('Todos os itens devem ter um grupo');
                return false;
            }
            if(!currentServiceOrder.autoInformation.autoCategory){
                alert('Selecione a categoria do veículo');
                return false;
            }
            if(!currentServiceOrder.autoInformation.autoPlate){
                alert('Preencha a placa do veículo');
                return false;
            }
            if(!currentServiceOrder.autoInformation.autoModel){
                alert('Preencha o modelo do veículo');
                return false;
            }
            if(!currentServiceOrder.autoInformation.autoYear){
                alert('Preencha o ano do veículo');
                return false;
            }
            if(!currentServiceOrder.autoInformation.autoChassis){
                alert('Preencha o chassi do veículo');
                return false;
            }
            if(!currentServiceOrder.clientInformation.clientName){
                alert('Preencha o nome do cliente');
                return false;
            }
            if(!currentServiceOrder.clientInformation.clientPhoneNumber){
                alert('Preencha o número de telefone do cliente');
                return false;
            }
            if(!currentServiceOrder.clientInformation.LGPDaccepted){
                alert('Preencha se o cliente aceitou os termos de privacidade');
                return false;
            }
            if(!currentServiceOrder.serviceAddress.latitude || !currentServiceOrder.serviceAddress.longitude){
                alert('Preencha informação de latitude e longitude do endereço do serviço');
                return false;
            }
            let quotesOk = true;

            for (const provider of currentServiceOrder.providers) {
                if (!provider.providerName) {
                    alert('Preencha o nome de todos os afiliados');
                    quotesOk = false;
                    break;
                }
                if (!provider.affiliateId) {
                    alert('Preencha o ID de todos os afiliados');
                    quotesOk = false;
                    break;
                }
                if(!provider.phoneNumber){
                    alert(`Preencha o número de telefone do afiliado ${provider.providerName}`);
                    quotesOk = false;
                    break;
                }
                if(provider.phoneNumber.countryCode === ''){
                    alert(`Preencha o código do país do afiliado ${provider.providerName}`);
                    quotesOk = false;
                    break;
                }
                if(provider.phoneNumber.areaCode === '' && provider.phoneNumber.countryCode === '+55' ){
                    alert(`Preencha o código de área do afiliado ${provider.providerName}, telefone: ${provider.phoneNumber.countryCode} ${provider.phoneNumber.areaCode} ${provider.phoneNumber.number}`);
                    quotesOk = false;
                    break;
                }
                if(provider.phoneNumber.number === ''){
                    alert(`Preencha o número de telefone do afiliado ${provider.providerName}`);
                    quotesOk = false;
                    break;
                }
                if(provider.quoteViaLink && !provider.providerBestQuote.completed){
                    // test which  field is missing in case the provider has a link response
                    const quoteViaLink = provider.providerBestQuote;
                    if(quoteViaLink.shortestAvailability.date===''){
                        alert(`Preencha a data de disponibilidade informada pelo afiliado ${provider.providerName}`);
                        quotesOk = false;
                        break;
                    }
                    // console.log('quoteViaLink', quoteViaLink)
                    const itemQuotes = provider.itemQuotes.filter((itemQuote, index) => quoteViaLink.itemQuotesIndexes.includes(index));
                    for(const itemQuote of itemQuotes){
                        if(itemQuote.price===0){
                            alert(`Preencha o preço informado pelo afiliado ${provider.providerName} para o item: \n\n -${itemQuote.item.itemName}`);
                            quotesOk = false;
                            break;
                        }
                        if(itemQuote.shortestAvailability.availability===''){
                            alert(`Preencha a disponibilidade informada pelo afiliado ${provider.providerName} para o item: \n\n - ${itemQuote.item.itemName}`);
                            quotesOk = false;
                            break;
                        }
                        if(quoteViaLink.brand.tier===''){
                            alert(`Preencha a marca informada pelo afiliado ${provider.providerName} para o  item: \n\n - ${itemQuote.item.itemName}`);
                            quotesOk = false;
                            break;
                        }
                    }
                    // console.log('provider', currentServiceOrder.providers[providerIndex])
                    if(provider.providerBestQuote.bookingTime.FIFO === ''){
                        alert(`Preencha se o serviço é por ordem de chegada ou hora marcada para o afiliado: ${provider.providerName}`);
                        quotesOk = false;
                        break;
                    }
                    if(provider.providerBestQuote.bookingTime.time === ''){
                        alert(provider.providerBestQuote.bookingTime.FIFO ? `Preencha o horário de abertura da loja do afiliado: ${provider.providerName}` :'Preencha a hora marcada para o serviço');
                        quotesOk = false;
                        break;
                    }
                }
                if(provider.quoteViaLink){
                    provider.itemQuotes.forEach(itemQuote => {
                        itemQuote.quoteReceivedAtTime = moment().tz(timeZone).format();
                    });
                }
            };
            return quotesOk;
        }


        if(scenarioIsValid()){
            if(socket && socket.connected){
                runServiceOrderCall();
            } else {
                socketCallBack.current = runServiceOrderCall;
                socket.connect();
            }
        }

    }

    async function runServiceOrderCall(){
        setLoading(true);
        console.log('currentServiceOrder before runServiceOrder', currentServiceOrder);
        const response = await runServiceOrder(currentServiceOrder);
        console.log('response from runServiceOrder', response);
        if(response === 'Unable to Run Scenario'){
            alert('Error in running scenario:', response.message);            
            setLoading(false);
            return
        } 
        
        setCurrentServiceOrder(response.serviceOrder)
        const shortenedServiceOrder = {
            serviceOrderId:response.serviceOrder.serviceOrderId, 
            currentStage:response.serviceOrder.currentStage, 
            timeLastUpdate:response.serviceOrder.timeLastUpdate,
            user: response.serviceOrder.user,
            providers: response.serviceOrder.providers.map(provider => ({providerName: provider.providerName, affiliateId: provider.affiliateId})),
            runType: response.serviceOrder.runType,
        };
        const newServiceOrders = [...serviceOrders];
        newServiceOrders.push(shortenedServiceOrder);
        setServiceOrders(newServiceOrders);
        setLoading(false);
    }

    return(
        <GlobalButton 
            onClick={runTest} 
            label={'Iniciar Cotação'} 
            type={'primary'}
        />
    )
}



