import React, {useContext } from 'react'

import styles from './DetailInput.module.css';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';  

export default function DetailInput({width, inputElement, readOnly}){
    const {currentServiceOrder} = useContext(QuoteMasterContext);

    const standardStyles = () => {
        if(readOnly===false){
            return {}
        }
        const nonEditableInputStyles = {
            backgroundColor: 'var(--background-non-editable)',
            pointerEvents: 'none',
            color: 'var(--letter-dark)',
            readOnly: readOnly
        };

        if(currentServiceOrder.currentStage!=='notInitiated'){
            return nonEditableInputStyles
        }
        if(currentServiceOrder.runType === 'standardTest'){
            return nonEditableInputStyles
        }
        if(readOnly){
            return nonEditableInputStyles
        }   
   
        return {}
    }

        
    return(
        <div 
            className={styles.detailInput} 
            style={{width: width, ...standardStyles()}} 
        >
            {inputElement}
        </div>
    )
}



