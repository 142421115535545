import React, {useContext} from 'react';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

export default function OpenProviderDetailsButton({provider}){
    const {setProviderQuoteDetails, currentServiceOrder} = useContext(QuoteMasterContext);

    const onClick = () => {
        if(!provider.affiliateId){
            alert('Afiliado não possui ID');
            return;
        }
        let list = 'providers';
        let index = currentServiceOrder.providers.findIndex((prov) => prov.affiliateId === provider.affiliateId);
        if (index === -1){
            index = currentServiceOrder.providersNotConsidered.findIndex((prov) => prov.affiliateId === provider.affiliateId);
            list = 'providersNotConsidered';
            if(index === -1){
                alert('Afiliado não encontrado');
                return;
            }
        }
        setProviderQuoteDetails({providerIndex:index, list:list});
    }

    return(
        <div onClick={onClick} 
            style={{
                display: 'flex', 
                flexDirection:'column', 
                alignItems:'center', 
                width: '24px',
                color: 'var(--letter-dark)', 
                cursor: 'pointer',
            }}>
            <Tooltip title={'Detalhes '+ provider.providerName} placement="bottom">
                <InfoOutlinedIcon/>
            </Tooltip>
        </div>
    )
}
