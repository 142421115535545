import React, { useContext } from 'react';

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js'; 
import {deleteServiceOrderInDB} from 'ServerCalls/serverCalls.js';
import ConfirmationModal from 'GlobalComponents/ConfirmationModal/ConfirmationModal.js';

export default function SODeletionConfirmation({}){
    const {setSOToDelete, SOToDelete, setCurrentServiceOrder, setServiceOrders, currentServiceOrder, freshServiceOrder, setLoading} = useContext(QuoteMasterContext);

    const deleteServiceOrder = async () => {
        setLoading(true);
        try{
            const response = await deleteServiceOrderInDB(SOToDelete);
            if (response.message !== 'Service Order deleted successfully'){
                alert('Error in deleting service order:', response.message);
                return;
            }
            if(currentServiceOrder && currentServiceOrder.serviceOrderId === SOToDelete){
                setCurrentServiceOrder(freshServiceOrder);                
            }     
            setServiceOrders(prev=>prev.filter(serviceOrder=>serviceOrder.serviceOrderId!==SOToDelete));

        } catch(error){
            console.error('Error in deleting service order:', error);
        }
        close();
        setLoading(false);
    }

    const close = () => {
        setSOToDelete(false);
    }

    const message = `Are you sure you want to delete the service order ${SOToDelete}? \n
                    This action cannot be undone.`;

    const OSDeletionButtons = [
        {
            text: 'Cancel',
            width: 100,
            onClick: close
        },
        {
            text: 'Delete',
            width: 100,
            onClick: deleteServiceOrder
        }
    ]
  
    return(
        <ConfirmationModal
            message={message}
            buttons={OSDeletionButtons}
        />

    )
}