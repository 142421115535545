// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.X6dbV6iRa5SiglaScwhC{
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: flex-start;    
    height: auto;
    height: 100%;
    overflow: hidden;
}

.sX40qX0Hd82UuRnDnUsS{
    display: flex;
    width: 100%;
}

.x2DUUSgq_KzKbbPsa6Uu{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    box-sizing: border-box;
    overflow-y: hidden;
    overflow-x: visible;
    padding: 8px;
    margin: 0px 0px 4px 4px;
    height: auto;
    width: 100%;
    flex-grow: 1;

}

.MnjbgvGcR9Cqe_66CCO2{
    display: flex;
    flex-direction: column;
    list-style-type: none; 
    padding: 0;
    align-items: center;
    font-size: 12px;
    border-radius: 16px;
    border: none;
    box-sizing: border-box;
    background-color: transparent;
    height: 100%;

}`, "",{"version":3,"sources":["webpack://./clientRoot/Static/WorkingArea/ServiceOrderManagement/ChatWindows/ChatWindows.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,oBAAoB;IACpB,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,YAAY;;AAEhB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,UAAU;IACV,mBAAmB;IACnB,eAAe;IACf,mBAAmB;IACnB,YAAY;IACZ,sBAAsB;IACtB,6BAA6B;IAC7B,YAAY;;AAEhB","sourcesContent":[".conversations{\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    box-sizing: border-box;\n    align-items: flex-start;    \n    height: auto;\n    height: 100%;\n    overflow: hidden;\n}\n\n.chatManagement{\n    display: flex;\n    width: 100%;\n}\n\n.conversationsContainer{\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: stretch;\n    box-sizing: border-box;\n    overflow-y: hidden;\n    overflow-x: visible;\n    padding: 8px;\n    margin: 0px 0px 4px 4px;\n    height: auto;\n    width: 100%;\n    flex-grow: 1;\n\n}\n\n.chatBox{\n    display: flex;\n    flex-direction: column;\n    list-style-type: none; \n    padding: 0;\n    align-items: center;\n    font-size: 12px;\n    border-radius: 16px;\n    border: none;\n    box-sizing: border-box;\n    background-color: transparent;\n    height: 100%;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"conversations": `X6dbV6iRa5SiglaScwhC`,
	"chatManagement": `sX40qX0Hd82UuRnDnUsS`,
	"conversationsContainer": `x2DUUSgq_KzKbbPsa6Uu`,
	"chatBox": `MnjbgvGcR9Cqe_66CCO2`
};
export default ___CSS_LOADER_EXPORT___;
