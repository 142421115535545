import React, {useContext} from 'react'

import styles from 'GlobalComponents/CommonStyles.module.css';

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';   

export default function NotesView({}){
    const {currentServiceOrder} = useContext(QuoteMasterContext);

    return (
        <div className={styles.mainRow}> Sumário da Ordem de Serviço
            <div 
                className={styles.OSsummary} 
                dangerouslySetInnerHTML={{ 
                    __html: currentServiceOrder.notes.replace(/\n/g, '<br />') 
                }}
            />        
        </div>
    )
}