
import React, {useContext, useState, useRef,useEffect } from 'react'

import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';   
import SelectionBox from 'GlobalComponents/SelectionBox/SelectionBox.js';

const selectionItems = [
    {value: '+55', label: '+55 - Brasil'},
    {value: '+61', label: '+61 - Austrália'},
]

export default function CountryCodeSelect({readOnly, instance, provider, width='100px', type}) {
    const {setCurrentServiceOrder, currentServiceOrder, providersList, setProvidersList} = useContext(QuoteMasterContext);
    const [countryCode, setCountryCode] = useState('');
    const firstLoadCompleted = useRef(false);

    useEffect(()=>{
        if(type==='phoneNumber'){
            setCountryCode(provider.phoneNumber.countryCode);
        } else {
            setCountryCode(provider.whatsappSource.formattedSource.countryCode);
        }
    },[])
    
    useEffect(() => {
        // if the page is just loading for the first time
        if(!firstLoadCompleted.current){
            // we just return here
            firstLoadCompleted.current = true;
            return;
        }

        // if the service order has already been initiated we don't allow the user to change the country code
        if(currentServiceOrder.currentStage !== 'notInitiated'){
            return;
        }

        switch(instance){
            case 'providersList':
            case 'chat':{
                const newCurrentServiceOrder = {...currentServiceOrder};
                const providerIndex = currentServiceOrder.providers.findIndex((prov) => prov.providerName===provider.providerName);
                if(providerIndex === -1){
                    return;
                }
                const newProvider = newCurrentServiceOrder.providers[providerIndex];
                newProvider.phoneNumber.countryCode = countryCode;
                setCurrentServiceOrder(newCurrentServiceOrder);
                break;
            }
                
            case 'providersPanel':{
                const newProvidersList = [...providersList];
                const providerIndex = providersList.findIndex((prov) => prov.affiliateId===provider.affiliateId);
                const newProvider = newProvidersList[providerIndex];
                newProvider.phoneNumber.countryCode = countryCode;
                setProvidersList(newProvidersList);
                break;
            }

            case 'newProvider':{
                const newProvidersList = [...providersList];
                const providerIndex = providersList.length-1;
                const newProvider = newProvidersList[providerIndex];
                newProvider.phoneNumber.countryCode = countryCode;
                setProvidersList(newProvidersList);
                break
            }

        }
        
    }, [countryCode]);


    useEffect(() => {
        switch(instance){
            case 'providersList':
            case 'chat':{
                let providerIndex = currentServiceOrder.providers.findIndex((prov) => prov.providerName===provider.providerName);
                let list;
                if(providerIndex === -1){
                    providerIndex = currentServiceOrder.providersNotConsidered.findIndex((prov) => prov.providerName===provider.providerName);
                    if(providerIndex === -1){
                        return;
                    }
                    list = currentServiceOrder.providersNotConsidered;
                } else {
                    list = currentServiceOrder.providers;
                }
                const currentProvider = list[providerIndex];
                if(type==='phoneNumber'){
                    setCountryCode(currentProvider.phoneNumber.countryCode);
                } else{
                    setCountryCode(currentProvider.whatsappSource.formattedSource.countryCode);
                }

                break;
            }
            case 'providersPanel':
            case 'newProvider':	{
                let providerIndex;
                if(instance==='providersPanel'){
                    providerIndex = providersList.findIndex((prov) => prov.affiliateId===provider.affiliateId);
                } else {
                    providerIndex = providersList.length-1;
                }
                const currentProvider = providersList[providerIndex];
                if(type==='phoneNumber'){
                    setCountryCode(currentProvider.phoneNumber.countryCode);
                } else{
                    setCountryCode(currentProvider.whatsappSource.formattedSource.countryCode);
                }

                break;
            }
        }
    }, [currentServiceOrder.providers, providersList]);



    return (
        <SelectionBox 
            selectionItems={selectionItems} 
            mainFunction={setCountryCode} 
            mainValue={countryCode} 
            instanceName={'País'} 
            readOnly={readOnly}
            noWrap={false}
            width={width}
        />
    );
}


