// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.LNuVbAeDWZdk06KQCNjI{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.LNuVbAeDWZdk06KQCNjI:hover{
    background-color: var(--background-hovered-button);
}

.jyoMh9ik6nQzj1JpsCcK{
    color: var(--icons);
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./clientRoot/Static/GlobalComponents/Icon/Icon.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,kDAAkD;AACtD;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":["\n.iconWrapper{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50%;\n}\n\n.iconWrapper:hover{\n    background-color: var(--background-hovered-button);\n}\n\n.icon{\n    color: var(--icons);\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconWrapper": `LNuVbAeDWZdk06KQCNjI`,
	"icon": `jyoMh9ik6nQzj1JpsCcK`
};
export default ___CSS_LOADER_EXPORT___;
