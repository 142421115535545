import React, { useCallback, useContext, useEffect } from 'react';
import styles from 'GlobalComponents/CommonStyles.module.css';
import modalStyles from './ProviderQuoteDetails.module.css';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';

import LabelElement from 'GlobalComponents/LabelElement/LabelElement.js';
import moment from 'moment';
import NumberInput from 'GlobalComponents/NumberInput/NumberInput';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput';
import DateElement from 'GlobalComponents/DateInput/DateElement';
import AvailabilitySelect from 'GlobalComponents/SelectionBox/SelectionBoxesInstances/AvailabilitySelect.js';
import FIFOSelect from 'GlobalComponents/SelectionBox/SelectionBoxesInstances/FIFOSelect.js';
import TimeDropdown from 'GlobalComponents/SelectionBox/SelectionBoxesInstances/TimeDropdown.js';
import LinkReponseSwitch from './LinkResponseSwitch.js';
import {timeZone} from 'Contexts/static_options/Classes.js'
import Tooltip from '@mui/material/Tooltip';
import TextInput from 'GlobalComponents/TextInput/TextInput.js';
import {convertISODateToReadableString2} from 'Contexts/Utils/formatTime.js';
import MobileServiceSwitch from './MobileServiceSwitch.js';


export default function ProviderBestQuoteDetails({ providerIndex, setRemoveLinkResponseConfirmationIndex}){      
    const {currentServiceOrder, setCurrentServiceOrder} = useContext(QuoteMasterContext);
    
    const handleDateChange = useCallback((providerIndex, dateIndex) => (event) => {// this will only be used for stage 1 quotes when the quote is via link and we can manually change the date
        console.log('handleDateChange called')
        const today = moment().tz(timeZone).format('YYYY-MM-DD');
        const targetValue = event.target.value;
        const days = moment(targetValue).diff(moment(today), 'days')
        const newServiceOrder = {...currentServiceOrder};
        const newProvider = newServiceOrder.providers[providerIndex];
        let relativeDays = days;
        const tier = currentServiceOrder.providers[providerIndex].providerBestQuote.brand.tier;

        if(dateIndex === 'main'){ // this is the main date
            if(tier=== 2){
                relativeDays += currentServiceOrder.tier2Penalty;
            } 
            const availability = currentServiceOrder.providers[providerIndex].providerBestQuote.shortestAvailability.availability;
           if (availability === 'needToOrder'){
                relativeDays += currentServiceOrder.orderPenalty;
            } 
                
            newProvider.providerBestQuote.shortestAvailability = {
                date: targetValue,
                days: days,
                relativeDays: relativeDays,
                availability: currentServiceOrder.providers[providerIndex].providerBestQuote.shortestAvailability.availability 
            }
        } else if(dateIndex === 0 || dateIndex === 1){ // these are the date options
            newProvider.providerBestQuote.dateOptions[dateIndex].date = targetValue;
        }       

        setCurrentServiceOrder(newServiceOrder);
    }, [currentServiceOrder.providers[providerIndex].itemQuotes, providerIndex]);

    useEffect(() => {
        if(currentServiceOrder.currentStage === 'notInitiated' && currentServiceOrder.providers[providerIndex].quoteViaLink){
            const newServiceOrder = {...currentServiceOrder};
            const newProvider = newServiceOrder.providers[providerIndex];
            console.log('newProvider:', newProvider);
            newProvider.providerBestQuote.shortestAvailability = newProvider.itemQuotes.reduce((acc, itemQuote) => {
                if(itemQuote.shortestAvailability.relativeDays > acc.relativeDays){
                    return itemQuote.shortestAvailability;
                } else {
                    return acc;
                }
            },  newProvider.itemQuotes[0] ? newProvider.itemQuotes[0].shortestAvailability : {date: '', days: 0, relativeDays: 0, availability: ''});
            const selectedItemQuotes = newProvider.itemQuotes.filter((itemQuote, index) => newProvider.providerBestQuote.itemQuotesIndexes.includes(index));
            console.log('selectedItemQuotes:', selectedItemQuotes);
            newProvider.providerBestQuote.completed = selectedItemQuotes.every(itemQuote => itemQuote.completed);
            setCurrentServiceOrder(newServiceOrder);
        }
    }, [currentServiceOrder.providers[providerIndex]]);



    return(
        <>
            <div className={modalStyles.quotesBox} >  
                <div className={modalStyles.boxTitle}>Melhor Cotação do Afiliado</div>                
                <div className={styles.parameterRow}>
                    <LabelElement width={'88px'} label={'Preço'} />
                    <LabelElement width={'60px'} label={'Linha'} />
                    <LabelElement width={'136px'} label={'Disponibilidade'} />
                    <LabelElement width={'104px'} label={'Melhor Data'} />
                    <LabelElement width={'130px'} label={currentServiceOrder.providers[providerIndex].providerBestQuote.bookingTime.FIFO===true ? 'Abertura da Loja' : currentServiceOrder.providers[providerIndex].providerBestQuote.bookingTime.FIFO===false ? 'Hora Marcada': 'Hora'} />
                    <LabelElement width={'90px'} label={'Serviço Móvel'} />
                    <LabelElement width={'156px'} label={'Atendimento Por'} />
                    <Tooltip 
                        title={
                            currentServiceOrder.providers[providerIndex].providerBestQuote.brand.tier===2 || currentServiceOrder.providers[providerIndex].providerBestQuote.shortestAvailability.availability==='needToOrder' ?
                                currentServiceOrder.providers[providerIndex].providerBestQuote.brand.tier===2 && currentServiceOrder.providers[providerIndex].providerBestQuote.shortestAvailability.availability==='needToOrder' ?
                                    "Considera uma penalidade de 7 dias por marcas de segunda linha e 3 dias para encomendadas" :
                                    currentServiceOrder.providers[providerIndex].providerBestQuote.brand.tier===2 ?
                                        "Considera uma penalidade de 7 dias caso haja produtos de segunda linha na cotação" :
                                        "Considera uma penalidade de 3 dias caso haja produtos encomendados na cotação"
                            :''
                        }
                        placement={'bottom'} 
                    >
                        <div>
                            <LabelElement width={'48px'} label={currentServiceOrder.providers[providerIndex].providerBestQuote.brand.tier===2 || currentServiceOrder.providers[providerIndex].providerBestQuote.shortestAvailability.availability==='needToOrder' ? "Dias*":'Dias'} />
                        </div>
                    </Tooltip>
                    <LabelElement width={'96px'} label={'Status'} />
                    <LabelElement width={'136px'} label={'Cotado em'} />
                    <LabelElement width={'90px'} label={'Resposta ao Link'} />
                </div>
            <ProviderBestQuoteMainLine providerIndex={providerIndex} setRemoveLinkResponseConfirmationIndex={setRemoveLinkResponseConfirmationIndex} handleDateChange={handleDateChange}/>
                <DateOptions providerIndex={providerIndex} handleDateChange={handleDateChange}/>
            </div>
        </>
    )
}

function ProviderBestQuoteMainLine({providerIndex, setRemoveLinkResponseConfirmationIndex , handleDateChange}){
    const {currentServiceOrder} = useContext(QuoteMasterContext);
    const readOnlyDefault = !currentServiceOrder.providers[providerIndex].quoteViaLink || currentServiceOrder.currentStage!=='notInitiated';
    return(
        <div className={styles.parameterRow} > 
            <DetailInput width={'88px'} readOnly={true} inputElement={ <NumberInput inputValue={currentServiceOrder.providers[providerIndex].providerBestQuote.price} onChange={()=>{}} readOnly={true} spin={false}  preventingDefault={true} />}/>
            <DetailInput width={'60px'} readOnly={true} inputElement={ <NumberInput inputValue={currentServiceOrder.providers[providerIndex].providerBestQuote.brand.tier} readOnly={true} onChange={()=>{}} />} /> 
            <DetailInput width={'136px'} readOnly={true} inputElement={<AvailabilitySelect itemQuote={currentServiceOrder.providers[providerIndex].providerBestQuote} readOnly={true} providerIndex={providerIndex} />} />
            <DetailInput width={'104px'} readOnly={currentServiceOrder.currentStage==='notInitiated' && currentServiceOrder.providers[providerIndex].quoteViaLink ? false : true}
                inputElement={<DateElement inputValue={currentServiceOrder.providers[providerIndex].providerBestQuote.shortestAvailability.date} onChange={handleDateChange(providerIndex, 'main')} />}
            />
            <DetailInput
                width={'130px'}
                readOnly={readOnlyDefault}
                inputElement={
                    <TimeDropdown providerIndex={providerIndex} readOnly={readOnlyDefault} dateIndex={'main'}/>
                }
            />
            <DetailInput width={'90px'} readOnly={false} inputElement={<MobileServiceSwitch providerIndex={providerIndex}/>}/>
    
            <DetailInput width={'156px'} readOnly={readOnlyDefault}                        
                inputElement={
                    <FIFOSelect providerIndex={providerIndex} readOnly={readOnlyDefault}/>
                }
            />   
                
            <DetailInput width={'48px'} readOnly={true}
                inputElement={<NumberInput inputValue={currentServiceOrder.providers[providerIndex].providerBestQuote.shortestAvailability.relativeDays} onChange={()=>{}} readOnly={true} spin={false} preventingDefault={true}/>}
            />

            <DetailInput  width={'96px'} readOnly={true}                        
                inputElement={<TextInput inputValue={currentServiceOrder.providers[providerIndex].providerBestQuote.completed ? 'Completa': 'Incompleta'} onChange={()=>{}} readOnly={true} />}
            />

            <DetailInput width={'136px'} readOnly={true} 
                inputElement={<TextInput inputValue={convertISODateToReadableString2(currentServiceOrder.providers[providerIndex].providerBestQuote.quoteReceivedAtTime)} onChange={()=>{}} readOnly={true} />}
            />
            <DetailInput width={'90px'} readOnly={false} inputElement={<LinkReponseSwitch providerIndex={providerIndex} setRemoveLinkResponseConfirmationIndex={setRemoveLinkResponseConfirmationIndex}/>}/>
        </div>
    )
}

function DateOptions({providerIndex, handleDateChange}){
    return(
        <>
            <DateLine providerIndex={providerIndex} handleDateChange={handleDateChange} optionIndex={0}/>
            <DateLine providerIndex={providerIndex} handleDateChange={handleDateChange} optionIndex={1}/>
        </>
    )
}

function DateLine({optionIndex, providerIndex, handleDateChange}){
    const {currentServiceOrder} = useContext(QuoteMasterContext);
    const option = currentServiceOrder.providers[providerIndex].providerBestQuote.dateOptions && currentServiceOrder.providers[providerIndex].providerBestQuote.dateOptions[optionIndex];
    const firstDivWidth =  88 + 60 + 136 ;
    const readOnlyDefault = !currentServiceOrder.providers[providerIndex].quoteViaLink || currentServiceOrder.currentStage!=='notInitiated';
    return(
        <div className={styles.parameterRow} > 
            <LabelElement width={`${firstDivWidth}px`} label={optionIndex===0? 'Segunda Opção de Data': 'Terceira Opção de Data'} />
            <DetailInput width={'104px'} readOnly={currentServiceOrder.currentStage==='notInitiated' && currentServiceOrder.providers[providerIndex].quoteViaLink ? false : true}
                inputElement={<DateElement inputValue={option && option.date} onChange={handleDateChange(providerIndex, optionIndex)} />}
            />                    
            <DetailInput
                width={'130px'}
                readOnly={readOnlyDefault}
                inputElement={
                    <TimeDropdown providerIndex={providerIndex} readOnly={readOnlyDefault} dateIndex={optionIndex}/>
                }
            />
        </div>
    )
}