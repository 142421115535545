import React, { useContext, useCallback } from 'react'

import styles from 'GlobalComponents/CommonStyles.module.css';

import LabelElement from 'GlobalComponents/LabelElement/LabelElement.js';
import { QuoteMasterContext } from 'Contexts/QuoteMasterContext.js';
import DetailInput from 'GlobalComponents/DetailInput/DetailInput.js';
import TextInput from 'GlobalComponents/TextInput/TextInput.js';

export default function VirtualAgentDetails({labelWidth, inputWidth}){
    const {currentServiceOrder} = useContext(QuoteMasterContext);

    return(
        <div className={styles.parametersColumn} > Agente Virtual
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Nome'} />
                <DetailInput 
                    width={inputWidth}
                    readOnly={true}
                    inputElement={
                        <TextInput 
                            inputValue={currentServiceOrder.virtualAgent.agentName} 
                            onChange={()=>{}} 
                            readOnly={true}
                        />
                    }
                />
            </div>
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Cidade'} />
                <DetailInput 
                    width={inputWidth}
                    readOnly={true}
                    inputElement={
                        <TextInput 
                            inputValue={`${currentServiceOrder.virtualAgent.agentCity}/${currentServiceOrder.virtualAgent.agentState}`} 
                            onChange={()=>{}} 
                            readOnly={true}
                        />
                    }
                />
            </div>
            <div className={styles.parameterRow}>
                <LabelElement width={labelWidth} label={'Tempo no Local'} />
                <DetailInput 
                    width={inputWidth}
                    readOnly={true}
                    inputElement={
                        <TextInput 
                            inputValue={currentServiceOrder.virtualAgent.agentWeatherDescription} 
                            onChange={()=>{}} 
                            readOnly={true}
                        />
                    }
                />
            </div>
        </div>
    )
}

