import React, {useContext} from 'react';
import {QuoteMasterContext} from 'Contexts/QuoteMasterContext.js';
import {loadServiceOrderFromDB} from 'Contexts/ServerCalls/serverCalls.js';
import moment from 'moment-timezone';
moment.locale('pt');
import {timeZone} from 'Contexts/static_options/Classes.js'
import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js';
import Icon from 'GlobalComponents/Icon/Icon.js';

export default function LoadServiceOrderButton({serviceOrder}){
    const {currentServiceOrder, setCurrentServiceOrder, user, setLoading, serviceOrders, setServiceOrders} = useContext(QuoteMasterContext);
    

    const serviceOrderLabel = () => {
        let firstLine = serviceOrder.serviceOrderId;
        if(serviceOrder.user){
            firstLine = user.userType === 'agent' ? 
            serviceOrder.serviceOrderId : `${serviceOrder.serviceOrderId} - ${serviceOrder.user.firstName} ${serviceOrder.user.lastName}`;
        }
        const dateLine = (timeLastUpdate) => {
            const today = moment().tz(timeZone).startOf('day');
            const diff = today.diff(moment(timeLastUpdate).tz(timeZone), 'days');
            if (diff === 0) {
                // If date is today, show the time
                return moment(timeLastUpdate).format('HH:mm');
            } else if (diff === 1) {
                // If date is yesterday, show 'Yesterday'
                return 'Ontem';
            } else if (diff > 1 && diff <= 7) {
                // If date is before yesterday and up to 7 days ago, show the day of the week
                return moment(timeLastUpdate).tz(timeZone).format('dddd');
            } else if (diff > 7) {
                // If date is more than 7 days ago, show the date
                return moment(timeLastUpdate).tz(timeZone).format('DD/MM/YYYY');
            }
        }
        const label = user.userType === 'agent' ? 
            <>{firstLine} - {dateLine(serviceOrder.timeLastUpdate)}</> :
            <>{firstLine}<br/>{dateLine(serviceOrder.timeLastUpdate)}</>;
        return (
            <>{label}</>
        )
    }


    function updateServiceOrdersList(serviceOrder){
        const newServiceOrders = [...serviceOrders];
        const currentServiceOrderAux = newServiceOrders.find(order => order.serviceOrderId === serviceOrder.serviceOrderId);
        currentServiceOrderAux.newInfo = false
        setServiceOrders(newServiceOrders);
    }

    const loadServiceOrder = async (serviceOrderId) => {
        setLoading(true)
        try{
            const newServiceOrder = await loadServiceOrderFromDB(serviceOrderId);
            setCurrentServiceOrder(newServiceOrder);
            updateServiceOrdersList(serviceOrder);
        }catch(error){
            console.error('Error in loading service order from DB:', error);
        }
        setLoading(false);
    }    

    return(
        < div style={{position: 'relative', width: '85%'}}>
            <GlobalButton 
                onClick={()=>loadServiceOrder(serviceOrder.serviceOrderId)}
                label={serviceOrderLabel()}
                type={currentServiceOrder.serviceOrderId === serviceOrder.serviceOrderId ? 'selected': 'secondary'}
                width={'100%'}
                uppercase={false}
            />
            {serviceOrder.newInfo && 
                <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                    <Icon originalIcon={'newInfo'} size={28}/>
                </div>
            }
        </div>

    )
}