
import React, {useState} from 'react'

import {runTests} from 'ServerCalls/serverCalls.js';
import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js';
import ConfirmationModal from 'GlobalComponents/ConfirmationModal/ConfirmationModal.js';

export default function RunTestsButton({}){

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [testStatus, setTestStatus] = useState('notRunning');
    return(
        <>
            <GlobalButton 
            onClick={()=>setShowConfirmationModal(true)} 
            label={testStatus==='notRunning'?'Testes': 'Rodando...'} 
            type={'primary'}
            width={'80px'}
            />
            {showConfirmationModal && <ConfirmationModalContent setShowConfirmationModal={setShowConfirmationModal} setTestStatus={setTestStatus}/>}
        </>

    )
}

function ConfirmationModalContent({setShowConfirmationModal, setTestStatus}){

    const [firstScenario, setFirstScenario] = useState('');
    const [numberOfScenarios, setNumberOfScenarios] = useState(0);

    const startTests = async () => {
        setShowConfirmationModal(false);
        setTestStatus('running');
        const response = await runTests(firstScenario, numberOfScenarios);
        console.log('response:', response);
        if(response!=='success'){
            alert('Erro ao rodar os testes' + response);
        }
        setTestStatus('notRunning');
    }

    const messageButtons = <div>
        <div>Primeiro cenário: <input value={firstScenario} onChange={(e)=>setFirstScenario(e.target.value)} /></div>
        <div>Número de cenários: <input value={numberOfScenarios} onChange={(e)=>setNumberOfScenarios(e.target.value)} /></div>
    </div>

    const buttons = [
        {text: 'Rodar', onClick: startTests, width: 80},
        {text: 'Cancelar', onClick: ()=>setShowConfirmationModal(false), width: 80}
    ]

    return(
        <ConfirmationModal 
            message={messageButtons}
            buttons={buttons}
        />
    )
}
