
import React from 'react'

import GlobalButton from 'GlobalComponents/GlobalButton/GlobalButton.js';

export default function NotesButton({setHeaderView, headerView}){

    return(
        <>
            <GlobalButton 
                onClick={()=>setHeaderView('notes')} 
                label={'Sumário'} 
                type={headerView === 'notes' ? 'selected' : 'secondary'} 
                width={'80px'}
            />
        </>

    )
}